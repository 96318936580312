"use client"
import React, { Component } from "react";
import Webcam from "react-webcam";
import {isTouchEnabled} from "../helpers/URLHelper";
import VideoPlayer from "../components/VideoPlayer";
import StandardModal from "../modal/StandardModal";
import setup, {createSource} from '../snap-utils/setup.tsx';
import DeviceSelector from '../snap-utils/DeviceSelector.tsx';
import VFSnapRecord from '../snap-utils/VFSnapRecord'
import { Lens } from '@snap/camera-kit';
import poweredBySnap from '../assets/images/powered-by-snap-chat.svg'
import stoprecord from "../assets/images/stop-recording@2x.png";
import startrecord from "../assets/images/recordTapToStart@2x.png";
import noFilter from "../assets/images/recording/no-filter.png";
import "../assets/css/record-fizz.css";
import {
  Transform2D,
} from '@snap/camera-kit';
import Notify from "./Notify";





// Set apiToken with an API token for the desired app. (https://kit.snapchat.com/manage)

// Mobile App
// const apiToken ='eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNjQ4MDQyMDg3LCJzdWIiOiJiMDQyNzE1MS1kZWUxLTQ4NWUtOGExMy05YjRjNjBmY2MxMTJ-U1RBR0lOR35lMWI2M2NmMS1hM2I3LTQ4ZWItYWFiOS0wOTJkMDBkMzI1NjAifQ.xSN_cWXmg7g-W3D5HQFxxU1cbPzS42uwB973uHkKesY';

// Web App - Staging
const apiToken ='eyJhbGciOiJIUzI1NiIsImtpZCI6IkNhbnZhc1MyU0hNQUNQcm9kIiwidHlwIjoiSldUIn0.eyJhdWQiOiJjYW52YXMtY2FudmFzYXBpIiwiaXNzIjoiY2FudmFzLXMyc3Rva2VuIiwibmJmIjoxNjg0MzQ1NDM1LCJzdWIiOiI2MDUwMjQ2NC0yODg4LTQ5YzAtOTU4Ny1kMzY3ODk0MGY5MmF-U1RBR0lOR34yZTdmZGZkNi0zODIzLTQ0MDUtYTkzZC0zNmFkNzE5OWNmMmUifQ.goS82ZNAXKB8XmwgNgEO45iOOnP67_QxROVkHVv6i9Y';



export default class Recorder extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        isRecording: false,
        hasRecording: false,
        showFinalClip: false,
        uiError:null,
        uuId: '',
        trackMicLevels: false,
        lowVolume: false,
        maxVolumeLevel: 0,
  
        // SNAP CAMERA STUFF
        lensGroups: [], // Set by props
        lenses: [],
        devices: null,
      };


      this.webcamRef = React.createRef();
      this.snapCanvasRef = null;
      this.recordChunks = [];
      this.micLevels = [];
      this.snapSession = null;
      this.loadProps(props);
    }
  
  
    componentWillReceiveProps(nextProps) {
      if (this.loadProps(nextProps)) {
      }
    }
  
    loadProps(props) {

        // Set lensGroups with an array of Lens Group ID(s) for the desired Lens Group(s). (https://camera-kit.snapchat.com)
        switch( props.lensSet ) {
            case "baby":
                this.state.lensGroups = [
                    '4edbdfdb-f3d5-4775-ae6a-f7e466a54419'
                ];
                break;
            case "birthday":
                this.state.lensGroups = [
                  'cd8dd8dc-cc0e-46f5-81d6-c5236fe41c5a'
                ];
                break;
            default:
                this.state.lensGroups = [
                    '1060b5b7-7bab-40db-8df5-b286ec4f9e25'
                ]
                break;
        }
    }
  
    componentDidMount() {
      // this.snapCameraSetup();
      window.addEventListener("orientationchange", this.onOrientationChange, false);

    }
    componentWillUnmount(){

      if( this.clockInterval ) {
        clearInterval(this.clockInterval);
      }
      if ( this.uploadTimer ){
        clearTimeout(this.uploadTimer);
      }


      this.closeMedia();

      window.removeEventListener("orientationchange", this.onOrientationChange);

    }
  
    onOrientationChange() {
      var isWide = window.innerWidth >  window.innerHeight;
      if( isWide ) {
        // landscape mode
        document.getElementById("record-box").scrollIntoView();
      }
    }
  
    closeMedia() {

      try{
        if( this.snapSession ) {
          this.snapSession.removeLens();
          this.snapSession.destroy();
        }
      }catch(e){
        console.log("snapSession.removeLens",e)
        // this.handleUserMediaError(e);
      }


      try{
        if( this.vfsnapRecorder ) {
          this.vfsnapRecorder.destroy()
        }
      }catch(e){
        console.log("vfsnapRecorder.destroy",e)
        // this.handleUserMediaError(e);
      }

      try{
        console.log("mediaRecorder.stop")
        if( this.mediaRecorderRef ) {
          //non snap 
          this.mediaRecorderRef.stop();
        }
      }catch(e){
        console.log("mediaRecorder.stop",e)
        // this.handleUserMediaError(e);
      }
        

      try{
        console.log("mediaStream.getTracks")
        // stop all tracks
        if( this.state.mediaStream && this.state.mediaStream.getTracks() ) {
          this.state.mediaStream.getTracks().forEach((track) => {track.stop();});
        }
      }catch(e){
        console.log("mediaStream.getTracks",e)
        // this.handleUserMediaError(e);
      }

    }

    snapCanvasRefChange = ( canvas ) => {
      this.closeMedia();
      this.snapCanvasRef = canvas;  
      this.snapCameraSetup();
    }

    loadDevices = async () =>{
      const devices = await DeviceSelector.getAvailableDevices();

      // devices.push({
      //   label:"test"
      // });

      console.log("loadDevices",devices);
      if( devices && devices.length>0 ) {
        this.setState({
          devices:devices
        });
      } else {
        this.setState({
          devices:false
        });
    
        this.handleUserMediaError({name:"DevicesNotFoundError"})
      }
    }

    snapCameraSetup = async () =>{
      if( this.snapCanvasRef ) {
        console.log(" START snapCameraSetup")
        const { cameraKit, session, lenses, mediaStream, source} = await setup({
            apiToken:apiToken,
            lensGroups:this.state.lensGroups,
            canvasEl:this.snapCanvasRef,
        });

        if( !cameraKit ||!session ||!lenses ||!mediaStream ||!source ) {
          console.log(" cameraKit",cameraKit)
          console.log(" session",session)
          console.log(" lenses",lenses)
          console.log(" mediaStream",mediaStream)
          console.log(" source",source)
          this.handleUserMediaError({name:"DevicesNotFoundError"})
          return;
        }

        // Reduce How Many We show
        var limit = (this.props.lensSet=='baby')?3:7;
        var lessLenses = lenses.splice(0,limit);

        this.snapSession = session


        this.loadDevices(); // Needs snap session for onChange


        this.setState({
            mediaStream:mediaStream,
            lenses:lessLenses,
            activeLens: lessLenses[0]
        });

        source.setTransform(Transform2D.MirrorX);



        if( this.vfsnapRecorder ) {
          console.log("DOUBLE START")
          this.vfsnapRecorder.destroy()
        }
        this.vfsnapRecorder = await new VFSnapRecord( session.output.live )
        this.vfsnapRecorder.init()

        if( this.vfsnapRecorder.error ){
          this.handleUserMediaError({name:"DevicesNotFoundError"})
        }

        // Trying to pop the legal prompt
        // var legalState = cameraKit.container.factories.legalState();
        // legalState.events.subscribe( legalState.actions.requestLegalPrompt() );
        // legalState.actions.requestLegalPrompt() 

        session.applyLens(lessLenses[0]);

      }
    }

    handleDataAvailable = ({ data }) => {
      if (data.size > 0) {
        console.log("Add Data", data);
        this.recordChunks = this.recordChunks.concat(data);
      }
    };
  
    start = () => {
      this.recordChunks = [];
  
      this.setState(() => ({trackMicLevels: true}));
  
      if( this.webcamRef && this.webcamRef.current ) {
        this.visualize(this.webcamRef.current.stream);
      }
      
      if (this.mediaRecorderRef) {
        this.mediaRecorderRef.removeEventListener(
          "dataavailable",
          this.handleDataAvailable
        );
      }
  
      var typePreference = [{
        mime:"video/webm",
        ext:"webm",
        uploadExt:'webm',
      },{ 
        mime:"video/mp4",
        ext:"mp4",
        uploadExt:'mp4',
      },{ 
        mime:"video/mpeg",
        ext:"mpeg",
        uploadExt:'mpeg',
      }];
  
      var workingType = null;
      for (var i in typePreference) {
        if( MediaRecorder.isTypeSupported(typePreference[i].mime) ) {
          workingType = typePreference[i];
          break;
        }
      }
      this.workingType = workingType;
  
  
      if( this.snapSession ) {
  
        this.vfsnapRecorder.start()
  
  
      } else {
        this.mediaRecorderRef = new MediaRecorder(this.webcamRef.current.stream, {
          mimeType: workingType.mime,
        });
        this.mediaRecorderRef.addEventListener(
          "dataavailable",
          this.handleDataAvailable
        );
        this.mediaRecorderRef.start();
      }
  
      this.setState({ isRecording: true });
  
  
      this.startTime = (new Date()).getTime();
      this.clockInterval = setInterval(()=>{
  
        let newTime = (new Date()).getTime();
        this.setState({
          currentTime: ( newTime-this.startTime )/1000
        })
      },500);
    };

    stop = () => {
      let maxVolumeLevel = Math.max(...this.micLevels);
      
      this.setState({
        trackMicLevels: false,
        maxVolumeLevel: maxVolumeLevel
      });
    
      console.log("final mic levels:", this.micLevels);
      console.log("Maximum volume detected:", maxVolumeLevel);
  
      if ( false && maxVolumeLevel < -110) {
        this.setState(() => ({lowVolume: true}))
      } else {
        this.setState(() => ({showFinalClip: true}));
      }
      clearInterval(this.clockInterval);
      let newTime = (new Date()).getTime();
      this.setState({
        currentTime: ( newTime-this.startTime )/1000
      })
  
      if( this.snapSession ) {
        this.vfsnapRecorder.stop()
      } else {
        this.mediaRecorderRef.stop();
      }
      this.setState({
        isRecording: false,
        hasRecording: true,
      },()=>{
        this.recordingStateChange()
      });
    };


    getBlob = ()=>{
        if( this.vfsnapRecorder ) {
            return this.vfsnapRecorder.getBlob();
        } else if (this.recordChunks && this.recordChunks.length) {
            return new Blob(this.recordChunks, {
                type: this.workingType.mime,
            });
        }
        return null;
    }
    getWorkingType = ()=>{
        if( this.vfsnapRecorder ) {
            return this.vfsnapRecorder.getWorkingType();
        } else if (this.recordChunks && this.recordChunks.length) {
            return this.workingType;
        }
        return null;
    }


    recordingStateChange = ()=> {
        if( this.props.onRecordingChange ) {
            this.props.onRecordingChange({
                lowVolume:this.state.lowVolume,
                showFinalClip:this.state.showFinalClip,
                previewVideoUrl:this.state.previewVideoUrl,
                isRecording:this.state.isRecording,
                hasRecording:this.state.hasRecording,
            })
        }
    }

    restart = () => {
        console.log("RESTART FROM CHILD")
      this.recordChunks = [];
      this.setState({
        isRecording: false,
        hasRecording: false,
        showFinalClip: false,
        previewVideoUrl: null,
        uploader: null,
        currentTime: 0,
        streamError: '',
        lowVolume: false,
      },()=>{
        this.recordingStateChange()
      });
    };

    download = () => {
  
      var url = null;
      if( this.vfsnapRecorder ) {
        url = this.vfsnapRecorder.createObjectURL();
      } else if( this.recordChunks.length) {
        const blob = new Blob(this.recordChunks, {
          type: this.workingType.mime,
        });
  
        url = URL.createObjectURL(blob);
      }
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture."+this.workingType.ext;
      a.click();
      var video = document.getElementById("video");
      video.src = window.URL.createObjectURL(url);
      window.URL.revokeObjectURL(url);
      this.recordChunks = [];
    };
  
    
    preview = () => {
      //close low volume modal if open
      if( this.state.lowVolume ){
        this.setState({ lowVolume: false });
      }
  
      var url = null;
      if( this.vfsnapRecorder ) {
        url = this.vfsnapRecorder.createObjectURL();
      } else if (this.recordChunks.length) {
  
        const blob = new Blob(this.recordChunks, {
          type: this.workingType.mime,
        });
  
        url = URL.createObjectURL(blob);
      }
  
      if( url ) {
        this.setState({
          showFinalClip: false,
          previewVideoUrl:url,
        },()=>{
            this.recordingStateChange();
        });
      }
    };
  
  
  
    handleUserMedia  = async(stream) => {
  
      this.setState({ 
        uiError : null
      });
      // setTimeout("window.location.reload()",1000);
      console.log("Video stream received.");
    };
  
    visualize = (stream) => {
      return;
      const audioContext = new AudioContext();
      const source = audioContext.createMediaStreamSource(stream);
      const analyser = audioContext.createAnalyser();
      analyser.smoothingTimeConstant = 0.8;
      analyser.fftSize = 2048;
      source.connect(analyser);
      this.micLevels.length = 0; // Flush the array for a fresh start
      const micTracker = setInterval(() => {
        if (!this.state.trackMicLevels) {
          clearInterval(micTracker);
          return;
        }
        const dataArray = new Float32Array(analyser.frequencyBinCount)
        analyser.getFloatFrequencyData(dataArray)
        this.micLevels.push(dataArray.reduce((a, b) => a + b) / dataArray.length);
        console.log(this.micLevels);
        // => Values outside the default [-100, -30] range.
      }, 100);
    }
  
    handleUserMediaError = async (err) => {
      console.log( "HangleUserMediaError" , err);
  
          if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {
              //required track is missing 
              this.setState({ 
                uiError : "Camera/microphone not found. Please connect a webcam/microphone and refresh the page to record."
              });
          } else if (err.name == "NotReadableError" || err.name == "TrackStartError") {
              //webcam or mic are already in use 
              this.setState({ 
                uiError : "Camera/microphone already in use. Please close any other applications useing the webcam/microphone and refresh the page to record."
              });
          } else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {
              //permission denied in browser 
              this.setState({ 
                uiError : "Please grant access to your camera and microphone and refresh the page to record."  
              });
          } else {
              //other errors 
              this.setState({ 
                uiError : "Camera/microphone not found. Please check that your camera/microphone permissions are allowed. If you don't have a camera on this computer try with another device."
              });
          }
    }
  
  
    renderCamera = ()=>{
      // return this.renderWebCamera();
      return this.renderSnapCamera();
    }
  
    renderCameraPicker = ()=>{

      var classAddOn = "invisible";
      if( this.state.devices && this.state.devices.length > 1 ) {
        classAddOn = ""
      }

      const onChange =  async (event)=>{

        let deviceData = this.state.devices.find(d => d.deviceId==event.target.value);
        let device = await DeviceSelector.getMediaStream(event.target.value);
        console.log("Device Change",device);
        if (device) {
          let source = createSource(device);
          console.log("Device source",source);

          await this.snapSession.setSource(source);
          await this.snapSession.play();

          if( deviceData ) {
            var deviceName = (deviceData.label+'').toLowerCase();
            
            var isFront = false;
            if( deviceName.includes('front') ) {
              isFront = true;
            } else if( deviceName.includes('facetime hd camera') ) {
              isFront = true;
            }

            if( isFront ) {
              console.log(deviceName, " ITS FRONT")
              await source.setTransform(Transform2D.MirrorX);
            } else {
              console.log(deviceName, " ITS BACK")
              await source.setTransform(Transform2D.Identity);
            }
          }

        }

      }
      return (   <>
          <div className={"form-group "+classAddOn}>
            <label>Camera &nbsp;
              <select className="form-control d-inline-block" onChange={onChange} >
                {this.state.devices===null &&
                  <option value="">Loading...</option>
                }
                {this.state.devices===false &&
                  <option value="">Error</option>
                }
                {this.state.devices && this.state.devices.length>0 &&
                  (this.state.devices.map( (device)=>{
                    return (

                      <option value={device.deviceId} key={device.deviceId}>{device.label}</option>
                    )
                  }))
                }
              </select>
            </label>
          </div>
          {/*<div style={{overflow:"scroll"}}>
              {this.state.devices &&  this.state.devices.map( (d)=>
                <p>{JSON.stringify(d,true)}</p>
              )}
              </div>*/}
        </>
     )
    }
    renderSnapCamera = ()=>{
      return (
        <>
          <canvas ref={this.snapCanvasRefChange} style={{width:"100%"}} width="854" height="480" />
        </>
      );
    }

    getInstructions = (lens)=>{

      var tapOrClick = "Click";
      if( isTouchEnabled() ) {
        tapOrClick = "Tap";
      }

      var message = null;
      if( lens ) {
        if( lens.name=="Birthday Hat" ) {
          message = tapOrClick+" the hat";
        }
      }
      return (
          <div className="position-absolute w-100 text-center" style={{color:"white",fontSize:"1.7rem",fontWeight:"bold"}}>
            {message}
          </div>
        );
    }

    renderLensePicker = () => {
  
      const showOffButton = false;
      var applyLens = (lens)=>{
  
        console.log("lens info",lens);

        if( this.state.activeLens == lens ) {
            this.snapSession.removeLens();
            this.snapSession.applyLens(lens);
        } else {
          this.setState({
            activeLens:lens
          });
          if( lens ) {
            this.snapSession.applyLens(lens);
          } else {
            this.snapSession.removeLens();
          }
        }
      }
      var isOff = this.state.activeLens==null;
  
      return (
        <div className="snap-filter-bar" >
          { showOffButton && 
            <button className={"snap-filter-btn "+(isOff?"active":"")}
              onClick={()=>{applyLens(null)} } >
              <img src={noFilter} width="100%" />
            </button>
          }
          {this.state.lenses.map((lens)=>{
            var isActive = this.state.activeLens==lens;
            return (
              <button className={"snap-filter-btn "+(isActive?"active":"")} key={"lens-"+lens.id}
                onClick={()=>{applyLens(lens)} } >
                <img src={lens.iconUrl} width="100%" />
              </button>
            );
          })}
        </div>
      );
    }
    renderWebCamera = ()=>{
      return (
        <Webcam
          audio={true}
          muted={true}
          mirrored={true}
          ref={this.webcamRef}
          className="w-100"
          videoConstraints={{
            facingMode: 'user', 
            aspectRatio: 854/480, 
            width: { ideal: 854 },
            height: { ideal: 480 },
            frameRate: 29.97
          }}
          onUserMedia={(stream) => {this.handleUserMedia(stream)}}
          // onUserMedia={e => console.log(e + "check1")}
          onUserMediaError={(err) => {this.handleUserMediaError(err)}}
        />
      )
    }
  
    render() {
  
      var displayTime = "0:00";
      if( this.state.currentTime ) {
        let sec = Math.round(this.state.currentTime) % 60;
        let min = Math.floor(this.state.currentTime/60);
        displayTime = min+":"+(sec<10? "0":'' )+sec;
      }
  
      return (
        <>
            <div className="row mb-2" >
                <div className="col-sm-8 text-start" >
                  { !this.state.previewVideoUrl &&
                    this.renderCameraPicker()
                  }
                </div>
                <div className="col-sm-4 text-center text-sm-end" >
                    <img className="snapPill" src={poweredBySnap} />
                </div>
            </div>
            <div className="text-center">


              { this.state.uiError && 
                <div className="pt-2 pb-2">
                      <Notify error={true} message={this.state.uiError} />
                </div>
              }
  
                
  
                <div id="record-box" className="video-view ">

                  <div className="box-480" >
                    <div className="box-480-item" >

  
                      { this.getInstructions(this.state.activeLens) }

                      { this.state.previewVideoUrl &&
                        <div>{/*Extra wrapper need to avoid issues breaking when remmoved*/}
                          <VideoPlayer 
                            autoplay={true}
                            options={{
                              playsinline: true,
                            }} 
                            src={this.state.previewVideoUrl}
                          />
                        </div>
                      }
                      {!this.state.previewVideoUrl  &&
                        <div>
  
                          {this.renderCamera()}
                          
                          <div className="record-clock">{displayTime}</div>
                          {this.renderLensePicker()}
                        </div>
  
                      }

                    </div>
                  </div>
  
                  <div className="video-content">
                    {!this.state.hasRecording && !this.state.isRecording && (
                      <button
                        type="button"
                        className="video_view_btn"
                        onClick={this.start}
                      >
                        <img src={startrecord} className="video_view_btnimg" />
                      </button>
                    )}
                    {!this.state.hasRecording && this.state.isRecording && (
                      <button
                        type="button"
                        className="video_view_btn"
                        // onClick={this.stop}
                        onClick={this.stop}
                      >
                        <img src={stoprecord} className="video_view_btnimg" />
                      </button>
                    )}
                  </div>
  
                </div>
  
            </div>


            <StandardModal
              header="Low Volume!"
              closeBtnText="Record Again"
              onHide={this.restartRecording}
              show={this.state.lowVolume}
              >
              <div className="mb-3">
                  The sound in this recording was very quiet.
                  If you were talking in the video your microphone may be off, on mute, or too far away.
              </div>
              <button className="btn btn-primary btn-block" onClick={this.preview} >Preview</button>
            </StandardModal>

            
        </>
      );
    }
  }
  