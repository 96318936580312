import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import MainContent from "../components/MainContent";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import BannerBar from "../components/BannerBar";
import { toastManager } from "../components/Toaster";
import LoadingBar from "../components/LoadingBar";
import Branding from "../brands/Branding";
import {QRCodeCanvas} from "qrcode.react";
import { apiFetch } from "../helpers/APIHelper";

export default (props) => {
  
  const [text, setText] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const [editUrl, setEditUrl] = useState("");
  const [error, setError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [copyClipboardClassName, setCopyClipboardClassName] = useState("");

  const qrcodeRefWrap = useRef()

  // From: https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
  // Added a max length to make a hash from
  // change to hex to make it small
  const hashCode = (str)=>{
      let hash = 0;
      for (let i = 0, len = str.length; i < len && i<20000; i++) {
          let chr = str.charCodeAt(i);
          hash = (hash << 5) - hash + chr;
          hash |= 0; // Convert to 32bit integer
      }
      return hash.toString(16);
  }

  const downloadQR = () => {
    const canvas = qrcodeRefWrap.current.getElementsByTagName('canvas')[0];
    console.log(canvas)

    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode-"+hashCode(text)+".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const isUrlInvalid = (url)=>{
    if( !url ) {
      return("No Url Provided");
    }
    if( !url.startsWith("http://") && !url.startsWith("https://") ) {
      return "The url needs to start with http or https";
    }
    if( !url.startsWith("http://") && !url.startsWith("https://") ) {
      return "The url needs to start with http or https";
    }
    return false;
  }

  const createShortLink = (url) => {
    setEditUrl(null);
    setQrUrl(null);
    setError("");

    url = url || "";
    url = url.trim();

    var validationError = isUrlInvalid(url);
    if( validationError ) {
      setError(validationError)
      return;
    }

    var trackTheLinks = true;
    if( !trackTheLinks ) {
      setQrUrl(url);
      return;
    }
    apiFetch(
      "/pub/short-link",
      {
        method: "PUT",
        data: {
          dest: url,
        },
      },(json) => {
        var shortLink = window.location.origin+json.linkPath;
        console.log(shortLink)
        setEditUrl(null);
        setQrUrl(shortLink);
      },(error) => {
        setError(error + "");
        console.log(error);
      }
    );
  }
  const copyEditUrl = ()=>{
    if( navigator && navigator.clipboard ) {
      setCopyClipboardClassName('');
      navigator.clipboard.writeText(editUrl);
      setTimeout(()=>{
        setCopyClipboardClassName('pulse');
      },1);
    } else {
      setError("Cant Copy. Your browser does not support click to copy. Please highlight the text to copy. ")
    }
  }
  const createVideo = (themeSlug) => {
    setEditUrl(null);
    setQrUrl(null);
    setError("");

    console.log(props);

    var orgKey = props.match.params.orgKey;
    var genKey = props.match.params.genKey;

    apiFetch(
      "/rest/order/qr-gen/"+orgKey,
      {
        method: "PUT",
        data: {
          genKey: genKey,
          email: userEmail,
          themeSlug:themeSlug,
        },
      },(json) => {
        setEditUrl(window.location.origin+json.setupLink);
        setQrUrl(window.location.origin+json.viewLink);
      },(error) => {
        setError(error + "");
        console.log(error);
      }
    );
  }


  var toggle = () => {
    var options = ["Save the Date","Invitation","Thank You"];
    return options.map((option)=>{
      var selected = option==selectedOption;
      return <>
        <button 
          key={option} 
          className={"btn "+(selected?'btn-primary':'btn-outline-primary')} 
          onClick={()=>{setSelectedOption(option)}}
          >{option}</button>
        &nbsp;
        </>
    });
  }

  var restart = ()=>{
    setQrUrl(null);
    setEditUrl(null);
    setSelectedOption(null);
    setText(null);
    setCopyClipboardClassName('');
  }



  var renderContent = ()=> {

    var showLink = true;
    var videoSubLine = "";
    var themeSlug = "";
    switch( selectedOption ) {
      case "Save the Date":
        // showLink = true;
        videoSubLine = "Add your engagement photos in a video inside your save the date cards.";
        themeSlug = 'invites';
        break;
      case "Invitation":
        // showLink = true;
        videoSubLine = "Add your engagement photos in a video inside your invitations";
        themeSlug = 'invites';
        break;
      case "Ceremony & Reception":
        showLink = false;
        break;
      case "Thank You":
        // showLink = true;
        videoSubLine = "Enhance your thank yous and add your wedding video or photos from your special day";
        themeSlug = 'thank-you';
        break;
      case "Change the Date":
        showLink = false;
        break;
    }

    return (
        <div>

          <h2 className="headline d-flex align-items-center mt-5 mb-4"><span>Add A QR Code</span></h2>



          {!qrUrl &&
            <div className="row mb-1 ">
              <div className="col-12" >

                  <h3>Select the category</h3>

                  <div>
                    {toggle()}
                  </div>


                  {selectedOption && videoSubLine &&
                    <div className="panel panel-default mt-4 mb-4">
                      <h3>Create Video Link</h3>
                      <p className="m-0">{videoSubLine}</p>
                      <div className="form-floating mt-0 mb-1">
                          <input
                            className="form-control"
                            onChange={(e)=>{setUserEmail(e.target.value)}}
                            value={userEmail}
                            placeholder={"user@mail.com"}
                            required={true}
                          />
                          <label>Email</label>
                      </div>

                      <button className="btn btn-secondary" onClick={()=>{createVideo(themeSlug)}}>Email Video Creation Link and Generate QR</button>
                    </div>
                  } 

                  { selectedOption && showLink && videoSubLine &&
                    <h1><b>OR</b></h1>
                  }

                  {selectedOption && showLink &&
                    <div className="panel panel-default mt-4">
                      <h3 className="panel-title">Link to wedding website or any URL</h3>
                      <div className="form-floating mb-1">
                          <input
                            className="form-control"
                            onChange={(e)=>{setText(e.target.value)}}
                            value={text}
                            placeholder={"https://your-link"}
                            required={true}
                          />
                          <label>URL</label>
                      </div>

                      <button className="btn btn-secondary" onClick={()=>{createShortLink(text)}}>Generate QR</button>

                      {error &&
                        <div className="alert alert-danger mt-2">{error}</div>
                      }
                    </div>
                  }

              </div>
            </div>
          }

          {qrUrl &&
            <div className="mt-4" >
              <p>
                <button className="btn btn-outline-primary" onClick={restart}>Back</button>
              </p>
              <div className="row" >

                <div className="col-2" ></div>
                <div className="col-8 text-center" >
                  {editUrl && 
                    <p className={"alert alert-info mb-0 w-80 "+copyClipboardClassName} onClick={copyEditUrl}>
                      <b>Project Edit Link</b><br/>
                      {editUrl}
                      <br/>
                      <small>Click to Copy Link</small>
                    </p>
                  }
                  <div ref={qrcodeRefWrap} className="card d-inline-block mt-3 p-2" >
                    {/*
                      level is for how much damage is allowed
                      Level L 07%, 
                      level M 15%, 
                      level Q 25%, 
                      level H 30%. 
                    */}
                    <QRCodeCanvas
                      title="Scan To View"
                      value={qrUrl}
                      bgColor="#FFFFFF"
                      fgColor="#000000"
                      size={480}
                      level={"H"}
                      includeMargin={true}
                      imageSettings={{
                        src:Branding.getQRGenCenterImage(),
                        height: 480*.14*11.972/9.94    ,
                        width: 480*.14,
                        excavate:true
                      }}
                    />
                    <p>
                      <button className="btn btn-primary" onClick={downloadQR} >Download</button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
    );
  }

  return (
    <div className="screen-wrap" >
      <NavBar />
      <MainContent className="container">
        {renderContent()}
      </MainContent>
      <Footer />
    </div>
  );
}
