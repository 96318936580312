import React from 'react';

export default (props) => {

    return (
        <button
            tabIndex="0"
            onClick={props.onClick}
            className={"btn btn-block btn-lg-inline-block btn-primary next-btn " }
        >
            { props.textOverride ? props.textOverride : "NEXT"}
        </button>

    );
    
}