import Brand from './Brand';
import { getBaseURL } from "../helpers/URLHelper";
import { Link } from "react-router-dom";

import QRInside from "../assets/branding/theknot/qr-inside.png";
import Step1_TY from "../assets/branding/theknot/knot-ty-s1.jpg";
import Step2_TY from "../assets/branding/theknot/knot-ty-s2.jpg";
import Step1_IN from "../assets/branding/theknot/knot-in-s1.jpg";
import Step2_IN from "../assets/branding/theknot/knot-in-s2.jpg";
import Step3 from "../assets/branding/theknot/knot-s3.jpg";

class TheKnot extends Brand {


	getHowItWorksVideo = ()=>{
		return false;
	}
	getQRGenCenterImage = () => {
		return QRInside;
	}


	getFizzEmptyMessage = ()=>{
		return (
			<>
				<h3 className="mt-sm-4 pt-lg-4">
					Add your photos and videos.
				</h3>

				<h5>
					Most people will only watch 60-90 seconds of a video. We recommend 15 photos or less. If you are adding a video, keep the total project under 2 minutes.
				</h5>

				<h5>
					However, there’s no limit.
				</h5>
            </>
		);
	}


	getStepOne = ( {isInvite,themeSlug}  ) => {
		if( themeSlug=='thank-you' ) {
			return this.getStep({
				img: Step1_TY,
				title: "Record a Video",
				msg: "Record a personal message to thank your guests.",
			});
		} else {
			return this.getStep({
				img: Step1_IN,
				title: "Record a Video",
				msg: "Record a personal message to invite guests to your wedding.",
			});
		}
	}
	getStepTwo = ( {isInvite,themeSlug}  ) => {
		if( themeSlug=='thank-you' ) {
			return this.getStep({
				img: Step2_TY,
				title: "Add Wedding Photos or Video Clips",
				msg: "Upload your wedding video or favorite photos to share the memory of your special day.",
			});
		} else {
			return this.getStep({
				img: Step2_IN,
				title: "Add Photos or Video Clips",
				msg: "Upload your favorite engagement photos or pictures of the couple.",
			});
		}
	}

	getStepThree = ( {isInvite,themeSlug}  ) => {
		if( themeSlug=='thank-you' ) {
			return this.getStep({
				img: Step3,
				title: "You’re Done!",
				msg: "QR Code in your thank you’s is scanned and your card magically comes to life.",
			});
		} else {
			return this.getStep({
				img: Step3,
				title: "You’re Done!",
				msg: "QR Code in your invitations is scanned for a one of a kind experience to build excitement.",
			});
		}

	}


} 


export default TheKnot