import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MainContent from "../components/MainContent";
import { apiFetch } from "../helpers/APIHelper";
import Spinner from "../components/Spinner";

export class AlertReset extends Component {

  constructor(props) {
      super(props);
  
      this.state = {
        fizzId: this.props.match.params.fizzId,
        viewedOtp: this.props.match.params.otp,
        isLoading: true,
        errorMessage: null
      }
  }

  componentDidMount(){
    this._getData();
  }

  _getData() {

    console.log( this.state.viewedOtp );
    
    apiFetch(
      "/rest/fizz/" + this.state.fizzId + "/reset-view-alert",
      {
        method: "POST",
        data:{
          viewedOtp: this.state.viewedOtp
        }
      },
      (json) => {
        console.log( json )
        this.setState({
          img: json.img,
          isLoading: false
        });

      },
      (error) => {
        console.log(error);
        this.setState({
          errorMessage: error.message,
          isLoading: false
        });
      }
    );
  };
  
  render() {

    if( this.state.isLoading ){
      return(
        <div className="screen-wrap">
          <NavBar/>
          <MainContent className="container bg-margin">
            <Spinner float={true}/>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    if( this.state.errorMessage ){

      return(
        <div className="screen-wrap">
          <NavBar/>
          <MainContent className="container bg-margin">
            <p className="alert alert-danger mt-4">
              {this.state.errorMessage}
            </p>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    return(
      <div className="screen-wrap">
        <NavBar/>
        <MainContent className="container bg-margin">
          <br/>
          <h1 className="headline-text mt-4 text-center">Your Alert Has Been Reset</h1>
          
          <h6 className="subheadline mb-3 text-center">We will let you know when your project is watched again.</h6>

          <div className="text-center d-none d-md-block">
            <img className="w-100 mt-4" src={this.state.img} alt="Video Greeting Card Image"/>
          </div>
          
        </MainContent>
        <Footer hideMobile={true} />
      </div>
    )
  }

}

export default AlertReset;