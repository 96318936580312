import FooterBar from '../components/FooterBar';
import Brand from './Brand';
import { getBaseURL } from "../helpers/URLHelper";
import { Link } from "react-router-dom";
import StepsRecord from "../assets/branding/blank/steps-record.jpg";
import StepsShare from "../assets/branding/blank/steps-share.jpg";
import pig from "../assets/branding/moonpig/moonpig-space-pig.png";

class Moonpig extends Brand {

	
	getSetupPageType = ()=>{
		return 'customize-this';
	}	
	getSetupBtnText = ()=>{
		return "Begin Customising";
	}
	        

	        
	getPreviewContentUnderVideo = (props) => {
		return (
			<>

				<div className="col-lg-10 offset-lg-1">
					<p className="my-3 pb-5">This is the video that will play when the QR code in the card is scanned. Tap <b>back to edit</b> below if you are still working on it.</p>
				</div>

				<FooterBar className='preview-footer'>
					<Link to={ getBaseURL( "/edit/" + props.fizzId ) } className="btn btn-outline-secondary btn-block btn-lg-inline-block px-5">
                  		Back To Edit
                	</Link>

					<button
						onClick={props.saveAccountClick}
						className={"btn btn-block btn-lg-inline-block btn-secondary my-4 ms-lg-4 next-btn" }
					>
						Save A Copy
					</button>
				</FooterBar>
			</>
		);
	}



	getSharedFizzContentTop = (props) => {
		return (
			<>
				<p style={{textAlign:'center',fontSize:'26px', marginTop:'16px'}}>{props.title}</p>
			</>
		);
	}




	getStepOne = ( props ) => {
		return (
			<>
				<div className="step-thumbnail-wrapper">
					<img src={StepsRecord} />
					<div className="info-strip">
						Choose a Card
					</div>
				</div>
				
				<p className="mt-3 px-4 px-md-0">Choose your favorite card, personalise, select music, and continue to edit until your gift has been received!</p>
			</>
		);
	}

	getStepThree = ( props ) => {
		return (
			<>
                <div className="step-thumbnail-wrapper">
                    <img src={StepsShare} />
                    <div className="info-strip">
						Wait for Happy Tears
                    </div>
                </div>
                    
				<p className="mt-3 px-4 px-md-0">When the recipient receives their gift, they'll scan a QR code or receive a link to their video.</p>
			</>
		);
	}

	getViewThankYouBottom = (props) => {
		return (
			<div className="row mt-0 mt-sm-5">
				<div className="col-md-4 offset-md-4 mt-0 mt-sm-4 pt-5">
					<img src={pig} width="100%" />
				</div>
			</div>
		)
	}

} 


export default Moonpig