import Brand from './Brand';
import { getBaseURL } from "../helpers/URLHelper";
import { Link } from "react-router-dom";

import navArrowSVG from "../assets/branding/lovepop/nav-arrow.svg";
import "../assets/branding/lovepop/lovepop-view-page.css";

const HoverImage = (props) => {
  return (
    <div className="lovepop-hover-image">
      <img className="main" src={props.src} alt={props.alt || "Product Image"}/>
      <img className="hover"  src={props.hover} alt="Larger View" />
    </div>
  )
}

class Lovepop extends Brand {

	
	getSharedFizzContentTop = (props) => {

    var navArrow = (
      <svg viewBox="0 0 10 6">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.354.646a.5.5 0 00-.708 0L5 4.293 1.354.646a.5.5 0 00-.708.708l4 4a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z" fill="currentColor"> </path>
      </svg>
    );

		return (<>
        <div className="lovepop-nav">
          <nav className="header__inline-menu"> 
            <ul className="list-menu list-menu--inline" role="list"><li data-position="0" data-title="Easter" data-top-nav="true" className="first-item-link">
                    <a style={{color:'#a67eac'}} data-position="0" data-title="Easter" data-top-nav="true" href="https://www.lovepop.com/collections/easter" className="header__menu-item list-menu__item link link--text focus-inset">
                      <span >
                        <svg style={{marginRight:'3px'}}  fill="#a67eac" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M192 496C86 496 0 394 0 288C0 176 64 16 192 16s192 160 192 272c0 106-86 208-192 208zM154.8 134c6.5-6 7-16.1 1-22.6s-16.1-7-22.6-1c-23.9 21.8-41.1 52.7-52.3 84.2C69.7 226.1 64 259.7 64 288c0 8.8 7.2 16 16 16s16-7.2 16-16c0-24.5 5-54.4 15.1-82.8c10.1-28.5 25-54.1 43.7-71.2z"></path></svg>
                        Easter
                      </span>
                    </a></li><li data-position="1" data-title="Occasions" data-top-nav="true" className=""><header-menu>
                      <details id="Details-HeaderMenu-2" className="mega-menu">
                        <summary className="header__menu-item list-menu__item link focus-inset" role="button" aria-expanded="false" aria-controls="MegaMenu-Content-2">
                          <a data-position="1" data-title="Occasions" data-top-nav="true" href="https://www.lovepop.comp/collections/shop-occasions-greeting-cards">Occasions</a>
                          {navArrow}
                        </summary>
                        <div id="MegaMenu-Content-2" className="mega-menu__content gradient motion-reduce global-settings-popup" tabindex="-1">
                          <div className="mega-menu__wrapper  page-width">
                            <ul className="mega-menu__list--columns" role="list"><li data-title="Birthday" data-position="0">
                                  <a data-title="Birthday" data-position="0" href="https://www.lovepop.comp/collections/birthday-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Birthday
                                  </a></li><li data-title="Anniversary" data-position="1">
                                  <a data-title="Anniversary" data-position="1" href="https://www.lovepop.comp/collections/anniversary-pop-up-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Anniversary
                                  </a></li><li data-title="Baby" data-position="2">
                                  <a data-title="Baby" data-position="2" href="https://www.lovepop.comp/collections/new-baby-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Baby
                                  </a></li><li data-title="Congratulations" data-position="3">
                                  <a data-title="Congratulations" data-position="3" href="https://www.lovepop.comp/collections/congratulations-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Congratulations
                                  </a></li><li data-title="Get Well" data-position="4">
                                  <a data-title="Get Well" data-position="4" href="https://www.lovepop.comp/collections/get-well-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Get Well
                                  </a></li><li data-title="Graduation" data-position="5">
                                  <a data-title="Graduation" data-position="5" href="https://www.lovepop.comp/collections/graduation-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Graduation
                                  </a></li><li data-title="Just Because" data-position="6">
                                  <a data-title="Just Because" data-position="6" href="https://www.lovepop.comp/collections/just-because-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Just Because
                                  </a></li><li data-title="Retirement" data-position="7">
                                  <a data-title="Retirement" data-position="7" href="https://www.lovepop.comp/collections/retirement-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Retirement
                                  </a></li><li data-title="Religious" data-position="8">
                                  <a data-title="Religious" data-position="8" href="https://www.lovepop.comp/collections/religious-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Religious
                                  </a></li><li data-title="Sympathy" data-position="9">
                                  <a data-title="Sympathy" data-position="9" href="https://www.lovepop.comp/collections/sympathy" className="mega-menu__link mega-menu__link--level-2 link">
                                    Sympathy
                                  </a></li><li data-title="Thank You" data-position="10">
                                  <a data-title="Thank You" data-position="10" href="https://www.lovepop.comp/collections/thank-you-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Thank You
                                  </a></li><li data-title="Wedding" data-position="11">
                                  <a data-title="Wedding" data-position="11" href="https://www.lovepop.comp/collections/wedding-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Wedding
                                  </a></li></ul>

                            

                              
                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.com/collections/birthday-cards">
                                  <img src="//www.lovepop.com/cdn/shop/files/Menu_SKU5.jpg?v=1677457657&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>
                              

                              
                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.com/collections/anniversary-pop-up-cards">
                                  <img src="//www.lovepop.com/cdn/shop/files/Menu_SKU6.jpg?v=1677457682&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>


  </div>
                        </div>
                      </details>
                    </header-menu></li><li data-position="2" data-title="Holidays" data-top-nav="true" className=""><header-menu>
                      <details id="Details-HeaderMenu-3" className="mega-menu">
                        <summary className="header__menu-item list-menu__item link focus-inset" role="button" aria-expanded="false" aria-controls="MegaMenu-Content-3">
                          <a data-position="2" data-title="Holidays" data-top-nav="true" href="https://www.lovepop.comp/collections/shop-all-holiday">Holidays</a>
                          {navArrow}
                        </summary>
                        <div id="MegaMenu-Content-3" className="mega-menu__content gradient motion-reduce global-settings-popup" tabindex="-1">
                          <div className="mega-menu__wrapper  page-width">
                            <ul className="mega-menu__list--columns" role="list"><li data-title="International Women's Day 3/8" data-position="0">
                                  <a data-title="International Women's Day 3/8" data-position="0" href="https://www.lovepop.comp/collections/cards-celebrating-women" className="mega-menu__link mega-menu__link--level-2 link">
                                    International Women's Day 3/8
                                  </a></li><li data-title="St. Patrick's Day 3/17" data-position="1">
                                  <a data-title="St. Patrick's Day 3/17" data-position="1" href="https://www.lovepop.comp/collections/st-patricks-day" className="mega-menu__link mega-menu__link--level-2 link">
                                    St. Patrick's Day 3/17
                                  </a></li><li data-title="Easter 3/31" data-position="2">
                                  <a data-title="Easter 3/31" data-position="2" href="https://www.lovepop.comp/collections/easter" className="mega-menu__link mega-menu__link--level-2 link">
                                    Easter 3/31
                                  </a></li><li data-title="Mother's Day 5/12" data-position="3">
                                  <a data-title="Mother's Day 5/12" data-position="3" href="https://www.lovepop.comp/collections/mothers-day-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Mother's Day 5/12
                                  </a></li><li data-title="Father's Day 6/16" data-position="4">
                                  <a data-title="Father's Day 6/16" data-position="4" href="https://www.lovepop.comp/collections/fathers-day-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Father's Day 6/16
                                  </a></li><li data-title="Independence Day 7/4" data-position="5">
                                  <a data-title="Independence Day 7/4" data-position="5" href="https://www.lovepop.comp/collections/fourth-of-july" className="mega-menu__link mega-menu__link--level-2 link">
                                    Independence Day 7/4
                                  </a></li><li data-title="Grandparents' Day 9/8" data-position="6">
                                  <a data-title="Grandparents' Day 9/8" data-position="6" href="https://www.lovepop.com/collections/grandparents" className="mega-menu__link mega-menu__link--level-2 link">
                                    Grandparents' Day 9/8
                                  </a></li><li data-title="Halloween 10/31" data-position="7">
                                  <a data-title="Halloween 10/31" data-position="7" href="https://www.lovepop.comp/collections/halloween-lp" className="mega-menu__link mega-menu__link--level-2 link">
                                    Halloween 10/31
                                  </a></li><li data-title="Diwali 11/12" data-position="8">
                                  <a data-title="Diwali 11/12" data-position="8" href="https://www.lovepop.comp/collections/diwali" className="mega-menu__link mega-menu__link--level-2 link">
                                    Diwali 11/12
                                  </a></li><li data-title="Thanksgiving 11/23" data-position="9">
                                  <a data-title="Thanksgiving 11/23" data-position="9" href="https://www.lovepop.comp/collections/thanksgiving" className="mega-menu__link mega-menu__link--level-2 link">
                                    Thanksgiving 11/23
                                  </a></li><li data-title="Hanukkah 12/7" data-position="10">
                                  <a data-title="Hanukkah 12/7" data-position="10" href="https://www.lovepop.comp/collections/hanukkah" className="mega-menu__link mega-menu__link--level-2 link">
                                    Hanukkah 12/7
                                  </a></li><li data-title="Christmas 12/25" data-position="11">
                                  <a data-title="Christmas 12/25" data-position="11" href="https://www.lovepop.comp/collections/pop-up-christmas-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Christmas 12/25
                                  </a></li><li data-title="New Year 1/1" data-position="12">
                                  <a data-title="New Year 1/1" data-position="12" href="https://www.lovepop.comp/collections/new-years-eve-3d-pop-up-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    New Year 1/1
                                  </a></li><li data-title="Lunar New Year 1/29" data-position="13">
                                  <a data-title="Lunar New Year 1/29" data-position="13" href="https://www.lovepop.comp/collections/lunar-new-year" className="mega-menu__link mega-menu__link--level-2 link">
                                    Lunar New Year 1/29
                                  </a></li><li data-title="Valentine's Day 2/14" data-position="14">
                                  <a data-title="Valentine's Day 2/14" data-position="14" href="https://www.lovepop.comp/collections/valentines-day-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Valentine's Day 2/14
                                  </a></li></ul>

                            



                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.comp/collections/valentines-day-pop-up-cards">
                                  <img src="//www.lovepop.com/cdn/shop/files/Mega_Menu_Modules_valentine_s_day.jpg?v=1706263502&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>
                              

                              
                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.comp/collections/lunar-new-year">
                                  <img src="//www.lovepop.com/cdn/shop/files/1_0b8376e4-64c4-4fc7-9c92-d5a525d2df80.jpg?v=1706263502&amp;width=400" alt="" width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>
                              
  </div>
                        </div>
                      </details>
                    </header-menu></li><li data-position="3" data-title="Recipient" data-top-nav="true" className=""><header-menu>
                      <details id="Details-HeaderMenu-4" className="mega-menu">
                        <summary className="header__menu-item list-menu__item link focus-inset" role="button" aria-expanded="false" aria-controls="MegaMenu-Content-4">
                          <a data-position="3" data-title="Recipient" data-top-nav="true" href="https://www.lovepop.comp/collections/new-greeting-cards">Recipient</a>
                          {navArrow}
                        </summary>
                        <div id="MegaMenu-Content-4" className="mega-menu__content gradient motion-reduce global-settings-popup" tabindex="-1">
                          <div className="mega-menu__wrapper  page-width">
                            <ul className="mega-menu__list--columns" role="list"><li data-title="Kids" data-position="0">
                                  <a data-title="Kids" data-position="0" href="https://www.lovepop.comp/collections/kids-cards-toys-and-more" className="mega-menu__link mega-menu__link--level-2 link">
                                    Kids
                                  </a></li><li data-title="Mom" data-position="1">
                                  <a data-title="Mom" data-position="1" href="https://www.lovepop.comp/collections/mom-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Mom
                                  </a></li><li data-title="Dad" data-position="2">
                                  <a data-title="Dad" data-position="2" href="https://www.lovepop.comp/collections/dad-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Dad
                                  </a></li><li data-title="Grandparents" data-position="3">
                                  <a data-title="Grandparents" data-position="3" href="https://www.lovepop.comp/collections/grandparents" className="mega-menu__link mega-menu__link--level-2 link">
                                    Grandparents
                                  </a></li><li data-title="Her" data-position="4">
                                  <a data-title="Her" data-position="4" href="https://www.lovepop.comp/collections/cards-for-her" className="mega-menu__link mega-menu__link--level-2 link">
                                    Her
                                  </a></li><li data-title="Him" data-position="5">
                                  <a data-title="Him" data-position="5" href="https://www.lovepop.comp/collections/cards-for-him" className="mega-menu__link mega-menu__link--level-2 link">
                                    Him
                                  </a></li><li data-title="LGBTQ+" data-position="6">
                                  <a data-title="LGBTQ+" data-position="6" href="https://www.lovepop.comp/collections/lgbt" className="mega-menu__link mega-menu__link--level-2 link">
                                    LGBTQ+
                                  </a></li><li data-title="Teacher" data-position="7">
                                  <a data-title="Teacher" data-position="7" href="https://www.lovepop.comp/collections/cards-gifts-for-teachers" className="mega-menu__link mega-menu__link--level-2 link">
                                    Teacher
                                  </a></li></ul>

                            

                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.com/collections/cards-for-her">
                                  <img src="//www.lovepop.com/cdn/shop/files/Menu_SKU9.jpg?v=1677457769&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>
                              

                              
                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.comp/collections/kids-cards-toys-and-more">
                                  <img src="//www.lovepop.com/cdn/shop/files/Menu_SKU10.jpg?v=1677457788&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>


                              
  </div>
                        </div>
                      </details>
                    </header-menu></li><li data-position="4" data-title="Product Types" data-top-nav="true" className=""><header-menu>
                      <details id="Details-HeaderMenu-5" className="mega-menu">
                        <summary className="header__menu-item list-menu__item link focus-inset" role="button" aria-expanded="false" aria-controls="MegaMenu-Content-5">
                          <a data-position="4" data-title="Product Types" data-top-nav="true" href="https://www.lovepop.comp/collections/shop-greeting-cards-lp">Product Types</a>
                          {navArrow}
                        </summary>
                        <div id="MegaMenu-Content-5" className="mega-menu__content gradient motion-reduce global-settings-popup" tabindex="-1">
                          <div className="mega-menu__wrapper  page-width">
                            <ul className="mega-menu__list--columns" role="list"><li data-title="Greeting Cards" data-position="0">
                                  <a data-title="Greeting Cards" data-position="0" href="https://www.lovepop.comp/collections/shop-all-pop-up-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Greeting Cards
                                  </a></li><li data-title="Pop-Up Bouquets" data-position="1">
                                  <a data-title="Pop-Up Bouquets" data-position="1" href="https://www.lovepop.comp/collections/lovepop-flower-bouquet-shop" className="mega-menu__link mega-menu__link--level-2 link">
                                    Pop-Up Bouquets
                                  </a></li><li data-title="Card &amp; Gift Bundles" data-position="2">
                                  <a data-title="Card &amp; Gift Bundles" data-position="2" href="https://www.lovepop.comp/collections/lovepop-bundles" className="mega-menu__link mega-menu__link--level-2 link">
                                    Card &amp; Gift Bundles
                                  </a></li><li data-title="Gifts" data-position="3">
                                  <a data-title="Gifts" data-position="3" href="https://www.lovepop.comp/collections/gifts" className="mega-menu__link mega-menu__link--level-2 link">
                                    Gifts
                                  </a></li><li data-title="Handcrafted Paper Flowers" data-position="4">
                                  <a data-title="Handcrafted Paper Flowers" data-position="4" href="https://www.lovepop.comp/collections/handcrafted-paper-flowers" className="mega-menu__link mega-menu__link--level-2 link">
                                    Handcrafted Paper Flowers
                                  </a></li><li data-title="Decor" data-position="5">
                                  <a data-title="Decor" data-position="5" href="https://www.lovepop.comp/collections/seasonal-decor" className="mega-menu__link mega-menu__link--level-2 link">
                                    Decor
                                  </a></li><li data-title="Value Packs" data-position="6">
                                  <a data-title="Value Packs" data-position="6" href="https://www.lovepop.comp/collections/greeting-card-packs" className="mega-menu__link mega-menu__link--level-2 link">
                                    Value Packs
                                  </a></li><li data-title="Notecards" data-position="7">
                                  <a data-title="Notecards" data-position="7" href="https://www.lovepop.comp/collections/stationery" className="mega-menu__link mega-menu__link--level-2 link">
                                    Notecards
                                  </a></li><li data-title="Advent Calendars" data-position="8">
                                  <a data-title="Advent Calendars" data-position="8" href="https://www.lovepop.com/collections/advent-countdown-calendars" className="mega-menu__link mega-menu__link--level-2 link">
                                    Advent Calendars
                                  </a></li><li data-title="Ornaments" data-position="9">
                                  <a data-title="Ornaments" data-position="9" href="https://www.lovepop.comp/collections/ornaments" className="mega-menu__link mega-menu__link--level-2 link">
                                    Ornaments
                                  </a></li><li data-title="Pop-up Stickers" data-position="10">
                                  <a data-title="Pop-up Stickers" data-position="10" href="https://www.lovepop.comp/collections/stickerpop" className="mega-menu__link mega-menu__link--level-2 link">
                                    Pop-up Stickers
                                  </a></li><li data-title="Gift Wrap" data-position="11">
                                  <a data-title="Gift Wrap" data-position="11" href="https://www.lovepop.comp/collections/gift-tags" className="mega-menu__link mega-menu__link--level-2 link">
                                    Gift Wrap
                                  </a></li><li data-title="Plushpop" data-position="12">
                                  <a data-title="Plushpop" data-position="12" href="https://www.lovepop.com/collections/plushpop-plush-pop-up-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Plushpop
                                  </a></li><li data-title="Shop All" data-position="13">
                                  <a data-title="Shop All" data-position="13" href="https://www.lovepop.comp/collections/shop-greeting-cards-lp" className="mega-menu__link mega-menu__link--level-2 link">
                                    Shop All
                                  </a></li></ul>

                            


                              

                              
                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.comp/collections/lovepop-bundles">
                                  <img src="//www.lovepop.com/cdn/shop/files/Menu_SKU11.jpg?v=1677457812&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>
                              

                              
                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.comp/collections/handcrafted-paper-flowers">
                                  <img src="//www.lovepop.com/cdn/shop/files/Menu_SKU12.jpg?v=1677457839&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>

                              

                              
  </div>
                        </div>
                      </details>
                    </header-menu></li><li data-position="5" data-title="Pop Culture" data-top-nav="true" className=""><header-menu>
                      <details id="Details-HeaderMenu-6" className="mega-menu">
                        <summary className="header__menu-item list-menu__item link focus-inset" role="button" aria-expanded="false" aria-controls="MegaMenu-Content-6">
                          <a data-position="5" data-title="Pop Culture" data-top-nav="true" href="https://www.lovepop.comp/collections/pop-culture">Pop Culture</a>
                          {navArrow}
                        </summary>
                        <div id="MegaMenu-Content-6" className="mega-menu__content gradient motion-reduce global-settings-popup" tabindex="-1">
                          <div className="mega-menu__wrapper  page-width">
                            <ul className="mega-menu__list--columns" role="list"><li data-title="Bridgerton" data-position="0">
                                  <a data-title="Bridgerton" data-position="0" href="https://www.lovepop.com/collections/queen-charlotte-a-bridgerton-story" className="mega-menu__link mega-menu__link--level-2 link">
                                    Bridgerton
                                  </a></li><li data-title="Disney" data-position="1">
                                  <a data-title="Disney" data-position="1" href="https://www.lovepop.comp/collections/disney" className="mega-menu__link mega-menu__link--level-2 link">
                                    Disney
                                  </a></li><li data-title="Friends" data-position="2">
                                  <a data-title="Friends" data-position="2" href="https://www.lovepop.comp/collections/friends-cards-gifts" className="mega-menu__link mega-menu__link--level-2 link">
                                    Friends
                                  </a></li><li data-title="Harry Potter" data-position="3">
                                  <a data-title="Harry Potter" data-position="3" href="https://www.lovepop.comp/collections/harry-potter-cards-gifts" className="mega-menu__link mega-menu__link--level-2 link">
                                    Harry Potter
                                  </a></li><li data-title="Marvel" data-position="4">
                                  <a data-title="Marvel" data-position="4" href="https://www.lovepop.comp/collections/marvel" className="mega-menu__link mega-menu__link--level-2 link">
                                    Marvel
                                  </a></li><li data-title="Minions" data-position="5">
                                  <a data-title="Minions" data-position="5" href="https://www.lovepop.comp/collections/despicable-me" className="mega-menu__link mega-menu__link--level-2 link">
                                    Minions
                                  </a></li><li data-title="Star Wars" data-position="6">
                                  <a data-title="Star Wars" data-position="6" href="https://www.lovepop.comp/collections/star-wars" className="mega-menu__link mega-menu__link--level-2 link">
                                    Star Wars
                                  </a></li><li data-title="Stranger Things" data-position="7">
                                  <a data-title="Stranger Things" data-position="7" href="https://www.lovepop.com/collections/stranger-things" className="mega-menu__link mega-menu__link--level-2 link">
                                    Stranger Things
                                  </a></li><li data-title="Ted Lasso" data-position="8">
                                  <a data-title="Ted Lasso" data-position="8" href="https://www.lovepop.comp/collections/ted-lasso-cards-gifts" className="mega-menu__link mega-menu__link--level-2 link">
                                    Ted Lasso
                                  </a></li><li data-title="The Office" data-position="9">
                                  <a data-title="The Office" data-position="9" href="https://www.lovepop.comp/collections/the-office" className="mega-menu__link mega-menu__link--level-2 link">
                                    The Office
                                  </a></li><li data-title="Shop All Pop Culture" data-position="10">
                                  <a data-title="Shop All Pop Culture" data-position="10" href="https://www.lovepop.comp/collections/pop-culture" className="mega-menu__link mega-menu__link--level-2 link">
                                    Shop All Pop Culture
                                  </a></li></ul>

                            



                              
                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.comp/collections/ted-lasso-cards-gifts">
                                  <img src="//www.lovepop.com/cdn/shop/files/Pop_Culture_LP5108.jpg?v=1689103485&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>
                              

                              
                                <div className="mega-menu__image-link">
                                  <a href="https://www.lovepop.com/collections/disney">
                                  <img src="//www.lovepop.com/cdn/shop/files/Pop_Culture_LP4927.jpg?v=1689103485&amp;width=400" alt=""  width="400" height="400" loading="lazy" className="mega-menu-inner-img" />
                                  
                                    <span className="mega-menu__image-title"></span>
                                  
                                  </a>
                                </div>
  </div>
                        </div>
                      </details>
                    </header-menu></li>
                    
                    <li data-position="7" data-title="More" data-top-nav="true" className="d-none"><header-menu>
                      <details id="Details-HeaderMenu-8" className="mega-menu">
                        <summary className="header__menu-item list-menu__item link focus-inset" role="button" aria-expanded="false" aria-controls="MegaMenu-Content-8">
                          <a data-position="7" data-title="More" data-top-nav="true" href="#">More</a>
                          {navArrow}
                        </summary>
                        <div id="MegaMenu-Content-8" className="mega-menu__content gradient motion-reduce global-settings-popup" tabindex="-1">
                          <div className="mega-menu__wrapper  page-width">
                            <ul className="mega-menu__list--columns" role="list"><li data-title="Best Sellers" data-position="0">
                                  <a data-title="Best Sellers" data-position="0" href="https://www.lovepop.comp/collections/best-selling-pop-up-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    Best Sellers
                                  </a></li><li data-title="Sale" data-position="1">
                                  <a data-title="Sale" data-position="1" href="https://www.lovepop.comp/collections/the-vault/the-vault" className="mega-menu__link mega-menu__link--level-2 link">
                                    Sale
                                  </a></li><li data-title="New Releases" data-position="2">
                                  <a data-title="New Releases" data-position="2" href="https://www.lovepop.comp/collections/new-greeting-cards" className="mega-menu__link mega-menu__link--level-2 link">
                                    New Releases
                                  </a></li><li data-title="5 for $50" data-position="3">
                                  <a data-title="5 for $50" data-position="3" href="https://www.lovepop.comp/collections/5-for-50" className="mega-menu__link mega-menu__link--level-2 link">
                                    5 for $50
                                  </a></li></ul>       
                            </div>
                        </div>
                      </details>
                    </header-menu></li>
                    
                    <li data-position="8" data-title="5 for $50" data-top-nav="true" className=""><a data-position="8" data-title="5 for $50" data-top-nav="true" href="https://www.lovepop.comp/collections/5-for-50" className="header__menu-item list-menu__item link link--text focus-inset">
                      <span>
                        
                        5 for $50
                      </span>
                    </a></li></ul>
          </nav>
        </div>
			  <p style={{textAlign:'center',fontSize:'26px', marginTop:'16px'}}>{props.title}</p>
			</>
		);
	}




	getSharedFizzVideoWrap = (children) => {
		return (
      <div className="row share-video-panel">
        <div className="col-lg-8 mb-3">
          {children}
        </div>
        <div className="col-lg-4">
          <a href="https://www.lovepop.com/collections/new-greeting-cards?featured=LP5124,LP5203" >
            <img className="d-block d-lg-none" width="100%" src="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/Feb_22_-_New_Releases_Suncatcher_Cards_DESKTOP_BANNER_1200x600_744329f4-4a39-45a7-b163-42b933d12099.png?v=1708461209" alt="Suncatcher Cards" />
            <img className="d-none d-lg-block" width="100%" src="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/Feb_22_-_New_Releases_Suncatcher_Cards_MOBILE_BANNER_500x700_48418c98-e2c2-48e1-ae7f-6ed192f6dede.png?v=1708461233"  alt="Suncatcher Cards" />
          </a>
        </div>

      </div>
    );
	}

  getSharedFizzContentBottom = (props) => {
    return (
      <div className="row mt-4">
        <div className="col-6 col-md-3">
          <div className="lovepop-option" >
            <a href="https://www.lovepop.com/collections/easter" >
              <HoverImage 
                src="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/Easter_Basket_Overview.jpg" 
                hover="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/Easter_Basket_Detail.jpg" />
              Easter Favorites
            </a>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="lovepop-option" >
            <a href="https://www.lovepop.com/collections/birthday-cards" >
              <HoverImage 
                  src="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/LP4014_SprinklesBirthdayCake_CoverOveview.jpg" 
                  hover="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/Renders-LP4014_SprinklesBirthdayCake-1-Detail.jpg" />
              Birthday Bestsellers
            </a>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="lovepop-option" >
            <a href="https://www.lovepop.com/collections/new-greeting-cards" >
              <HoverImage 
                  src="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/LP5353_WithDeepestSympathyPop-UpCard_CoverOverview.jpg" 
                  hover="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/LP5353_WithDeepestSympathyPop-UpCard_Sentimental-1.jpg" />
              New Releases
            </a>
          </div>
        </div>
        <div className="col-6 col-md-3">
          <div className="lovepop-option" >
            <a href="https://www.lovepop.com/collections/5-for-50" >
              <HoverImage 
                  src="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/LP2671_HappyBirthday_CoverOverview.jpg" 
                  hover="https://vfair.s3.us-west-2.amazonaws.com/org/branding/love-pop/site-assets/LP2671_HappyBirthday_Detail.jpg" />
              5 for $50
            </a>
          </div>
        </div>
      </div>
    )
  }
} 


export default Lovepop