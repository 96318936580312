import React, { Component } from 'react';
import SpecialField from '../components/SpecialField';

export const buildSpecialFieldImageString = (specialFieldsToSend) => {
    let imageString = "";
    if (specialFieldsToSend) {
      specialFieldsToSend.map((item) => {
        imageString +=  cleanString(item.value)+`\n`;
      });
    }
    if( !imageString ) {
    	imageString = "\n";
    }
    return encodeURIComponent(imageString);
  };



export const getSpecialFieldsToSend = (groups) => {
	var fields = [];
	for (var i = 0; i < groups.length; i++) {
	  var group = groups[i];
	  fields = fields.concat(group.items);
	}
	return fields;
}

export const getPreviewImageUrlsFromImageString = (taId, previewImageCount, imageString)=>{
	var previewImages = [];
    for (var i=0; i<previewImageCount; i++) {
      previewImages.push(
        "/rest/theme-animation/" +
          taId +
          "/image-preview/" +
          i +
          "/" +
          imageString
      );
    }
    return previewImages;
}

export const getPreviewImageUrls = (taId, previewImageCount, specialFieldsToSend)=>{
  if( previewImageCount === 0 ){
    return [];
  }

  previewImageCount = previewImageCount || 1;
	var imageString = buildSpecialFieldImageString(specialFieldsToSend);
  return getPreviewImageUrlsFromImageString( taId, previewImageCount, imageString );
}


export const validateFieldsToSend = (specialFieldsToSend)=>{
	
    var areAllValid = true;
    for( var i=0; i<specialFieldsToSend.length; i++ ){

      var field = specialFieldsToSend[i];
      if( field.required==1 ){
        field.invalid = field.value ? (field.value.length===0) : true;
      }
      if( specialFieldsToSend[i].invalid ){
        areAllValid = false;
      }
    }
    return areAllValid;
}

export const cleanString = (string) => {
  const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

  return string.replace(regex, '');
};

  

export default class SpecialFieldEditor extends Component {

	constructor(props) {
		super(props);

		this.state = {
		}
	}
	onFieldChange = (field) => {
    if( this.props.onSpecialFieldsChange ) {
    	this.props.onSpecialFieldsChange(this.props.specialFields);
    }
	}

	render() {
		if( !this.props.specialFields || this.props.specialFields.length<=0 ) {
			return null;
		}

		return (
			<div>
				{this.props.specialFields.map((fieldGroup, index) => {
					return (
						<SpecialField key={"field-"+index} fieldGroup={fieldGroup} onFieldChange={this.onFieldChange} />
					)
				})}
			</div>
		)

	}
}