import React, { Component } from "react";
import { apiFetch,translateUrls } from "../helpers/APIHelper";
import { getMailToLink, getSMSLink, isAndroid, isIOS, canShare, isMobile } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { toastManager } from "../components/Toaster";
import FooterBar from "./FooterBar";
import Branding from "../brands/Branding";


export default class InviteOptions extends Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      fizzId: this.props.fizzId,
      inviteMessage:"",
      inviteLink: "",
      shareLink: "",
      showImMakingThisAlone: true,
    }
  }

  componentDidMount() {
    this._getData();

  }

  _getData = () => {
    this.setState({
      loading: true
    });

    apiFetch('/rest/fizz/' + this.state.fizzId + '/invite', {
      method: 'GET',
    }, (json) => {
      console.log(json);
      var item = json.item ? json.item : json;
      this.setState({
          loading: false,
          inviteMessage:  Branding.getInviteSample(item.inviteSampleNoLink),
          inviteLink: translateUrls(item.inviteLink),
          linkToSend:  Branding.getInviteSample(item.inviteSampleNoLink) + '\n\n' + translateUrls(item.inviteLink)
      });


    }, (error) => {
      console.log(error);
      this.setState({
        loading: false,
        error: ((error&&error.message)||error)+""
      })
    });
  }

  onInviteChange=(e)=>{
    this.setState({
      inviteMessage:e.target.value,
      linkToSend: e.target.value + '\n\n' + this.state.inviteLink
    })
  }

  copyToClipboard=()=>{
    navigator.clipboard.writeText(this.state.linkToSend);
    toastManager.showToast({
			message: 'Copied to Clipboard!'
    });
    this.trackInviteClick();
  }
  
  shareAny=()=>{
    navigator.share({
      text: this.state.linkToSend
    }).then(() => {
      // alert("Sent");
    }).catch((e)=>{
      // Canceled or something else
      // alert("Error"+e);
    })
    this.trackInviteClick();
  }

  trackInviteClick = () => {

    this.setState({
      showImMakingThisAlone:false
    })
    
    apiFetch('/rest/tracking/best/invite-intent?fizzId=' + this.state.fizzId, {
      method: 'POST',
      data: { value: 1, },
    }, (json) => {

      //fire and forget
    
    }, (error) => {
    
      console.log(error);
    
    });

    if( this.props.onInviteIntent ){
      this.props.onInviteIntent();
    }
  }

  render() {

    var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;

    let smsLink = getSMSLink({body:this.state.linkToSend});
    let emailLink = getMailToLink({body:this.state.linkToSend, subject:"Help me create a surprise group video"});

    return (
      <>
        <div className="row">
        <div className="col-lg-10 offset-lg-1">



          <div className="row pt-3 align-items-center justify-content-center ">
        

            <div className="col-lg-8 text-center">
              
              {this.state.error ? (
                <div className=" text-center alert alert-danger">
                  {this.state.error}
                </div>
              ):(
                <>

                  {this.state.loading && 
                    <Spinner float={true} />
                  }
                  <p className="mb-3">{Branding.getInviteBody()}</p>

                  <textarea className="invite-info" value={this.state.inviteMessage} onChange={this.onInviteChange} />

                  {!this.state.loading && 
                    <>
                        { hasClipboard &&
                          <div className="mt-4 mb-3">
                            <button className="btn btn-link btn-small" onClick={this.copyToClipboard} >
                              Copy Invite and paste anywhere
                            </button>
                          </div>
                        }
                    </>
                  }
                </>
              )}
              
            </div>
          </div>
        </div>

      </div>

       <FooterBar>


             {!this.state.loading &&


               <>
                 



                { canShare() && isMobile() ?
                  <>
                    { isAndroid() ?
                      <>
                      {/* this needs more refactoring -- maybe make a dropdown/pullup option to select either if both. */}
                        <div>
                          <a className="btn btn-primary btn-block btn-lg-inline-block  mb-2 me-1" onClick={this.trackInviteClick.bind(this)} href={smsLink}>
                            Text This Invite
                          </a>
                        </div>
                        <button className="btn btn-primary btn-block btn-lg-inline-block  mb-2 me-1" onClick={this.shareAny}>
                          More Sending Options
                        </button>
                      </>
                      :
                      // isIOS
                      <>
                        <button className="btn btn-primary btn-block btn-lg-inline-block  mb-2 me-1" onClick={this.shareAny}>
                          Send This Invite
                        </button>
                      </>
                    }
                  </>
                :
                  <>
                    <a className="btn btn-primary btn-block btn-lg-inline-block mb-2 me-1" target="_blank" onClick={this.trackInviteClick.bind(this)} href={emailLink}>
                      Email This Invite
                    </a>
                  </>
                }


                { !this.state.showImMakingThisAlone &&

                  <button
                    tabIndex="0"
                    onClick={this.props.moveToNextFlow}
                    className="btn btn-block btn-lg-inline-block btn-outline-primary  mb-2 next-btn "
                  >
                    NEXT
                  </button>
                }

                { this.state.showImMakingThisAlone &&

                  // consider making css class for this.  
                    <button className="btn btn-block btn-lg-inline-block btn-outline-primary  mb-2" onClick={ this.props.moveToNextFlow}  >
                      Next
                    </button>
                }

               </>
             }

        </FooterBar>

      </>
    );
  }
}
