"use client"

import React from 'react';
import "../assets/css/spinner.css";


export default function Spinner(props) {

	var spinner = (
    <div className={'lds-spinner '+ (props.className||'black') } style={props.style}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  );


  if( props.float ) {
    return (
          <div className="floating-spinner">
            {spinner}
          </div>
    );
  } else if( props.centerFloat ) {
    return (
          <div style={{position:'absolute',zIndex:1000,left:'50%',top:'20%',marginLeft:'-30px'}} >
            {spinner}
          </div>
    );

  } else if( props.centerBlock ) {
    return (
          <div className="text-center" >
            {spinner}
          </div>
    );

  } else {
  	return spinner
  }
}
