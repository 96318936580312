import React, { Component } from "react";
import { Link, Prompt } from "react-router-dom";
import { getBaseURL, getFizzURLKey } from "../helpers/URLHelper";
import NavBar from "../components/NavBar";
import Spinner from "../components/Spinner";
import { apiFetch } from "../helpers/APIHelper";
import IconMove from "../assets/images/vf-icons/icon-move.svg";
import MainContent from "../components/MainContent";
import AddButtonGroup from "../components/AddButtonGroup";
import SortableClipList from "../components/SortableClipList";
import MusicPicker, { getSelectedMusic } from "../components/MusicPicker";
import InviteOptions from "../components/InviteOptions";
import FizzSpecialFields from "../components/FizzSpecialFields";
import MusicListField from "../components/MusicListField";
import FooterBar from "../components/FooterBar";
import NextButton from "../components/NextButton";
import ThemedIcon from "../components/ThemedIcon";
import Branding from "../brands/Branding";
import { MusicPickerModal } from "../components/MusicPickerModal";
import SaveProgressModal from "../modal/SaveProgressModal";


const ARE_YOU_SURE_UPLOADING = "Your upload hasn’t finished. Are you sure you want to leave?";

class EditFizzFlow extends Component {
  constructor(props) {
    super(props);

    this.clipList; //= React.createRef();

    this.state = {
      clips: [],
      inputBox: "",
      processMessage: "",
      fizz: null, // NULL UNTILL LOADED,
      uploadingList: [],
      showMusicPicker: false,
      music: null,
      videoId: null,
      isUploading: false,
      currentStep: null, 
      hasContactInfo: false,
      hasSeenSaveProgressModal: false,
      showSaveProgessModal: false,
    };
    this.loadProps(props);
  }
  componentDidUpdate(nextProps) {
    if (this.loadProps(nextProps)) {
      this._getData();
    }
  }
  loadProps(props) {
    var old = this.state.fizzId;

    this.state.fizzId = props.match.params.fizzId;

    return old != this.state.fizzId;
  }
  componentDidMount() {
    this._getData();
  }

  onShowMusicModal = () => {

    this.setState({
      showMusicPicker: true,
    });
  };


  playAudio = (music) => { 
    // Stop the old
    if(this.state.pickedMusic != music && this.state.pickedMusic != null) {
        var y = document.getElementById('music-'+this.state.pickedMusic.id);
        if( y ) {
            y.pause();
        }
    }
    this.setState({
        pickedMusic: music
    });

    // Play new
    var x = document.getElementById('music-'+music.id);
    if( x ) {
        x.play();
        return x;
    }
  }

  onCloseMusic = () => {
    this.setState({
        showMusicPicker: false,
    });
  };

  onSelectMusic = (picked) => {
    this.setState({
        selectedMusic: picked// Only use this to set the default saved if it is not set
    });
  };

  onSaveMusic = (picked) => {

    if ( picked ){

      this.setState({
        musicSaveStatus: "Saving...",
      });
      apiFetch(
        "/rest/fizz/" + this.state.fizzId + "/music",
        {
          method: "PUT",
          data: {
            musicId: picked.id,
          },
        },
        (json) => {
          
          this.setState(prevState => ({
            musicSaveStatus: null,
            showMusicPicker: false,
            selectedMusic: picked,
            music: {
              ...prevState.music,
              defaultMusicId: picked.id
            }
          }));
          
        },
        (error) => {
          this.setState({
            musicSaveStatus: error + "",
          });
          console.log(error);
        }
      );
    }
  };

  _getData = () => {
    this.setState({
      error: null,
      isLoading: true,
    });
    apiFetch(
      "/rest/fizz/" +
        this.state.fizzId +
        "/detail?verison=2&cache=" +
        Math.round(Math.random() * 100000000),
      {
        method: "GET",
      },
      (json) => {
        if( json.fizz.isLocked ){
          // Fizz is locked - prevent any edits

          this.props.history.push( getBaseURL( `/vid/${json.fizz.fizzUUID}` ) );
          return;
        }

        this.setState({

            isLoading: false,
            fizz: json.fizz,
            music: json.fizz.music,
            selectedMusic: getSelectedMusic(json.fizz.music),

            newestActorVideoId: json.fizz.newestActorVideoId,
            disableNewestActor: json.disableNewestActor,
            currentStep: json.fizz.currentStep,
  
            taId: json.fizz.taId,
            specialFields: json.fizz.specialFields,
            previewImageCount: json.fizz.previewImageCount,

            recordType: json.fizz.recordType,
            showQRStyles: json.fizz.showQRStyles,

            hasContactInfo: json.user.hasContactInfo,
  
        });

      },
      (error) => {
        console.log(error);
        this.setState({
          error: error.message,
          isLoading: false
        });

      }
    );
  };

  nextFromMusicFlow = () => {
    this.onSaveMusic(this.state.selectedMusic); 
    this.moveToNextFlow( this.state.currentStep );
  }

  sendMakeAlone = () => {
    //consider doing more here.
    this.moveToNextFlow( this.state.currentStep );
  }

  moveToNextFlow = ( currentStep ) => {
    var newStateStep = currentStep;

    if( currentStep < 4 ){
      newStateStep++;

    }

    if( newStateStep >= 4 ){
      // go to preview
      this.props.history.push( getBaseURL( `/preview/${this.state.fizzId}` ) );
      // this.props.history.push( "/preview/" + this.state.fizzId );

    } else {
      window.scrollTo(0, 0);
      this.setState({
        currentStep : newStateStep
      });
    }

    apiFetch(
      "/rest/fizz/" +
        this.state.fizzId +
        "/update-current-step?cache=" +
        Math.round(Math.random() * 100000000),
      {
        method: "POST",
        data: { currentStep: newStateStep },
      },
      (json) => {
        // fire and forget
        
        // user saw save progress modal but did not 
        
        if( !json.hasContactInfo ){
          this.setState({
            hasContactInfo: json.hasContactInfo,
            hasSeenSaveProgressModal: false
          });

        } else {
          this.setState({
            hasContactInfo: json.hasContactInfo
          });
          
        }

      },
      (error) => {
        console.log(error);
      }
    );
  };
  
  clipListRef = (el)=>{
    console.log("Ref Call",el)
    this.clipList = el;
  }

  onFileUploadChange = (event) => {
    // this.clipListRef.onFileUploadChange(event);
    console.log( this.clipList );
    this.clipList.onFileUploadChange(event);
    this.toggleSaveProgressModal();
  }

  onItemAdded = () => {
    console.log("item added");
    this.toggleSaveProgressModal();
  }

  toggleSaveProgressModal = () => {
    if( !this.state.hasContactInfo && !this.state.hasSeenSaveProgressModal ) {
      console.log( "Show Save Progress Modal.")
      this.setState({
        showSaveProgessModal: true,
        hasSeenSaveProgressModal: true
      });

    } else {
      console.log("Contact info already provided or modal already shown");
      this.setState({
        showSaveProgessModal: false,
      });
    }
  }

  onInviteIntent = () => {
    console.log( "Invite Intent called" );
    this.toggleSaveProgressModal();
  }

    // Content Blocks

    renderMusicContent = () => {
      return (
          <div className="screen-wrap">
            <NavBar title="Your Project" />

            <MainContent className="container bg-margin">
              <h1 className="headline d-flex flex-column-reverse flex-md-row align-items-center text-right mt-3 mb-3 mr-2 mr-md-4">
                Choose your Music
              </h1>

              <div className="col-lg-8 offset-lg-2 mt-4 pt-lg-3">
                <MusicPicker 
                  music={this.state.music}
                  onSelectMusic = {this.onSelectMusic}
                />

              </div>

              <FooterBar >
                <NextButton onClick={()=>(this.nextFromMusicFlow(this.state.currentStep))} />
              </FooterBar>

            </MainContent>

          </div>
      );
    }




    renderInviteContent = () => {
      return (
        <div className="screen-wrap">
          <NavBar title="Your Project" />
      
          <MainContent className="container">
            <h1 className="headline headline-stretch d-flex flex-column-reverse flex-md-row align-items-center text-right mt-3 mb-3 mr-2 mr-md-4" >
              {Branding.getInviteHeadline()}
            </h1>
            <InviteOptions 
              fizzId={this.state.fizzId}
              moveToNextFlow={()=>{this.moveToNextFlow( this.state.currentStep )}}
              onInviteIntent = {this.onInviteIntent }
            />


            {/* contact info modal */}
            <SaveProgressModal 
              show={this.state.showSaveProgessModal}
              onHide={this.toggleSaveProgressModal}
              fizzId={this.state.fizzId}
              from={'invite'}
              />
          </MainContent>
        </div>    
      );
    }


  renderContent() {

    var emptyState = this.state.clips.length <=0  && this.state.uploadingList.length <=0;

    return (
      <>
        <h1 className="headline headline-stretch d-flex flex-column-reverse flex-md-row align-items-center text-right mt-3 mb-3 mr-2 mr-md-4" >
          ADD PHOTOS AND VIDEOS
        </h1>

        <div className="row align-items-center justify-content-center bg-margin">
          <div className="col-lg-12 mt-3 px-0 px-lg-3">
            <section className="fizz-clip-list">
              
              <SortableClipList 
                onClipsChange={this.onClipsChange}
                fizzId={this.state.fizzId}
                ref={ this.clipListRef }
                recordPath={ `/p/${getFizzURLKey()}/record/${this.state.fizzId}`}
                onItemAdded={ this.onItemAdded }
              />

            { emptyState &&
                <>
                  <small id="mobile-move-hint" className="d-flex align-items-center m-3">
                    <ThemedIcon icon={IconMove} />
                    <em> &nbsp; PRESS AND HOLD three lines to re-order</em>
                  </small>
                </>
              }
            </section>
          </div>

          <FooterBar >
            <AddButtonGroup
                onFileUploadChange={this.onFileUploadChange}
                recordPath={ `/p/${getFizzURLKey()}/record/${this.state.fizzId}`}
            />
            <button className="btn btn-outline-primary btn-block btn-lg-inline-block next-btn me-2" onClick={()=>(this.moveToNextFlow(this.state.currentStep))} >NEXT</button>
          </FooterBar>
          
        </div>
      </>
    )
  } 

  onClipsChange = (clips)=>{
    this.setState({
      clips:clips
    })

    if( clips.length > 0 ){
      this.toggleSaveProgressModal();
    } 
  }


  renderPostPreviewContent() {
    var emptyState = this.state.clips.length <=0  && this.state.uploadingList.length <=0;

    return (
        <>
        {this.state.showQRStyles ? (
          <h1 className="headline-simple text-center my-3">
            Edit Your <b>Video</b>
          </h1>
        ):(

          <h1 className="headline d-flex flex-column-reverse flex-md-row align-items-center text-right mt-3 mb-3 mr-2 mr-md-4">
          {
            emptyState ? 'Create Your Video' : 'Edit Your Video'
          }
          </h1>
        )}

          <div className="row justify-content-center bg-margin">
            <div className="col-lg-4 mt-md-4 mb-4">

              <FizzSpecialFields fizzId={this.state.fizzId} taId={this.state.taId} specialFields={this.state.specialFields} previewImageCount={this.state.previewImageCount} />
            
              <MusicListField displayErr={this.state.displayErr} music={this.state.music} showMusicList={this.onShowMusicModal} selectedMusic={this.state.selectedMusic} />
  
            </div>
                  
            <div className="col-lg-8">

              <section className="fizz-clip-list">
               
                <SortableClipList 
                  onClipsChange={this.onClipsChange}
                  fizzId={this.state.fizzId}
                  ref={ this.clipListRef }
                  recordPath={ `/p/${getFizzURLKey()}/record/${this.state.fizzId}`}
                  onItemAdded={ this.onItemAdded }
                />
              
                <small id="mobile-move-hint" className="d-flex align-items-center m-3">
                  <ThemedIcon icon={IconMove} />
                  <em>PRESS AND HOLD three lines to re-order</em>
                </small>
              </section>

            </div>
          </div>

          <FooterBar>   
              <Link
                to={`/p/${getFizzURLKey()}/invite-others/` + this.state.fizzId}
                className="btn btn-link btn-lg-inline-block btn-block mb-2 text-center me-4"
              >
                {Branding.getInviteOthersBtnText()}
              </Link>

              <AddButtonGroup
                    onFileUploadChange={this.onFileUploadChange}
                    primaryText={ this.state.clips.length <= 0 ? "ADD PHOTOS OR VIDEOS" : "ADD MORE PHOTOS OR VIDEOS"}
                    recordPath={ `/p/${getFizzURLKey()}/record/${this.state.fizzId}`}
                  />      
              <button className="btn btn-outline-primary btn-block btn-lg-inline-block next-btn me-2" onClick={()=>(this.moveToNextFlow(this.state.currentStep))} >NEXT</button>
          </FooterBar>
      </>
  )}

  render() {

    if( this.state.error ) {
      return (
        <div className=" text-center alert alert-danger mt-4">
          {this.state.error}
        </div>
      )
    }

    if( this.state.isLoading ){
      return(
        <div className="screen-wrap">
          <MainContent className="container bg-margin">
            <Spinner float={true} />
          </MainContent>
        </div>
      )
    }

    if( this.state.currentStep && this.state.currentStep == 1 ){
      return(
        this.renderMusicContent()
      )
    }

    if( this.state.currentStep && this.state.currentStep == 2 ){
      return(
        this.renderInviteContent()
      )
    }

    return (
      <div className="screen-wrap">
        <NavBar title="Your Project" />
        
        <Prompt when={this.state.isUploading} message={ARE_YOU_SURE_UPLOADING} />

        <MainContent className="container">

          {/* The Bulk of the page */}

          { this.state.currentStep && ( this.state.currentStep > 3 || this.state.currentStep == 0 ) ?  (
          
            this.renderPostPreviewContent()
          
            ) : (

            this.renderContent() 

          )}



          <MusicPickerModal 
            showMusicPicker={this.state.showMusicPicker}
            onHide={this.onCloseMusic}
            music={this.state.music}
            onSave={this.onSaveMusic}
          />


          {/* save progress modal */}
          <SaveProgressModal 
            show={this.state.showSaveProgessModal}
            onHide={this.toggleSaveProgressModal}
            fizzId={this.state.fizzId}
            from={'edit'}
          />

        </MainContent>
        {/* <Footer hideMobile={true} /> */}

      </div>
    );
  }
}

export default EditFizzFlow;