import React, { Component } from "react";
import { Link } from 'react-router-dom';
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import LoadingBar from "../components/LoadingBar";
import { apiFetch, saveSession  } from "../helpers/APIHelper";
import { getFizzURLKey, getInviteFizzUUID, getBaseURL } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import ProgressUploader from "../helpers/ProgressUploader";
import BannerBar from "../components/BannerBar";
import MainContent from "../components/MainContent";
import Notify from "../components/Notify";
import AddButtonGroup from "../components/AddButtonGroup";
import HowItWorksBanner from "../components/HowItWorksBanner";
import Branding from "../brands/Branding";


export default class ViewInvite extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      fizzId: null,
      message: '',
      uploadingList:[],
      hasErrors: false,
      errorsCleared: false
    }
  }

  componentDidMount() {
    this._getData();
  }

  _getData = () => {
    this.setState({
      loading: true
    });

    apiFetch(`/rest/invite/${getInviteFizzUUID()}/fizz?cache=${Math.round(Math.random() * 100000000)}`, {
      method: 'GET',
    }, (json) => {
      console.log(json);
      this.setState({
        loading: false,
        fizzId: json.id,
        fizzImg: json.img,
        message:json.message,
        name: json.name,
        recordType: json.recordType
      });

      saveSession(json);

    }, (error) => {
      this.setState({
        loading: false,
        error: error+""
      })
    });
  }


  onFileUploadChange = (event) => {
    var acceptedFiles = Array.from(event.target.files);
    console.log("Select", acceptedFiles);
    this.startUpload(acceptedFiles);
    event.target.value = null;
  };

  oneDone = (uploader) => {
    // If Error uploading, inform user of error
    if ( uploader.response.status != 'success' ){

      console.log("Error: ", uploader.response );
      this.setState( { hasErrors: true } );
    } else {
      console.log("Upload Done: ", uploader.response);
    }

    var allDone = true;
    for (var i = 0; i < this.state.uploadingList.length; i++) {
      if ( !this.state.uploadingList[i].isDone() ) {
        allDone = false;
        break;
      }
    }
    this.setState({
      uploadingList: this.state.uploadingList,
      currentUploadCount: this.state.currentUploadCount + 1
    });

    if( !allDone ) {
      this.startNext();
    } else {
      this.allDone();
    }
  }

  allDone = ()=>{
    if( !this.state.hasErrors ){
      
      this.props.history.push( getBaseURL( `/in/${getInviteFizzUUID()}/thank-you` ) );
    }
  }



  startNext = ()=>{
    console.log("startNext")
    for (var i = 0; i < this.state.uploadingList.length; i++) {
      if (this.state.uploadingList[i].status == "idle") {
        // Start one that is idle
        console.log("startNext", this.state.uploadingList[i])

        var started = this.state.uploadingList[i].start("POST", `/rest/fizz/${this.state.fizzId}/add-clip?type=invite&uuid=${getInviteFizzUUID()}` );
        console.log( "Started", started);
        started.then(this.oneDone)
          .catch(this.oneDone);
        break;
      }
    }
  }

  startUpload = (files) => {

    this.setState({
      isUploading:true,
      currentUploadCount: 1,
      totalUploadCount: files.length
    })


    var uploads = [];
    for (var i = 0; i < files.length; i++) {
      let file = files[i];

      let uploader = new ProgressUploader(file, (uploader) => {
        // Progress
        console.log(uploader.getPercent());
        // Call set state to refresh
        this.setState({
          uploadingList: this.state.uploadingList
        });
      });
      uploader.key = Math.random();
      uploader.loadImage();
      uploads.push(uploader);
    }

    this.setState({
      uploadingList: this.state.uploadingList.concat(uploads),
    },()=>{
      if( this.state.uploadingList.length>0 ) {
        this.startNext();
      }
    });
  };

  onHideUpload( uploader ) {
    let updatedList = [];
    if( this.state.uploadingList.length > 1 ){
      for( var h = 0; h < this.state.uploadingList.length; h++ ){
        if ( uploader === this.state.uploadingList[h] ){
          this.state.uploadingList.splice( h, 1 )
          updatedList = this.state.uploadingList;
         
        }
      }
    } 

    this.setState({ 
      errorsCleared: true,
      isUploading: false,
      hasErrors: false,
      uploadingList: updatedList
    });

  }


  renderContent(){

    if( this.state.error ) {
      return  <p className="alert alert-danger">{this.state.error}</p>
    }
    if( this.state.isLoading ){
      return <Spinner float={true} />
    }


    if( this.state.isUploading ) {
      return (
        <>
          { this.state.errorsCleared &&
            <>
              <Link
                to={`/in/${getInviteFizzUUID()}/thank-you`}
                className="inviteContinueButton btn btn-outline-primary ms-lg-6" 
                >
                Continue
              </Link>
              <div className="mt-3"></div>
            </>
          }

          <div className="row">  
            <div className="col-md-4 offset-md-4">
              <div className="text-center mb-3">
                <h3 className="upload-count">{"Uploading " + this.state.currentUploadCount + " of " + this.state.totalUploadCount + "..." }</h3>
              </div>
            </div>
          </div>

          {this.state.uploadingList.map((upload, index) => {
            return (
                <div key={`upload-${upload.key}`} className="row align-items-center heading_video">
                  <div className="col-lg-10 col-8">
                    { upload.getStatusDisplay() != 'Error Uploading' ?
                      <>
                        <p>{upload.getStatusDisplay()}</p>
                        <LoadingBar percent={upload.getPercent()} />
                      </>
                    :
                      <Notify message={ upload.response.message } error="true" onHide={this.onHideUpload.bind(this, upload ) } />
                    }
                  </div>
                  <div
                    className="col-lg-2 col-4 text-end notSelectable"
                    key={`clip-${upload.key}`}
                  >
                    <div className="card card-img-top" >
                      <div className="notSelectable box-480" unselectable="on" >
                        {upload.img && (
                            <img 
                              className="box-480-item nonDraggableImage notSelectable uploading-video"
                              src={upload.img}/>
                        )}
                        {upload.video && (
                            <video 
                              className="box-480-item nonDraggableImage notSelectable uploading-video"
                              draggable={false}
                              unselectable="on"
                            >
                              <source src={upload.video} />
                            </video>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </>
      );
    }


    return (<>

        <div className="col-md-7">
            { Branding.getViewInviteImg() }
        </div>

        <div className="col-md-5">

          <h5 className="subheadline mb-3">{Branding.getViewInviteBody(this.state.message)}</h5>

          <div className="text-end" >
            { Branding.isViewInviteRecordOnly() ?
              <Link className="btn btn-primary" to={`/in/${getInviteFizzUUID()}/record/${this.state.fizzId}?recordType=${this.state.recordType}`} >
                {Branding.getViewInviteRecordBtnText()}
              </Link>
              :
              <AddButtonGroup
                onFileUploadChange={this.onFileUploadChange.bind(this)}
                recordPath={ `/in/${getInviteFizzUUID()}/record/${this.state.fizzId}?recordType=${this.state.recordType}` }
              /> 
            
            }
          </div>
          
        </div>
      </>)

  }

  render() {

    return (
      <div className="screen-wrap" >
        <NavLogo />
        <BannerBar />
        <MainContent className="container pb-5 pt-3">


          <h2 className="headline d-flex flex-reverse-column flex-md-row align-items-center text-end my-4">You're Invited!</h2>

          <div className="row mt-3 mb-5" >
            {this.renderContent()}
          </div>


          <HowItWorksBanner className="mt-4" isInvite={true} />
        </MainContent>

      </div>
    );
  }
}
