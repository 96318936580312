import React from "react";
import Branding from "../brands/Branding";

const NavLogo = (props) => {

  return Branding.getHeader({ logo: "/org/" + window.orgUUID + "/logo-small" });

}

export default NavLogo;
