import React, { Component } from "react";
import { apiFetch, saveSession } from "../helpers/APIHelper";
import VideoPlayer from "../components/VideoPlayer";
import { Link } from "react-router-dom";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import MainContent from "../components/MainContent";
import { getFizzUUID } from "../helpers/URLHelper";
import Branding from "../brands/Branding";
import Ferrets from "../assets/img/ferret.jpg";
import ShareOptions from "../components/ShareOptions";
import SaveProgressModal from "../modal/SaveProgressModal";



export default class QRThankyou extends Component {
  constructor(props) {
    super(props);


    this.state = {
      fizzId: props.match.params.fizzId,
      showSaveProgessModal: false,
    };
  }

  componentDidMount() {
    this.getData();
  }


  getData = () => {

    this.setState({
      isLoading: true
    });
    console.log("setting data...");

    apiFetch(`/rest/fizz/${this.state.fizzId}/qr-thank-you`, {
      method: 'GET',
    }, (json) => {
      console.log(json);

      this.setState({
        isLoading: false,
        shareUrl: json.shareUrl,
        fizzId: json.fizzId,
        showGroupShare: json.showGroupShare,
        showSaveProgessModal: !json.hasContactInfo
      });

      if( json.sessionId ){
        saveSession(json);
      }
    

    }, (error) => {
      this.setState({
        isLoading: false,
        error: error+""
      })
    });

  };


  handleChange = (event) => {

    var value = event.target.value;
    if( event.target.type=='checkbox' ){
      value = event.target.checked;
    }
    this.setState({
      [event.target.name]: event.target.value,
      errors:{}
    });

    console.log("Handle Change", event.target.name, value );
  };



  render() {

    if( this.state.isLoading ){
      return(
        <div className="screen-wrap">
          <NavLogo/>
          <MainContent className="container bg-margin">
            <Spinner float={true}/>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    return (
      <div className="screen-wrap">
        <NavLogo />
        <MainContent className="container bg-margin">
            <h1 className="text-center headline-simple size-60 mt-4">Thank <b>You.</b></h1>
            <div className="row">
                <div className="col-md-6 offset-md-3 text-center mt-4">
                    <p>Your card is complete and ready to post! Your video will play whenever the QR is scanned. You can also download a copy of your own below or send out the completed video to everyone who participated!</p>
                </div>
            </div>

          <div className="row thank-you-panel">
            <div className="col-md-6 d-none d-md-block qr-ferrets">
                <img src={Ferrets} className="w-100" />
            </div>
            
            <div className="col-md-6 qr-share-group">
                <ShareOptions shareUrl={this.state.shareUrl} fizzId={this.state.fizzId} showGroupShare={this.state.showGroupShare} /> 
            </div>

            <div className="d-md-none">
              <img src={Ferrets} className="w-100" />
            </div>
          </div>

          {/* { Branding.getFizzShareOptions({ shareUrl:this.state.shareUrl, fizzId: this.state.fizzId, showGroupShare: this.state.showGroupShare }) } */}


          {/* save progress modal */}
          <SaveProgressModal 
            show={this.state.showSaveProgessModal}
            onHide={()=>{ this.setState({showSaveProgessModal:false})}}
            fizzId={this.state.fizzId}
            from={'qr-thankyou'}
          />

        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}
