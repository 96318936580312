import ProgressUploader from "./ProgressUploader";
import { getInviteFizzUUID } from "../helpers/URLHelper";

export default class UploadManager {

    constructor( fizzId, type, callBacks ) {
    	console.log("Create UploadManager",fizzId,type);
        this.fizzId = fizzId;
        this.type = type;
    	this.callBacks = callBacks || {}

        this.uploadingList = [];

		this.hasError = false;
    }

    fireUpdate(){
    	if( this.callBacks.onProgress ) {
	        this.callBacks.onProgress( this.uploadingList );
    	}
    }

    startUpload = (files)=>{


		console.log("Start Upload of ("+files.length+") files. Current uploadingList.length:"+this.uploadingList.length)
	    for (var i = 0; i < files.length; i++) {
			let file = files[i];

			let uploader = new ProgressUploader(file, (uploader) => {
				// Progress
				console.log(uploader.getPercent());
				// Call set state to refresh
				this.fireUpdate();
			});
			uploader.key = Math.random();
			uploader.loadImage();

			this.uploadingList.push(uploader);
	    }
	    this.fireUpdate();

	    console.log(" fireUpdate uploadingList.length:"+this.uploadingList.length );
			if( this.uploadingList.length>0 ) {
				this.startNext();
			}
    }


	startNext = ()=>{
		console.log("startNext")
		for (var i = 0; i < this.uploadingList.length; i++) {

			var uploader = this.uploadingList[i];
			if (uploader.status == "idle") {
				// Start one that is idle
				var fizzUUID = getInviteFizzUUID();
				var started = uploader.start("POST", `/rest/fizz/${this.fizzId}/add-clip?type=${this.type}&size=${uploader.fileSizeMB}&isVideo=${uploader.isVideoParam}`+(fizzUUID?"?&uuid="+fizzUUID:''));
				started
					.then(()=>{
						this.oneDone(uploader);
					})
					.catch(()=>{
						this.oneDone(uploader);
					});
				break;
			}
		}
	}

	oneDone = (uploader) => {

		console.log("One DONE", uploader)
		console.log("Upload list: ", this.uploadingList)

		if( this.callBacks.onOneDone ){
			var shoudlRemoveThis = this.callBacks.onOneDone( uploader );
			if( shoudlRemoveThis ) {
				console.log("SHOULD REMOVE", uploader, this.uploadingList, );
	            this.remove(uploader);
			}
			else {
				this.hasError = true
			}
		}

		var allDone = true;
		for (var i = 0; i < this.uploadingList.length; i++) {
		  if ( !this.uploadingList[i].isDone() ) {
		    allDone = false;
		    break;
		  }
		}

	    this.fireUpdate();

		if( !allDone ) {
		  this.startNext();
		} else {
		  this.allDone();
		}
	}

	allDone = ()=>{

		console.log("all done manager: ", this.uploadingList)

		if( !this.hasError ) {
			this.uploadingList = [];
	    	this.fireUpdate();
		}
		if( this.callBacks.onAllDone ){
			this.callBacks.onAllDone(this.hasError);
		}

	}

	remove = (uploader)=>{

		console.log("Remove uploader:"+uploader.key, "count:"+this.uploadingList.length );

		for (var j = 0; j < this.uploadingList.length; j++) {
			let upload = this.uploadingList[j]
			if ( uploader === upload || uploader.key==upload.key){
				this.uploadingList.splice( j, 1 );
				console.log("  Removed at index:"+j, "count:"+this.uploadingList.length);

				this.fireUpdate()
			}
        }

	}


}
