import React, {useState} from "react";
import StandardModal from "../modal/StandardModal";
import MusicPicker from "./MusicPicker";

export function MusicPickerModal({
  showMusicPicker,
  music,
  onHide,
  onSave
}) {

    const [preSelectedMusic, setSelectedMusic] = useState(null);

    const onPreSelectMusic = (picked) => {
        setSelectedMusic(picked)
    }

    const onConfirm = () => {
        onSave(preSelectedMusic)
        onHide()
    }


  return (
        <div className="music-modal" >
            <StandardModal 
                size="full" 
                show={showMusicPicker} 
                onConfirm={() => onConfirm()} 
                onHide={() => onHide()} 
                confirmBtnText="Save"
            >
                <MusicPicker 
                    onSelectMusic={music => onPreSelectMusic(music)} 
                    music={music} 
                />
            </StandardModal>
        </div>
    );
}
  