import React, { Component } from "react";
import { apiFetch } from "../helpers/APIHelper";
import { getBaseURL, getQRKey } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import MainContent from "../components/MainContent";
import { saveSession } from "../helpers/APIHelper";
import Branding from "../brands/Branding";
import NavLogo from "../components/NavLogo";
import AutoPlayVideo from "../components/AutoPlayVideo";
import FooterBar from "../components/FooterBar";


export default class QRScan extends Component {


  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this._getData();
  }

  _getData = () => {
    this.setState({
      isLoading: true
    })

    apiFetch(`/rest/qr/${getQRKey()}`, {
      method: 'GET',
    }, (json) => {
      console.log(json);

      if( json.sessionId ){
        saveSession( json );
      }

      if( json.editFizzId ){
        this.props.history.push( getBaseURL( '/edit/' + json.editFizzId ) );
        return;
      }

  
      this.setState({
        isLoading: false,
        imgTall: json.imgTall,
        isQRCardOwner: json.isQRCardOwner,
        hasOwner: json.hasOwner,
        previewVideo: json.previewVideo,
        createThemeAnimationId: json.createThemeAnimationId,
        howToVideo: json.howToVideo,
        fizzImage: json.fizzImage
    });
      
    }, (error) => {
      this.setState({
        isLoading: false,
        error: error+""
      })
    });
  }


  toggleShowHowToVideo = () => {
    this.setState({
        showHowToVideo: !this.state.showHowToVideo
    });
  }


  render() {
    return(
      <div className="screen-wrap" >
        <NavLogo />
        <MainContent className="container bg-margin">
          { this.renderContent() }
        </MainContent>
      </div>
    )
  }

  renderContent() {


    if( this.state.isLoading ){
      return (
        <Spinner float={true}/>
      );
    }


    if( this.state.error ){
      return (
        <p className="alert alert-danger">{this.state.error}</p>
      );
    }


    return (
      <>
        <h1 className="headline-simple text-center my-3">
              Let’s get <b>started</b>
        </h1>
        <div className="row align-items-center" >
          <div className="col-lg-10 offset-lg-1" >
            <p className="mb-5 text-center">Welcome, bring your card to life with this matching animation. Tap below to begin customising and make this gift extra special!</p>
            <AutoPlayVideo video={this.state.previewVideo} poster={this.state.fizzImage} />
          </div>
        </div>
        <FooterBar>
          <Link 
            to={this.state.fizzId ? getBaseURL(`/edit/${this.state.fizzId}`) : getBaseURL(`/create/${this.state.createThemeAnimationId}` )} 
            className="btn btn-primary btn-block btn-lg-inline-block">
            { Branding.getSetupBtnText() }
          </Link>
        </FooterBar>
      </>

    );
  }
}
