"use client"
import React, { useState, useEffect } from "react";
import '../assets/css/or-line.css'

export default function OrLine(props) {


    return (
        <p className="or-line"><span>or</span></p>
    )
}
