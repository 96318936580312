export const GET_FEATURES = (() => {
    var features = {
        allowDownload: ALLOW_DOWNLOAD(),
        allowUpload: ALLOW_UPLOAD(),
        allowRecord: ALLOW_RECORD(),
        embeddedApp: GET_EMBEDDED_APP(),
        os: getMobileOperatingSystem(),
        isEmbedded: IS_EMBEDDED_APP()
    };

    return features;
})()

export const ALLOW_PHONE_SIGNUP = (()=>{
    

    var host = process.env.API_HOST
    if( !host && typeof window !== 'undefined') {
        host = window.location.origin
    }
    
    var isAllowed = false;
    switch( host ) {
        case 'https://air-dev7.videofizz.com':
        // case 'https://dev.partyinacard.com':
        // case 'http://localhost:3002':
            isAllowed = true;
            break;
        default:
            isAllowed = false;
            break;
    }
    return false; // just hide all for now. --- jdh
    // return isAllowed;
})()

function IS_EMBEDDED_APP() {
        if( typeof(navigator)==='undefined' ) {
          return false;
        }
        var userAgent = navigator.userAgent;
        
        if (
            userAgent.match(/FB(4A;|AV|AN|IOS;)/) || // facebook 
            userAgent.includes("Instagram") || // instagram
            userAgent.includes("BytedanceWebview") || // tiktok
            userAgent.includes("[LinkedInApp]") || // linkedin
            userAgent.includes("GSA") // google search app
        ) {
            return true;
        }
        return false;
    }

function GET_EMBEDDED_APP() {
    if( typeof(navigator)==='undefined' ) {
        return false;
    }
    var userAgent = navigator.userAgent;

    if (userAgent.match(/FB(4A;|AV|AN|IOS;)/)) {
        return 'Facebook';
    }
    else if(userAgent.includes("Instagram")) {
        return "Instagram"
    } 
    else if(userAgent.includes("BytedanceWebview")) {
        return "TikTok";
    }
    else if(userAgent.includes("[LinkedInApp]")) {
        return "LinkedIn";
    }
    else if (userAgent.includes("GSA")) {
        return "Google"
    }
    return null;
}

function ALLOW_DOWNLOAD() {
    if (IS_EMBEDDED_APP() &&
        getMobileOperatingSystem() == "iOS"
        ) {
        return false;
    }

    return true;
}

function ALLOW_UPLOAD(){
    if (IS_EMBEDDED_APP() &&
        getMobileOperatingSystem() == "Android"
        ) {
        return false;
    }

    return true;
}

function ALLOW_RECORD() {
    if (IS_EMBEDDED_APP() &&
        getMobileOperatingSystem() == "Android"
        ) {
        return false;
    }

    return true;
}


/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 * From https://stackoverflow.com/questions/21741841/detecting-ios-android-operating-system
 * 
 * @returns {String}
 */
function getMobileOperatingSystem() {
    if( typeof(navigator)==='undefined' ) {
        return false;
    }
    if( typeof(window)==='undefined' ) {
        return false;
    }
    var userAgent = navigator.userAgent || window.opera;

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
}

function getNavigator() {
    if( typeof(navigator)==='undefined' ) {
        return null;
    }
    return navigator;
}