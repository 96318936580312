import { useEffect, useRef, useState } from "react";

export default (props) => {

    const [pulseClass,setPulseClass] = useState('')

    const onClick = () =>  {
        props.onClick() 
        setPulseClass('');
        setTimeout( ()=>{
            setPulseClass('pulse');
        },100)
        
    }

    return (
        <button
            tabIndex="0"
            onClick={ onClick } 
            className={`${ props.className || "" } ${pulseClass}`}
        >
            { props.children }
        </button>
    )
}