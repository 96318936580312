import React, {useState, useEffect } from "react";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import { apiFetchAsync, apiFetch } from "../helpers/APIHelper";
import OrLine from "../components/OrLine";
import BannerBar from "../components/BannerBar";
import MainContent from "../components/MainContent";
import Spinner from "../components/Spinner";
import Branding from "../brands/Branding";
import {validateFields } from "../helpers/Validation";
import { getInviteFizzUUID } from "../helpers/URLHelper";
import { allowOptIn } from "../helpers/DomHelper";

import { ALLOW_PHONE_SIGNUP } from "../helpers/Config";

export default function ViewInviteThankYou(props) {

  const [hasContactInfo, setHasContactInfo] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [errors,setErrors] = useState([]);
  const [pageError, setPageError] = useState(null);
  const [marketingOptIn, setMarketingOptIn] = useState(true);

  const _getData = () => {
 
    apiFetch(`/rest/invite/${getInviteFizzUUID()}/thank-you`, {
      method: 'GET',
      }, (json) => {
    
        setHasContactInfo(json.hasContactInfo);
        setIsLoading(false);
    
      }, (error) => {
        setIsLoading(false);
        setPageError(error);
      });

  };

  useEffect(() => {
    _getData();
  },[]);


  const renderThankYou = ()=>{

    return (<>
      <h2 className="headline">Thank you!</h2>
      <h5 className="subheadline text-center mb-4">{Branding.getViewInviteThankYouMessage()}</h5>
      { Branding.getViewThankYouBottom() }
    </>)
  }


  const renderGetContactInfo = ()=>{

    const validateForm = (form)=>{
      var email = form.email.value.trim();
      var phone = form.phone ? form.phone.value.trim() : '';
      if( !email && !phone ) {
        return [Branding.getViewInviteEmptyFormError()];
      }  
      return validateFields([
          {value:form.email.value, type:'email'},
          {value:phone, type:'phone'},
      ]);
    }
    const onSubmit = async (e)=>{
      e.preventDefault();
      let form = e.target
      let errors = validateForm(form)
      setErrors( errors||[])

      if( errors.length<=0 ) {
        //setSending(true)

        var data = await apiFetchAsync('/rest/actor-info/'+ getInviteFizzUUID(),{
          method:"PUT",
          data:{
            email: form.email.value,
            phone:form.phone ? form.phone.value.trim() : '',
            optIn: marketingOptIn 
          }
        })

        setHasContactInfo(true)

      }
    }
    const onInputChange = (e)=>{
      setErrors([])
    }

    const onOptInChange = (e)=>{    
      setMarketingOptIn( !marketingOptIn );
      setErrors([])
  }


    return (<>
      <h2 className="headline">Last Step</h2>
      <h3 className="subheadline text-center mb-5">{Branding.getViewInviteInfoRequest()}</h3>
      <div className="row">
        <div className="col-md-6 offset-md-3 text-center">

            <form onSubmit={onSubmit}>

                <div className="form-floating mt-0 mb-1">
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      placeholder={"user@mail.com"}
                      onChange={onInputChange}
                    />
                    <label>Email</label>
                </div>

                { ALLOW_PHONE_SIGNUP &&
                  <>
                    <OrLine/>

                    <div className="form-floating mt-0 mb-1">
                        <input
                          name="phone"
                          type="phone"
                          className="form-control"
                          placeholder={"123-456-7890"}
                          onChange={onInputChange}
                        />
                        <label>Phone Number</label>
                    </div>
                  </>
                }
                
                {errors.map( (error)=>{
                  return (
                      <p className="alert alert-danger" >{error}</p>
                    )
                })}

                { isLoading ?
                    <Spinner />
                :
                    <div className="text-center mt-4">
                        <input type="submit" disabled={isLoading} className="btn btn-primary btn-block mb-1" value="Message me when it is done" />

                        { allowOptIn() && 
                          <>
                            <div className="form-check d-inline-block">
                              <label className="form-check-label" >
                                  <input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={onOptInChange}
                                      checked={marketingOptIn}
                                  />
                                  Keep me in the loop!
                                </label>
                            </div>

                            { ALLOW_PHONE_SIGNUP &&
                                <p className={"mt-1 "+(marketingOptIn?"opacity-75":"opacity-0") } >
                                    <small>
                                        We will send you offers and product info.
                                        Unsubscribe at any time by replying STOP or use the unsubscribe link.
                                    </small>
                                </p>
                            }
                          </>
                        }
                    </div>
                }

            </form>
        </div>
      </div>
    </>)
  }

  
  if( isLoading ){
    return (
      <div className="screen-wrap" >
        <NavLogo />
        <BannerBar />

        <MainContent className="container pb-5 pt-3 h-100">
          <Spinner float={true} />
        </MainContent>

        <div className="simple-footer">
          <Footer hideMobile={false} />
        </div>
      </div>
    )
  }


  if (pageError) {
    return (
      <div className="screen-wrap" >
        <NavLogo />
        <BannerBar />
        <MainContent className="container pb-5 pt-3 h-100">
          <p className="alert alert-danger">{pageError}</p>
        </MainContent>

        <div className="simple-footer">
          <Footer hideMobile={false} />
        </div>
      </div>
    )
  }
  
  return (
    <div className="screen-wrap" >
      <NavLogo />
      <BannerBar />
      <MainContent className="container pb-5 pt-3 h-100">

        { !hasContactInfo ? (

          renderGetContactInfo()
          
          ) : (
          
          renderThankYou()

        ) }

      </MainContent>

      <div className="simple-footer">
        <Footer hideMobile={false} />
      </div>
    </div>
  );
}
