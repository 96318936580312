"use client"
import React, { Component } from 'react';
import MailIcon from "../assets/images/vf-icons/email.svg"; 
import ShareIcon from "../assets/images/vf-icons/share.svg";
import CopyIcon from "../assets/images/vf-icons/copy.svg";
import DownloadIcon from "../assets/images/vf-icons/download.svg";
import GroupShare from "../assets/images/vf-icons/group-share.svg";
import TextIcon from "../assets/images/vf-icons/text.svg";
import { getMailToLink, getSMSLink, isMobile, canShare, share } from "../helpers/URLHelper";
import PulseButton from "../components/PulseButton"
import { Spinner } from 'react-bootstrap';
import { apiFetch } from '../helpers/APIHelper';
import { toastManager } from '../components/Toaster';
import ThemedIcon from '../components/ThemedIcon';
import ShareModal from '../modal/ShareModal';

 export default class ShareOptions extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGroupConfirm:false,
			showGroupShare: props.showGroupShare || false,
		}
	}

  

	copyToClipboard=()=>{
		navigator.clipboard.writeText(this.props.shareUrl);    
		toastManager.showToast({
			message: 'Copied to Clipboard!'
   		})
	}

	shareAny=()=>{
		navigator.share({
		 	url: this.props.shareUrl
		}).then(() => {
		  // alert("Sent");
		}).catch((e)=>{
		  // Canceled or something else
		  // alert("Error"+e);
		})
	}

	onHide=() => {
		this.setState({
			showGroupConfirm: false
		})
	}

	toggleGroupShare = () =>{
		this.setState({
			showGroupConfirm: !this.state.showGroupConfirm
		})
	}


	renderGroupConfirm = () => {


		return (
			<ShareModal
            	show={this.state.showGroupConfirm}
				onHide={this.onHide}
				shareUrl={this.props.shareUrl}
				fizzId={this.props.fizzId}
				showOnlyConfirm={true}
			/>
		);
	}

	renderShareButtons = () => {
		
		var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;

		var generalURL = this.props.shareUrl;

		var downloadUrl = generalURL+"/download";

		var shareUrl = generalURL;
		
	    let smsLink = getSMSLink({body:shareUrl});
	    let emailLink = getMailToLink({body:shareUrl});
		
		return(
			<div className='ps-4'>		

				{ this.state.showGroupShare &&
					<div>
						<button className="btn btn-link btn-share" onClick={this.toggleGroupShare}>
							<ThemedIcon 
								className=" me-3"
								icon={GroupShare}
								style={{backgroundColor: `var(--secondary)`}}
							/>
							Share with Participants
						</button>
					</div>
				}

				{ canShare() ? (

					<button className="btn btn-link btn-share" onClick={this.shareAny}>
						<ThemedIcon 
							className="me-3"
							icon={ShareIcon}
							style={{backgroundColor: `var(--secondary)` }}
						/>
						Share this video
					</button>
				) : (
					<>

						<a href={emailLink} className="btn btn-link btn-share" target="_blank">
							<ThemedIcon
								className="me-3"
								icon={MailIcon}
								style={{backgroundColor: `var(--secondary)` }}
							/>
							Email
						</a>

						{ smsLink && 

							<div>
								<a className="btn btn-link btn-share" href={smsLink}>
									<ThemedIcon 	
										className="me-3"
										icon={TextIcon} 
										style={{backgroundColor: `var(--secondary)` }}
									/>
									Text message
								</a>
							</div>
						}
					
						{ hasClipboard &&
							<div>
								<PulseButton className="btn btn-link btn-share" onClick={this.copyToClipboard} >
									<ThemedIcon
										className="me-3"
										icon={CopyIcon} 
										style={{backgroundColor: `var(--secondary)` }}
									/>
									Copy Link & Paste Anywhere
								</PulseButton>
							</div>
						}

					</>
				)}

				<div>
					<a href={downloadUrl} download="Video.mp4" className="btn btn-link btn-share tag-download-user">
						<ThemedIcon
							className="me-3" 
							icon={DownloadIcon} 
							style={{backgroundColor: `var(--secondary)` }}
						/>
						Download a copy to keep
					</a>
				</div> 
			</div>
		);
	}

	render() {

		return (
			<div>
				{ 
				!this.state.showGroupConfirm ? 
					this.renderShareButtons() : 
					this.renderGroupConfirm() 
				}
			</div>
		);
	}
 }