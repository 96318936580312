import React, { Component } from 'react';
import IconPlay from "../assets/images/vf-icons/play.svg";
import IconPlayOutline from "../assets/images/vf-icons/play-outline-blk.svg";
import IconPause from "../assets/images/vf-icons/pause.svg";
import IconCheck from "../assets/images/vf-icons/check.svg";
import Equalizer from '../components/Equalizer';
import ThemedIcon from '../components/ThemedIcon';

import "../assets/css/music-picker.css";


export const getSelectedMusic = ( musicPickerObj ) => {
	if( musicPickerObj && musicPickerObj.options ) {
		for (var i = 0; i < musicPickerObj.options.length; i++) {
			let music = musicPickerObj.options[i];
			if( music.id === musicPickerObj.defaultMusicId ||  music.id === musicPickerObj.defaultId ) {
				return music;
			}
		}
	}
	return null;
}


export default class MusicPicker extends Component {
	constructor(props) {
		super(props);
		
		this.firstLoad = true;
		this.state = {
			isPlaying: false,
			filterBy: 'All',
			tracks: ['All'],
			trackCategories: [],
		}
		this.loadMusic(props);
		this.firstLoad = false;
	}

	componentWillReceiveProps(nextProps){
		if( this.props.music != nextProps.music ) {
			this.loadMusic(nextProps);
		}
	}

	componentDidMount() {
		this.audioRef = React.createRef();
	}

	loadMusic( props ) {

		var old = this.state.selectedMusic;

		this.state.selectedMusic = getSelectedMusic(props.music);
		this.state.defaultMusic = this.state.selectedMusic;
		this.state.tracks = !!props.music && props.music.options;

		if( props.music && props.music.options ) {
			this.state.trackCategories = this.getTrackCategories(props.music.options); 
		}

		if( this.firstLoad ){
			this.state.filterBy = this.getCategoryFromTrack(this.state.defaultMusic);

		}

		if( !old ) {
			if (this.props.onSelectMusic) {
				this.props.onSelectMusic( this.state.selectedMusic );
			}
		}

	}


	getCategoryFromTrack = ( track ) => {

		if( !track ){
			return null;
		}
		var name = track.name;
		var parts = name.split(" - ");
		if( parts.length>1 ) {
			var trackCats = parts[0].split(',');
			for (var j =0; j<trackCats.length && j<1; j++) {
				var catName = trackCats[j].trim();
				return catName;
			}
		}
		return null;
	}
	getTrackCategories = ( tracks ) => {

		var categoriesHash = {};
		for (var i =0; i<tracks.length; i++) {
			var catName = this.getCategoryFromTrack( tracks[i] );
			if( catName ) {
				categoriesHash[catName] = catName;
			}
		}
		var categories = Object.keys(categoriesHash).sort();


		categories.unshift("All");
		
		return categories
	}

	selectTrack = (music) => { 

		console.log( "selectTrack",(music||{}).name);

		if(this.state.selectedMusic !== music ) {
			this.setState({
				selectedMusic: music
			});

			if( this.audioRef && this.audioRef.current ) {
				try{
					// Stop the old
					this.audioRef.current.pause();
				}catch(e){ }

				try{
					// Load and play the new
					this.audioRef.current.load();
					this.audioRef.current.play();
				}catch(e){ }
			}
		} else {
			// They clicked the same track. Toggle the playback
			this.togglePlay()
		}


		if (this.props.onSelectMusic) {
			this.props.onSelectMusic( music );
		}

	}

	togglePlay = () => {
		try{

			if( this.audioRef && this.audioRef.current ) {
				if (this.state.isPlaying) {
					this.audioRef.current.pause();
					console.log("Paused: ", this.audioRef.current.paused);
					
				} else {
					this.audioRef.current.play();
					console.log("Paused: ", this.audioRef.current.paused);
				}
			}
		}catch(e){ }
	}

	handlePauseEvent = () => {
		this.setState({
			isPlaying: false
		});
	}

	handlePlayEvent = () => {
		this.setState({
			isPlaying: true
		});
	}

	handleCategoryFilter = (e) => {
		e.preventDefault();
		const target = e.target;
		
		const filterBy = target.textContent;
		
		this.setState({
			filterBy: filterBy
		});
	}

	render() {

		var styles = {
			musicModalScroll: {
				maxHeight: '400px',
				overflowY: 'scroll',
				marginTop: '16px'
			}
		};

		if( this.props.music.options ) {

			var hasNoMusic = false

			var defaultCatName = this.getCategoryFromTrack(this.state.defaultMusic);

			var filteredTracks = [];

			for (var i =0; i<this.props.music.options.length; i++) {
				var track = this.props.music.options[i];



				if( this.state.filterBy=='All' || !this.state.filterBy) {
					filteredTracks.push(track);
				} else if( !track.url && !hasNoMusic && track.name=='No Music') {
					// Allways show no music
					hasNoMusic = true
					filteredTracks.push( track );
				} else {
					var name = track.name;
					var parts = name.split(" - ");
					if( parts.length>1 ) {
						var trackCats = parts[0].split(',');
						for (var j =0; j<trackCats.length && j<1; j++) {
							var catName = trackCats[j].trim();
							if (catName == this.state.filterBy) {
								filteredTracks.push(track)
							}
						}
					}
				}
			}



			var sortedList = [];
			for( var i=0; i<filteredTracks.length; ++i ) {
				var track = filteredTracks[i];
				if( !track.url ) {
					// NO music shows at the top
					sortedList.push( track );
				}
			}
			for( var i=0; i<filteredTracks.length; ++i ) {
				var track = filteredTracks[i];
				if( track.url && track==this.state.defaultMusic ) {
					// Default Cat Shows at the top
					sortedList.push( track );
				}
			}
			for( var i=0; i<filteredTracks.length; ++i ) {
				var track = filteredTracks[i];
				if( !track.url || track==this.state.defaultMusic ) {
					continue;
				}
				if( defaultCatName && track.name.indexOf(defaultCatName)>=0 ) {
					// Default Cat Shows at the top
					sortedList.push( track );
				}
			}
			for( var i=0; i<filteredTracks.length; ++i ) {
				var track = filteredTracks[i];
				if( !track.url || track==this.state.defaultMusic ) {
					continue;
				}
				if( defaultCatName && track.name.indexOf(defaultCatName)>=0 ) {
					continue;
				}

				if( track.url && track.name.indexOf(defaultCatName)<0 ) {
					// Push what was not before
					sortedList.push( track );
				}
			}

			return (
				<div className='music-picker'>
						
						<div className='border'>
							<div className="px-3 py-2 d-flex flex-column align-items-center w-100 mr-n4">
								<small>
									<i>Your Current Track</i>
								</small>
								<div className="d-flex justify-content-between align-items-center w-100">
									{ this.state.selectedMusic.url &&
										<ThemedIcon 
											icon={this.state.isPlaying?IconPause:IconPlay} 
											onClick={this.togglePlay} />
									}
									<h3 className="picked-track-name"><em>{this.state.selectedMusic.name}</em></h3>
									{ this.state.selectedMusic.url &&
										<div className="equalizer-wrapper">
											<Equalizer play={this.state.isPlaying} />
										</div>
									}
								</div>

								<audio autoPlay={true} ref={this.audioRef} onPause={this.handlePauseEvent} onPlay={this.handlePlayEvent}>
									<source src={this.state.selectedMusic.url} type="audio/mpeg" />
								</audio>
							</div>
						</div>

						<div className='border'>
							<div className="p-1 d-flex align-items-center flex-wrap">
								{this.state.trackCategories.map(category => (
									<i 
										className={`track-category ${category==this.state.filterBy ? "active" : ""}`} 
										key={category} 
										onClick={this.handleCategoryFilter}
									>
										{category}
									</i>
								))}
							</div>
						</div>

						<div className='music-picker-list border'>
							<ul className="list-group">

								{sortedList.map((music) =>{
								
									var isNoMusic = !music.url;

									if( music.id==-2) {
										return null;
									}
									return(

										<li 
											key={music.id}
											className="list-group-item music-track" 
											onClick={ this.selectTrack.bind(this, music) } 
										>	
											{this.state.selectedMusic == music ? 

												<ThemedIcon 
													icon={this.state.isPlaying?IconPause:IconPlay} 
													onClick={this.togglePlay}
													style={{
														visibility:(isNoMusic?'hidden':''),
													}} />
												:
												<img src={IconPlayOutline} width="18px" height="21px" style={{ visibility:(isNoMusic?'hidden':'')  }} />
											}
											<span className={`track-name${this.state.selectedMusic == music ? " font-weight-bold mr-2" : ""}`}>
												{music.name}
											</span>
											{this.state.selectedMusic == music && 
												<ThemedIcon 
													icon={IconCheck} 
													className="primary" 
													/>
											}
										</li>
									)
								})}   

							</ul>
						</div>

				</div>

				);
		}
		return null;

	}
}