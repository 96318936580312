import React from 'react';
import Brand from './Brand';
import { getBaseURL } from "../helpers/URLHelper";
import { Link } from "react-router-dom";
import Setup from "../assets/branding/thebump/bump-setup.jpg";
import Step1 from "../assets/branding/thebump/bump-invite.jpg";
import Step2 from "../assets/branding/thebump/bump-record.jpg";
import Step3 from "../assets/branding/thebump/bump-share.jpg";

export default class TheBump extends Brand {
	
	getSetupImg = () => { 
		return (
			<img className="w-100 card" src={Setup} />
		);
	}
	getSetupText = ()=>{
		return "Invite friends and family to guess if you are having a boy or girl. We’ll collect the guesses and create a video for you to share when you are ready. Download and save the special memory for your baby.";
	}
	getSetupBtnText = ()=>{
		return "Make My Own";
	}


	//
	//	INVITE PAGE
	//
	getInviteHeadline = ()=>{
		return 'Start Inviting!';
	}	
	getInviteBody = ()=>{
		return (
			<>
				Send this invite or tap inside the box to customize the message.
				You can invite as many people as you like. 
				<b> We’ll let you know&nbsp;when&nbsp;new&nbsp;guesses&nbsp;come&nbsp;in!</b>
			</>
		);
	}	
	getInviteSample = ( json )=>{
		return 'Hi! We are creating a special memory for our little one. Tap the link to record a quick video letting us know if you think we’re having a BOY or a GIRL. You’ll be among the first to know when we share the happy news.';
	}	
	getInviteOthersBtnText = ()=>{
		return "Invite Others";
	}	

	//
	//	View Invite
	//
	getViewInviteImg=()=>{
		return this.getSetupImg();
	}
	getViewInviteBody=()=>{
		return "What do you think the new baby will be? Your guess will be added with others and you’ll be the first to know when the gender is revealed.";
	}
	getViewInviteThankYouMessage = ()=>{
		return "Your video was sent.";
	}
	isViewInviteRecordOnly = ()=>{
		return true;
	}
	getViewInviteRecordBtnText=()=>{
		return "Record your Guess";
	}
	getViewInviteEmptyFormError=()=>{
		return "Please enter an email or a phone number or you won't receive the gender reveal video when it's ready.";
	}
	getViewInviteInfoRequest=()=>{
		return (<>
			Be among the first to know when the gender is revealed.<br/>The final video will be sent to you by email or text.
		</>);
	}
	

	getRecordType = ()=>{
		return 'baby';
	}

	
	getHowItWorksVideo = ()=>{
		return false;
	}

	getStepOne = ( {isInvite,themeSlug} ) => {
		if( isInvite ){
			return this.getStep({
				img: Step1,
				title: "Record your Guess",
				msg: "Guess if the new baby is a boy or girl. Use the  filters provided to make it more fun! It’s easy!",
			});
		}
		return this.getStep({
			img: Step1,
			title: "Invite Others to Guess",
			msg: "We’ll give you a link to invite friends and family to guess if the new baby is a boy or girl. It’s easy and fun!",
		});
	}
	getStepTwo = ( {isInvite,themeSlug} ) => {	
		if( isInvite ){
			return this.getStep({
				img: Step2,
				title: "The Reveal is Recorded",
				msg: "The happy couple will record their reveal and add it to the project.",
			});
		}
		return this.getStep({
			img: Step2,
			title: "Record the Reveal",
			msg: "Upload your reveal or pictures of your new baby and we’ll add it to your project.",
		});
	}

	getStepThree = ( {isInvite,themeSlug} ) => {
		if( isInvite ){
			return this.getStep({
				img: Step3,
				title: "Be the first to know!",
				msg: "The completed video will be sent to you showing all guesses and the grand reveal!",
			});
		}
		
		return this.getStep({
			img: Step3,
			title: "Share it with the world!",
			msg: "When you’re ready, share the completed video with those who participated. Download a copy as a memory for your little one.",
		});

	}


} 
