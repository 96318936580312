import { apiFetch } from "./APIHelper";
import moment from 'moment';
import LocalStorageHelper from "./LocalStorageHelper";
import MemCache from "./MemCache";
import SessionStorageHelper from "./SessionStorageHelper";

const viewStorageHelper = LocalStorageHelper( SessionStorageHelper( MemCache ) );


var sendFBQ = null;
const getFBQ = ()=>{
    if( !sendFBQ && typeof(window.fbq)!=='undefined' ) {
        sendFBQ = window.fbq;
    }
    return sendFBQ;
}
export const overrideFBQ = (newFBQ)=>{
    sendFBQ = newFBQ;
}
// overrideFBQ( (track, eventName, datat )=>{
//     goodFun(  eventName, datat );
// })


const trackFBEvent = ( eventName, data )=>{
    
    try{
        const fbq = getFBQ();
        if( fbq ) {

            switch(eventName) {
                case 'checkout':
                    fbq('track', 'InitiateCheckout', data || {});
                    break;
                case 'fizz_created':
                    fbq('track', 'CustomizeProduct', data || {});
                    fbq('track', 'fizz_created', data || {});
                    break;
                case 'email':
                    fbq('track', 'Lead', data || {});
                    break;
                default:
                    fbq('trackCustom', eventName, data || {});
                    break;
            }
        }
    } catch( e ){
        console.log("FB tracking failed:",e);
    }
  
  }

  const trackTagManagerEvent = ( eventName, data, callback )=>{
    
    try{
        if( typeof(dataLayer)!=='undefined' ) {

            var data = data || {};
            data.event = eventName;
            if( callback ) {
                data.eventCallback = callback;
            }
            dataLayer.push(data);
            // console.log("dataLayer push:",data);
        } else {
            console.log("dataLayer not found");
        }
    } catch( e ){
        console.log("Tag Manager tracking failed:",e);
    }
  
  }
  
  export const trackEvent = ( eventName, data, callback )=>{
    console.log("Event:",eventName, data);
    trackFBEvent( eventName, data );
    trackTagManagerEvent( eventName, data );
  }

  if( typeof(window)!=='undefined' ) {
    window.vfTrackEvent = trackEvent;
}

export const trackOnce = (name, fizzId = null) => {

    apiFetch('/rest/tactivity/best/'+name+'?+fizzId=' + fizzId, {
      method: 'POST',
      data: { value: 1, },
    }, (json) => {
  
      //fire and forget
    
    }, (error) => {
    
      console.log(error);
    
    });
}

export const shouldTrackView = ( fizzUUID ) => {
    var lastViewedTime = viewStorageHelper.getItem( 'lastViewedTime' );
    var viewedFizzUUID = viewStorageHelper.getItem( 'viewedFizzUUID' );
    var duration = moment.duration( moment().diff( moment( lastViewedTime ) ) );
    var minutes = duration.asMinutes();
    
    // is this the same fizz?
    if( fizzUUID && fizzUUID == viewedFizzUUID ){
      
      if( minutes < 1 ){
        // refresh time occured during blackout time.
        return false;
      }
      
    }
    
    viewStorageHelper.setItem( 'viewedFizzUUID', fizzUUID );
    viewStorageHelper.setItem( 'lastViewedTime', moment().format() );
    
    return true;
}
  