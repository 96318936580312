
var EMAIL_REGEX = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);



export const validateFields = ( fields )=>{

  var errors = [];

  // if( fields.length == undefined ) {
  //   var data = new FormData(fields);
  //   fields = formData.entries(data);
  // }

  for( let i=0; i<fields.length; ++i ) {
    let field = fields[i];

    switch( field.type ) {
      case 'email':

        var error = validateEmail(field.value, field.required);
        if( error ){
          errors.push(error);
        }
        break;

      case 'phone':

        var error = validatePhone(field.value, field.required);
        if( error ){
          errors.push(error);
        }
        break;

      default:
        console.log("Type",field.type,field.name)
        // Just check required if nothing else
        if( field.required ) {
          if( !field.value ){
            errors.push(field.label+" is required.");
          }
        }
        break;
    }
  }

  console.log("validateFields", fields, errors)
  return errors;
}

export const validateEmail = (email, required) => {

    if( !email ) {
      if( required ) {
        return "Please enter an email address.";
      } else {
        // Empty is valid
        return false;
      }
    }

    if(!EMAIL_REGEX.test(email)) {
      return "Please enter valid email address."
    }

    return false;
} 
export const validatePhone = (phone, required) => {

    // Null or empty
    if( !phone || !phone.trim() ){

      if( required ) {
        return "Please enter a phone number.";
      } else {
        // Empty is valid
        return false;
      }
    }

    var cleaned = phone.replace(/[^0-9]/g, '');

    if (cleaned.length < 10 ) {
      // 555-555-1234
      return "Please enter a valid phone number.";
    } 

    if (cleaned.length == 10 ) {
      // 555-555-1234
      // This is good
      return false;
    } 
    if (cleaned.length == 11 ) {
      if( cleaned[0] == '1' ) {
        return false;
      } else {
        return "Only US phone numbers are allowed.";
      }
    } 
    // Too Long
    return "Please enter a valid phone number.";
} 