import React, { Component } from "react";
import { apiFetch } from "../helpers/APIHelper";
import { getFizzURLKey, getBaseURL, isMobile } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";
import MainContent from "../components/MainContent";
import Footer from "../components/Footer";
import { saveSession } from "../helpers/APIHelper";
import Branding from "../brands/Branding";
import HowItWorksBanner from "../components/HowItWorksBanner";
import NavLogo from "../components/NavLogo";
import AutoPlayVideo from "../components/AutoPlayVideo";
import FooterBar from "../components/FooterBar";


export default class PSetup extends Component {


  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this._getData();
  }

  _getData = () => {
    this.setState({
      isLoading: true
    })

    apiFetch(`/rest/p/${getFizzURLKey()}/start`, {
      method: 'GET',
    }, (json) => {
      console.log(json);

      if( json.sessionId ){
        saveSession( json );
      }

      // if( json.redirect ){
      //   window.location = getBaseURL( '/edit/' + json.editFizzId );
      //   return;
      // }

      this.setState({
          fizzId: json.fizzId,
          themeSlug: json.themeSlug,
          isLoading: false,
          showCreateFizz: json.showCreateFizz,
          video: json.video,
          img: json.img
      });
      
    }, (error) => {
      this.setState({
        isLoading: false,
        error: error+""
      })
    });
  }


  render() {
    return(
      <div className="screen-wrap" >
        <NavLogo />
        <MainContent className="container bg-margin">
          { this.renderContent() }
        </MainContent>
      </div>
    )
  }

  renderContent() {


    if( this.state.isLoading ){
      return (
        <Spinner float={true}/>
      );
    }


    if( this.state.error ){
      return (
        <p className="alert alert-danger">{this.state.error}</p>
      );
    }


    switch( Branding.getSetupPageType() ) {

      case 'customize-this':
        return this.renderCustomizeThis();
        break;

      case 'steps':
      default:
        return this.renderSteps();
        break;

    }
    return this.renderSteps();
  }


  renderCustomizeThis() {
    return (
      <>
        <h1 className="headline headline-big d-flex flex-column-reverse flex-md-row align-items-center text-end me-2 me-md-4">
              Let’s get started
        </h1>
        <div className="row align-items-center" >
          <div className="col-lg-10 offset-lg-1" >
            <p className="mb-5">Welcome, bring your card to life with this matching animation. Tap below to begin customising and make this gift extra special!</p>
            <AutoPlayVideo video={this.state.video} poster={this.state.img} />
          </div>
        </div>
        <FooterBar>
          <Link 
            to={this.state.showCreateFizz ? getBaseURL(`/personalize`) : getBaseURL(`/edit/${this.state.fizzId}`)} 
            className="btn btn-primary btn-block btn-lg-inline-block">
            { Branding.getSetupBtnText() }
          </Link>
        </FooterBar>
      </>

    );
  }

  renderSteps() {
    return (
      <>
        <div className="mt-md-5 mb-5">
          <div className="d-md-block">
            <h1 
              className="headline d-flex flex-column-reverse flex-md-row
              align-items-center text-end me-2 me-md-4"
            >
              Let’s get started
            </h1>
            <div className="row mt-2">
              <div className="col-lg-8">
                { Branding.getSetupImg() }
              </div>
              <div className="col-lg-4">
                <p className="mt-3" >{Branding.getSetupText()}</p>
                <div className="text-end">
                  <Link to={this.state.showCreateFizz ? getBaseURL(`/personalize`) : getBaseURL(`/edit/${this.state.fizzId}`)} className="btn btn-primary ">
                    { Branding.getSetupBtnText() }
                  </Link>
                </div>
              </div>
             </div>
          </div>
        </div>
        <HowItWorksBanner fizzId={this.state.fizzId} themeSlug={this.state.themeSlug} />

        <div className="d-md-none mt-5 mb-5" >
          <Link to={this.state.showCreateFizz ? getBaseURL(`/personalize`) : getBaseURL(`/edit/${this.state.fizzId}`)} className="btn btn-primary btn-block">
            { Branding.getSetupBtnText() }
          </Link>
        </div>
      </>
    );
  }
}
