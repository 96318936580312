import { apiFetchAsync } from "./APIHelper"
import IdObjectMemCache from "../helpers/IdObjectMemCache";

var shouldCheck = true

export const combineClips = async ( fizzId, setData ) => {
    var data = await apiFetchAsync("/rest/fizz/" + fizzId + "/finalize", {method: "PUT"})
    IdObjectMemCache('fizz').setItem({
        id: fizzId,
        img: data.img
    })
    setData(data)
}

export const startCheck = async (fizzId, isFizzCheckingAllowed, setData) => {
    shouldCheck = true
    checkProcessingFizzes(fizzId, isFizzCheckingAllowed, setData)
}

export const stopCheck = () => {
    shouldCheck = false;
}

export const checkProcessingFizzes = async ( fizzId, isFizzCheckingAllowed, setData ) => {
    try {
        if (shouldCheck) {
            console.log("Checking processing fizzes.")
            console.log("location: " + window.location.href)
            var data = await apiFetchAsync(
                "/rest/fizz/process/" +
                  fizzId +
                  "?cache=" +
                  Math.round(Math.random() * 100000000),
                {
                  method: "GET",
                });
            console.log("Data from processing fizzes, ", data)
        
            if (!data.isDone && isFizzCheckingAllowed) {
                await sleep(3000)
                if (shouldCheck) {
                    await checkProcessingFizzes(fizzId, isFizzCheckingAllowed, setData );
                }
            } else {
                setData(data)
            }
        }
    } catch (error) {
        throw error
    }

}

function sleep(ms) {
    console.log("Sleeping.... zzzz....")
    return new Promise(resolve => setTimeout(resolve, ms));
}
