import React, { Component } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import CreateFizz from "./screens/CreateFizz";
import InviteOthers from "./screens/InviteOthers";
import NotFound from "./screens/NotFound";

import AlertReset from "./screens/AlertReset";
import PreviewFizz from "./screens/PreviewFizz";
import RecordFizz from "./screens/RecordFizz";
import PSetup from "./screens/PSetup";
import ViewInvite from "./screens/ViewInvite";
import ViewInviteThankYou from "./screens/ViewInviteThankYou";
import BaseMarkup from "./screens/_BaseMarkup";
import ScrollToTop from "./helpers/ScrollToTop";
import Toaster, {toastManager} from "./components/Toaster";
import SharedFizz from "./screens/SharedFizz";
import EditFizzFlow from "./screens/EditFizzFlow";
import QRGen from "./screens/QRGen";
import QRThankyou from "./screens/QRThankyou";
import QRScan from "./screens/QRScan";

class App extends Component {
  constructor(props) {
    super(props)
    
    this.state = {
      toastList: '',
    }

  }

  sendToast = ( data ) => {
    toastManager.showToast( data[0] );
  }
  componentDidMount() {
    window.document.body.className = "org-"+this.props.orgKey;
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Toaster toastList={this.state.toastList} />
          <Switch>

            <Route path="/template-base-markup" exact component={BaseMarkup} />

            <Route path="/qr-gen/:orgKey/:genKey" exact component={QRGen} />

            {/*  
            
            <Route path="/qr-setup/:qrKey/terms" component={Terms} />
            <Route path="/qr-setup/:qrKey/tutorials" component={Tutorials} />
            <Route path="/qr-setup/:qrKey/faq" component={FAQs} />
            <Route path="/qr-setup/:qrKey/privacy-policy" component={PrivacyPolicy} /> 
            
            */}

            <Route path="/qr-setup/:qrKey/create/:themeAnimationId" component={CreateFizz} />
            <Route path="/qr-setup/:qrKey/:sku/:skuId" component={QRScan} />

            <Route path="/p/:fizzKey/set-up" component={PSetup} />
            <Route path="/p/:fizzKey/personalize" component={CreateFizz} />
            <Route path="/p/:fizzKey/change/:fizzId" component={CreateFizz}  />
            <Route path="/p/:fizzKey/edit/:fizzId" component={EditFizzFlow} />
            <Route path="/p/:fizzKey/invite-others/:fizzId" component={InviteOthers} />
            <Route path="/p/:fizzKey/record/:fizzId" exact component={RecordFizz} />
            <Route path="/p/:fizzKey/preview/:fizzId" exact component={PreviewFizz} />
            <Route path="/p/:fizzKey/invite-others/:fizzId" component={InviteOthers} />
            <Route path="/p/:fizzKey/alert-reset-viewed/:otp/:fizzId" component={AlertReset} />
            <Route path="/p/:fizzKey/thank-you/:fizzId" component={QRThankyou} />
            
            <Route path="/in/:uuid/record/:fizzId" exact component={ (props) => (<RecordFizz {...props} isInvite={true} /> )} />
            <Route path="/in/:uuid/thank-you" component={ViewInviteThankYou} />
            <Route path="/in/:uuid" component={ViewInvite} />

            <Route path="/vid/:uuid" exact component={SharedFizz} />

            <Route path="*" exact component={NotFound} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
