import Brand from './Brand';
import TextBoozyBonus 	 from '../assets/branding/boozy/text-heres-a-boozy-bonus.png';
import TextPushUpCup from "../assets/branding/boozy/text-push-up-cup.png"
import PushUpGif 	 from "../assets/branding/boozy/share-page-push-up.gif"
import StepsRecord from "../assets/branding/blank/steps-record.jpg";
import StepsInvite from "../assets/branding/blank/steps-invite.jpg";
import StepsShare from "../assets/branding/blank/steps-share.jpg";
import "../assets/branding/boozy/styles.css";
import { getBaseURL } from "../helpers/URLHelper";
import { Link } from "react-router-dom";
import FooterBar from '../components/FooterBar';

class BoozyBites extends Brand {

	
	getSharedFizzContentTop = (props) => {

		return (
			<>
				<div className="text-center my-4">
					<img src={TextBoozyBonus} alt="Here's a Boozy Bonus" height="64" />
				</div>

				<h5 className="subheadline text-center" style={{color: '#F16A4F', fontSize: '.77rem'}}>You've been sent a special message to go with your Bites.</h5>
			</>
		);
	}

	getSharedFizzContentBottom = (props) => {
		return (
			<div className="no-gap mt-4">

				<section className="home-cup">
				  <div className="wrapper text-center">
				    <div className="text">
				      <h3>MEET THE PATENT-PENDING</h3>
				      <div className="text-center my-4">
				      	<img src={TextPushUpCup} height="64" />
				      </div>

				      <p>Our Push-Up Cup Will Change Your Life! Ok, maybe not truly life changing, but they will up your celebration game! Everyone loves an old school jello shot, but no one likes the moment you have to use your finger or tongue to eat it. And we don’t either! Thus, we invented our beautiful push-up cup. And now, you can enjoy our Bites as an edible cocktail, one bite at a time, rather than having to down the whole thing like a college shot. </p>
				      <p>
				      	<strong>How To Use Our Boozy Cup: </strong>
				      	<br/>1.<strong> </strong>Twist the stem 
				      	<br/>2. Push the stem up 
				      	<br/>3. Take a Bite
				      </p>
				      <a href="https://boozybites.com/pages/the-cup" className="btn btn-primary">details</a>
				      
				    </div>
				    
				    <div className="image-square" >
				      <a href="https://boozybites.com/pages/the-cup" className="image">
				        <img src={PushUpGif} />
				      </a>
				    </div>
				    
				  </div>
				</section>

          	</div>
		)
	}


	getViewThankYouBottom = (props) => {
		return this.getSharedFizzContentBottom(props)
	}
	
	getHowItWorksVideo = (props) => {
		return null;
	}

	getPreviewContentUnderVideo = (props) => {
		return (
			<>
				<div className="col-lg-10 offset-lg-1">
					<p className="my-3 pb-5">Once the recipient receives their gift, they will also get this custom video from you!</p> 
				</div>

				<FooterBar>
					<Link to={ getBaseURL( "/edit/" + props.fizzId ) } className="btn btn-outline-secondary btn-block btn-lg-inline-block px-5">
						Back To Edit
					</Link>
				</FooterBar>
			</>		
		);
	}
} 


export default BoozyBites