import React, { useState }  from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
// import { Link } from 'react-router-dom';
import Notify from "./Notify";
import { checkMic } from "../helpers/MicCheck";
import { getFizzURLKey, getInviteFizzUUID, isMobile } from "../helpers/URLHelper";
import Branding from "../brands/Branding";
import { GET_FEATURES } from "../helpers/Config";

import "../assets/css/add-button-group.css";

const AddButtonGroup = ({ onFileUploadChange, recordPath, buttonVariant = "primary", size='lg-block', title="Add Photos & Videos", onFeatureNotAvailable}) => {

  const [ isChromeMicDisabled, setIsChromeMicDisabled ] = useState( false );
  const [ showDropdown, setShowDropdown ] = useState( false );

  var blockStyle = "btn-block btn-lg-inline-block";
  if( size=='block') {
    blockStyle = "btn-block";
  }

  const renderUploadButton = () => {
    if (!GET_FEATURES.allowUpload && onFeatureNotAvailable) {
      return (
        <button className="dropdown-item dropdown-link btn-upload" onClick={() => onFeatureNotAvailable("Uploading")}>
          Upload from this device
        </button>
      )
    }

    return (
      <label className="dropdown-item dropdown-link btn-upload">
        Upload from this device
        <input
          id="file"
          type="file"
          onChange={onFileUploadChange}
          required
          multiple
          onClick={()=> setShowDropdown(!showDropdown)}
          accept="image/*, video/*"
          />
      </label>
    )
  }

  const renderRecordButton = () => {
    if (!GET_FEATURES.allowRecord && onFeatureNotAvailable) {
      return (
        <button className="dropdown-item dropdown-link btn-upload" onClick={() => onFeatureNotAvailable("Recording")}>
          Record a new video
        </button>
      )
    }

    return (
      <a className="dropdown-item" style={{ lineHeight: "22px" }} href={recordPath}>
        Record a new video
      </a>
    )
  }

  return(  
      <div className={blockStyle}>

        { false && isMobile() ? (

          <label 
            className={ `btn btn-${buttonVariant} btn-upload ${blockStyle} me-2`}
            onClick={()=>{setIsChromeMicDisabled( !checkMic() )}} >
          {title}
            <input
              id="file"
              type="file"
              onChange={onFileUploadChange}
              required
              multiple
            />
          </label>
        ) : (
          <Dropdown
            drop="down"
            show={showDropdown}
            id={'addMediaDropdown'}
            onToggle={()=> setShowDropdown( !showDropdown ) }
            className="mb-2 blockStyle"
          >
            <Dropdown.Toggle
              variant={ buttonVariant }
              className={`btn-group dropup ${blockStyle} me-lg-2`}
              >
              {title}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100 text-center">
                
                { renderUploadButton() }

                <hr className="mx-5"/>

                { renderRecordButton() }

                {/* <Link
                  to={ recordPath}
                  className="dropdown-item text-start"
                  style={{ lineHeight: "22px" }}
                >
                  Record a new video
                </Link> */}

            </Dropdown.Menu>
          </Dropdown>
        )}

        { isChromeMicDisabled && 
          <div className="mt-4 mb-4">
            <Notify 
              message={<span>Sorry. We are unable to verify your microphone is on. Please watch your video after recording. If your recording does not have sound <a href="/faq#micHelp" style={{fontWeight: "bold", color: "white", textDecoration: "underline"}}>use these steps to turn on your microphone.</a></span>}
              error= {true}
              onHide={()=>setIsChromeMicDisabled( false )}
              noNewLines={true}
            />
          </div> 
        }

      </div>
    );
}

export default AddButtonGroup;