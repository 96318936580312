import React, { useState }  from "react";
import { Link } from "react-router-dom";
import MainContent from "../components/MainContent";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import BannerBar from "../components/BannerBar";
import { toastManager } from "../components/Toaster";
import LoadingBar from "../components/LoadingBar";

//
//  THIS IS A FUNCATIONAL COMPONENT, YOU DONT NEED TO MAKE OTHERS FUNCTIONAL. THAT PART DOES NOT MATTER
//
export default (props) => {
  
  const [isLoading, setIsLoading] = useState(false);
  const [isSmallPage, setIsSmallPage] = useState(false);

  var onClick = ()=> {
    window.location = "/";
  };


  var renderContent = ()=> {

    return (
      <>
        <h1 className="headline-text">Large Headline</h1>

        <h2 className="headline-text mb-3">Smaller text for under the headline</h2>

        <h5 className="subheadline mb-3">Longer Message Text Message. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h5>


        <div>Full With Content for all breakpoints. No rows or columns needed.</div>

        <br/>
        <h4 className="landing_title" >This text should be 36 px on desktop and 30 px on mobile.</h4>

        <hr/>
        <h2 className="headline-text">
          Things that look like links 
        </h2>
        <p className="subheadline">
          Subheadline text <a href="http://google.com" className="blue-link">External Link</a>
          {} <Link to="/" className="blue-link">React Link</Link> 
          {/* You need something to keep from deleting the space*/} <button className="btn btn-link blue-link" onCLick={onClick} >Button with onClick</button>
        </p>


        <p>Regular text <a href="http://google.com" className="blue-link">External Link</a> <Link href="/" className="blue-link">React Link</Link> <button className="btn btn-link blue-link" onCLick={onClick} >Button with onClick</button></p>

        <hr/>  

        <div>
          <p>Download testing</p>
          <a href="https://air-dev7.videofizz.com/vid/zwSBlFtJgt/download-image" download="Image.jpeg" className="blue-link">Download Image</a> 
          <br/>
          <a href="https://air-dev7.videofizz.com/vid/zwSBlFtJgt/download" download="Video.mp4" className="blue-link">Download Video</a>
        </div>

        <hr/>
        <h2 className="headline-text">
          Buttons - Primary
        </h2>

        <button className="btn btn-primary" onClick={onClick} >Primary Button</button>
        {/*space*/} <button className="btn btn-primary disabled" disabled >Disabled Primary Button</button>
        {/*space*/} <Link   className="btn btn-primary" to="/" >Primary  Link</Link>

        {/*space*/} <button className="btn btn-outline-primary" onClick={onClick} >Outline Button</button>
        {/*space*/} <Link   className="btn btn-outline-primary" to="/" >Outline Link</Link>
        <br/>


        <h2 className="headline-text">
          Buttons - Secondary
        </h2>

        <button className="btn btn-secondary" onClick={onClick} >Secondary Button</button>
        {/*space*/} <button className="btn btn-secondary disabled" disabled >Disabled Secondary Button</button>
        {/*space*/} <Link   className="btn btn-secondary" to="/" >secondary  Link</Link>

        {/*space*/} <button className="btn btn-outline-secondary" onClick={onClick} >Outline Button</button>
        {/*space*/} <Link   className="btn btn-outline-secondary" to="/" >Outline Link</Link>


        <h2 className="headline-text">
          Buttons - Layout
        </h2>
        <div className="mt-5">
          <button className="btn btn-primary btn-block" onClick={onClick} >Block Button</button>
          <p>btn-block (aka full width button)</p>
        </div>
        
        <div className="text-center">
          <button className="btn btn-primary btn-block btn-lg-inline-block" onClick={onClick} >Bock on mobile only Button</button>
          <p>btn-block btn-lg-inline-block (aka full width for mobile only)</p>
        </div>



        <hr/>
        <h2 className="headline-text">
          Grids
        </h2>
        
        <p> Two Columns On Desktop, on column mobile</p>
        <div className="row" >
          <div className="col-lg-6 " >
            <div className="alert alert-danger" >Left</div>
          </div>
          <div className="col-lg-6" >
            <div className="alert alert-danger" >Right</div>
          </div>
        </div>

        <hr/>

        <p> Two Columns All</p>
        <div className="row mt-4" >
          <div className="col-6 " >
            <div className="alert alert-danger" >Left</div>
          </div>
          <div className="col-6" >
            <div className="alert alert-danger" >Right</div>
          </div>
        </div>



        <hr/>

        <h2 className="headline-text">
          Spinners 
        </h2>

        <p>Inline</p>
        <div>
          <Spinner />
        </div>

        <p>Floating</p>
        {isLoading &&
          <Spinner float={true} />
        }
        <button className="btn btn-primary" onClick={()=>{setIsLoading(true)}} >Show Floating Spinner</button>

        <hr/>

        <div className="row mt-4" >
          <div className="col-6 " >
            <button 
              className="btn btn-primary" 
              onClick={()=>{
                toastManager.showToast({ 
                    title: 'This is a Successful Toast!' ,
                    message: 'This is the Successful Toast message.\n\n This is a Successful Toast additional message created by adding line breaks.' 
                });
              }} 
            >
              Make Success Toast
            </button>
          </div>
          <div className="col-6" >
            <button 
              className="btn btn-outline-primary" 
              onClick={()=>{
                toastManager.showToast({ 
                  title: 'This is a Error Toast!' ,
                  message: 'This is the Error Toast message. \n\n This is a Error Toast additional message created by adding line breaks.',
                  error: 'error'
                });
              }} 
            >
              Make Error Toast
            </button>
          </div>
        </div>

        <hr/>


        <h2>Edit Fizz Items</h2>

        <h3>Loading Bar</h3>
        <LoadingBar percent={0.2} />
        <LoadingBar percent={0.5} />
        <LoadingBar percent={0.9} />

      </>
    );
  }

  return (
    <div className="screen-wrap" >
      <NavBar />
      <MainContent className="container">
        <button className="btn btn-primary"  onClick={()=>{ setIsSmallPage(!isSmallPage) }}  >
          Test Short Page
        </button>
        { !isSmallPage && renderContent()}
      </MainContent>
      <Footer />
    </div>
  );
}
