
function stringifyEvent(e) {
  const obj = {};
  for (let k in e) {
    obj[k] = e[k];
  }
  return JSON.stringify(obj, (k, v) => {
    if (v instanceof Node) return 'Node';
    if (v instanceof Window) return 'Window';
    return v;
  }, ' ');
}



export default class ProgressUploader {

    constructor(file, progressCallback) {
    	this.status = "idle"
    	this.loaded = 0;
    	this.total = 1;
        this.file = file;
        this.fileSizeMB = 'unknown';
        this.progressCallback = progressCallback;
        this.response = null;
        this.isVideoParam = 'unknown'
    }

    loadImage() {

    	var sizeLimitForPreviewMB = 10;

    	console.log( this.file.size, this.file );
    	var sizeMB = sizeLimitForPreviewMB+sizeLimitForPreviewMB;
    	if( this.file.size ) {
    		sizeMB = this.file.size/1024/1024;
    		this.fileSizeMB = sizeMB;
    	}

    	var isMov = false;
    	if( this.file.name && this.file.name.toLowerCase().indexOf(".mov") ) {
    		isMov = true;
    	}

		let isVideo = this.file.type.indexOf('video') >=0;
		this.isVideoParam = isVideo ? 'yes' : 'no';
    	if( URL && URL.createObjectURL ) {
    		console.log("Use URL.createObjectURL");
			if( isVideo ) {
				if( !isMov ) {
		    		this.video = URL.createObjectURL(this.file);
		    	}
		    } else {
		    	this.img = URL.createObjectURL(this.file);
		    }
			this.progressCallback(this);

    	} else if( sizeMB<sizeLimitForPreviewMB ) {
			var reader = new FileReader();
			var url = reader.readAsDataURL(this.file);
			reader.onloadend = function(e) {
				console.log( this.file );
				if( isVideo ) {
					if( !isMov ) {
			    		this.video = [reader.result];
			    	}
			    } else {
			    	this.img = [reader.result];
			    }
				this.progressCallback(this);
		    }.bind(this);
		} 
    }

    start(method,path) {

    	this.status = "pending";

    	if( !this.img && !this.video ) {
    		this.loadImage();
    	}
    	
		return new Promise((resolve, reject) => {
			const req = new XMLHttpRequest();

			req.overrideMimeType("application/json");
			req.upload.addEventListener("progress", event => {
				if (event.lengthComputable) {
    				this.status = "pending"
					this.loaded = event.loaded;
					this.total = event.total;
					this.progressCallback(this);
				}
			});

			req.upload.addEventListener("load", event => {
				this.loaded = event.loaded;
				this.total = event.total;
			});

			req.upload.addEventListener("error", event => {
				this.status = "error"
				this.loaded = 0;
				this.total = this.total || 1;
				reject(this);
			});
			req.addEventListener('readystatechange', event => {
					this.status = "done"
					//  Refer to XMLHttpRequest.readyState
					// 0	UNSENT	Client has been created. open() not called yet.
					// 1	OPENED	open() has been called.
					// 2	HEADERS_RECEIVED	send() has been called, and headers and status are available.
					// 3	LOADING	Downloading; responseText holds partial data.
					// 4	DONE	The operation is complete.
					
			    if (req.readyState === 4) { 
						var data = "Unknown Error";
						try{
							data = JSON.parse(req.responseText);
						} catch( e ) {
							data = req.responseText;
						}

						this.response = data;
						
						if( data.status != 'success' ){
							if( !this.response || !this.response.message ){
								this.response = {
									status: 'error',
									message: 'Upload Failed: '+stringifyEvent(event)+" "+stringifyEvent(req)
								}
							}
							this.status = 'error';

						}
						
						resolve(this);
			    }
			});

			const formData = new FormData();
			formData.append("files[]", this.file, this.file.name);

			req.open(method, path );
			req.send(formData);
		});
    }

    isDone() {
    	return this.status=='done' || this.status=='error';
    }
    hasError() {
    	return this.status=='error';
    }

    getPercent() {
    	if( this.total ) {
    		if( this.status == "done" ) {
    			return 1;
    		} else {
    			return 0.9 * this.loaded / this.total;
    		}
    	}
    	return 0;
    }

    getStatusDisplay() {

      var statusDisplay;
      switch( this.status){
        case "pending":
          statusDisplay = "Uploading...";
          break;
        case "error":
          statusDisplay = "Error Uploading";
          break;
        case "done":
        case "confirmed":
          statusDisplay = "Uploaded";
          break;
        case "idle":
        default:
          statusDisplay = "";
          break;
      }
      return statusDisplay;
    }
}
