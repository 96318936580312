import React, { Component } from "react";
import NavBar from "../components/NavBar";
import { apiFetch, translateUrls } from "../helpers/APIHelper";
import { getMailToLink, getSMSLink, isAndroid, canShare, isMobile, getFizzURLKey } from "../helpers/URLHelper";
import Spinner from "../components/Spinner";
import { toastManager } from "../components/Toaster";
import BackLink from "../components/BackLink";
import MainContent from "../components/MainContent";
import FooterBar from "../components/FooterBar";
import { Link } from "react-router-dom";
import Branding from "../brands/Branding";
import SaveProgressModal from "../modal/SaveProgressModal";

export default class InviteOthers extends Component {


  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: null,
      fizzId: this.props.match.params.fizzId,
      inviteMessage:"",
      inviteLink: "",
      shareLink: "",
      hasContactInfo: false,
      showSaveProgressModal: false,
      hasSeenSaveProgressModal: false,
    }
  }

  componentDidMount() {
    this._getData();
  }

  _getData = () => {
    this.setState({
      loading: true
    });

    apiFetch('/rest/fizz/' + this.state.fizzId + '/invite', {
      method: 'GET',
    }, (json) => {
      console.log(json);
      var data = json.item ? json.item : json;
      this.setState({
          loading: false,
          inviteMessage: Branding.getInviteSample(data.inviteSampleNoLink),
          inviteLink: translateUrls(data.inviteLink),
          linkToSend:  Branding.getInviteSample(data.inviteSampleNoLink) + '\n\n' + translateUrls(data.inviteLink),
          hasContactInfo: data.hasContactInfo
      });


    }, (error) => {
      console.log(error);
      this.setState({
        loading: false,
        error: ((error&&error.message)||error)+""
      })

    });
  }

  onInviteChange=(e)=>{
    this.setState({
      inviteMessage:e.target.value,
      linkToSend: e.target.value + '\n\n' + this.state.inviteLink
    })
  }

  copyToClipboard=()=>{
    navigator.clipboard.writeText(this.state.linkToSend);
    toastManager.showToast({
			message: 'Copied to Clipboard!'
    })
    this.trackInviteClick();
  }
  
  shareAny=()=>{
    navigator.share({
      text: this.state.linkToSend
    }).then(() => {
      // alert("Sent");
    }).catch((e)=>{
      // Canceled or something else
      // alert("Error"+e);
    })

    this.trackInviteClick();
  }


  trackInviteClick = () => {

   this.toggleSaveProgressModal();
    
    apiFetch('/rest/tracking/best/invite-intent?fizzId=' + this.state.fizzId, {
      method: 'POST',
      data: { value: 1, },
    }, (json) => {

      //fire and forget
    
    }, (error) => {
    
      console.log(error);
    
    });

  }

  toggleSaveProgressModal = () => {
    if( !this.state.hasContactInfo && !this.state.hasSeenSaveProgressModal ) {
      console.log( "Show Save Progress Modal.")
      this.setState({
        showSaveProgessModal: true,
        hasSeenSaveProgressModal: true
      });

    } else {
      console.log("Contact info already provided or modal already shown");
      this.setState({
        showSaveProgessModal: false,
      });
    }
  }

  render() {

    var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;

    let smsLink = getSMSLink({body:this.state.linkToSend});
    let emailLink = getMailToLink({body:this.state.linkToSend, subject:"Help me create a surprise group video"});

    return (
      <div className="screen-wrap">
        <NavBar title="Invite Others" />
        <MainContent className="container bg-margin">

            
            <h1 className="headline headline-stretch d-flex flex-column-reverse flex-md-row align-items-center text-right mt-3 mb-3 mr-2 mr-md-4 " >
              {Branding.getInviteHeadline()}
            </h1>


          <div className="row pt-3 align-items-center justify-content-center ">
        

            <div className="col-lg-8 col-xl-7 text-center">
              
              {this.state.error ? (
                <div className=" text-center alert alert-danger">
                  {this.state.error}
                </div>
              ):(
                <>

                  {this.state.loading && 
                    <Spinner float={true} />
                  }

                  <p className="mb-3">{Branding.getInviteBody()}</p>
                  
                  <textarea className="w-100 invite-info" style={{height:"120px"}}  value={this.state.inviteMessage} onChange={this.onInviteChange} />

                  {!this.state.loading && 
                    <>
                        { hasClipboard &&
                          <div className="mt-4 mb-3">
                            <button className="btn btn-link btn-small" onClick={this.copyToClipboard} >
                              Copy Invite and paste anywhere
                            </button>
                          </div>
                        }
                    </>
                  }
                  
                  <FooterBar>

                    {!this.state.loading &&
      
                      <>
                        
                      { canShare() && isMobile() ?
                        <>
                          { isAndroid() ?
                            <>
                            {/* this needs more refactoring -- maybe make a dropdown/pullup option to select either if both. */}
                              <div>
                                <a className="btn btn-primary btn-block btn-lg-inline-block  mb-2 me-1"  onClick={this.trackInviteClick.bind(this)} href={smsLink}>
                                  Text This Invite
                                </a>
                              </div>
                              <button className="btn btn-primary btn-block btn-lg-inline-block  mb-2 me-1" onClick={this.shareAny}>
                                More Sending Options
                              </button>
                            </>
                            :
                            // isIOS
                            <>
                              <button className="btn btn-primary btn-block btn-lg-inline-block  mb-2 me-1" onClick={this.shareAny}>
                                Send This Invite
                              </button>
                            </>
                          }
                        </>
                      :
                        <>
                          <a className="btn btn-primary btn-block btn-lg-inline-block mb-2 me-1"  target="_blank" onClick={this.trackInviteClick.bind(this)} href={emailLink}>
                            Email This Invite
                          </a>
                        </>
                      }
      
                  
                        <Link
                          className="btn btn-outline-secondary btn-block btn-lg-inline-block ms-lg-2 mb-2 "
                          to={`/p/${getFizzURLKey()}/edit/`+this.state.fizzId }
                          >Back To Edit</Link>
                      </>
                    }
        
                  </FooterBar>
  
                </>
              )}
            </div>
          </div>


          {/* save progress modal */}
          <SaveProgressModal 
            show={this.state.showSaveProgessModal}
            onHide={this.toggleSaveProgressModal}
            fizzId={this.state.fizzId}
            from={'invite'}
          />

        </MainContent>
      </div>
    );
  }
}
