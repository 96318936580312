import React, { Component } from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';

const listeners = {
  onToggle:null
};

export const onToggle = (fun)=>{
  listeners.onToggle = fun;
}

class NavBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      isExpanded:false
    };
  }


  onToggle = ()=>{
    this.setExpanded(!this.state.isExpanded);
  }

  closeFromClick = ()=>{
    this.setExpanded(false);
  }

  setExpanded = (isExpanded)=>{

    this.setState({
      isExpanded:isExpanded
    })

    if( listeners.onToggle ) {
      listeners.onToggle(isExpanded)
    }
  }


  render() {
    return (
      <div className="header-branding d-flex justify-content-center align-items-center py-3">
        <img src={"/org/"+ window.orgUUID + "/logo-small"} height="44" />
      </div>
    );
  }
}

export default NavBar;
