import React from 'react';

export default (props) => {

    return (
        <>
        <div className="footer-spacer"></div>
        <div className={"edit-footer " + ( props.className ? props.className : "")}>
            <div className="container" >
                {props.children}
            </div>
        </div>
        </>
    );
    
}