

export function getWindow() {
  if( typeof(window)==='undefined' ) {
      return null;
  }
  return window;
}
export function getDocument() {
  if( typeof(document)==='undefined' ) {
      return null;
  }
  return document;
}
export function getNavigator() {

  if( typeof(navigator)==='undefined' ) {
      return null;
  }
  return navigator; 
}

export function getLocalStorage() {

  if( typeof(window)==='undefined' ) {
    return null;
  }
  return window.localStorage
}




export function hasClassName( el, newName ) {
  var className = el.className||"";
  return className.split(' ').indexOf(newName) < 0;
}

export function addClassToBody( newName ) {

  console.log("addClassToBody Class",newName)
  var document = getDocument();
  if( document ) {
    if( hasClassName(document.body,newName) ) {
      // No class found
      document.body.className += ' '+newName;
    }
  }

}
export function removeClassFromBody( newName ) {

  console.log("removeClassFromBody Class",newName)
  var document = getDocument();
  if( document ) {
    var className = document.body.className;
    var classList = className.split(' ');
    var idx = classList.indexOf(newName);
    if( idx>=0 ) {
      classList.splice(idx,1);
      document.body.className = classList.join(' ');
    }
  }
 
}

export function allowOptIn() {
  var window = getWindow();
  if( window && window.orgKey ) {
    // used a switch statement to allow for future expansion
    switch( window.orgKey ) {
      case "ccc":
        return true;
      case "piac":
        return true;
      default:
        return false;
    }
  }
  return false;
}

