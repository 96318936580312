import React from "react";
import { Link } from "react-router-dom";
import { isLoggedIn, logOut } from "../helpers/APIHelper";
import { getBaseURL } from "../helpers/URLHelper";

function Footer( props ) {

  var year = (new Date()).getFullYear();
  return (
    <footer className="container">
      
      <div className="text-center copy_right">
        {/*Copyright &copy; {year} VideoFizz*/}
      </div>
    </footer>
  );
}

export default Footer;
