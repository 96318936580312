import React, { Component } from "react";
import { Link } from "react-router-dom";
import VideoPlayer from "../components/VideoPlayer";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import MainContent from "../components/MainContent";
import Branding from "../brands/Branding";
import { Modal } from 'react-bootstrap';
import pig from "../assets/branding/moonpig/moonpig-space-pig.png";
import { translateUrls, apiFetch } from "../helpers/APIHelper";
import { canShare, getMailToLink, isMobile, getBaseURL } from "../helpers/URLHelper";
import { combineClips, checkProcessingFizzes } from "../helpers/PreviewFizzHelper"
import StandardModal from "../modal/StandardModal";

export class PreviewFizz extends Component {
  constructor(props) {
    super(props);

    this.isFizzCheckingAllowed = true;
    this.saveAccountClick = this.saveAccountClick.bind(this);

    this.state = {
      isLoading: true,
      fizzId: props.match.params.fizzId,
      isDone: false,
      isStatus: false, 
      video: null,
      isVideoFinalised: false,
      showFinalClip: false,
      img: null,
      errorMessage:null,
      showModal: null,
      videoOptions: {},
      isPhysical: false,
      body: null,
      showLockDownConfirm: false
    };
  }

  async componentDidMount() {
    try {
      await combineClips(this.state.fizzId, this.setData)

      if (!this.state.video) {
        await checkProcessingFizzes(this.state.fizzId, this.isFizzCheckingAllowed, this.setData)
      }
    } catch (e) {
      console.log(e)
      this.setState({ 
        errorMessage: e.message,
        isLoading: false
      }); 
    }
  }

  componentWillUnmount() {
    this.isFizzCheckingAllowed = false;
  }

  setData = (data) => {
    console.log("Setting data", data)
    this.setState({
      img: data.img || this.state.img,
      isDone: !!data.video || data.isDone,
      isStatus: data.status,
      isLoading: false,
      isPhysical: data.isPhysical,
      body: data.body,
      video: data.video,
      shareUrl: translateUrls(data.shareUrl),
      videoOptions: {
        autoplay: true,
        controls: true,
        poster:data.img,
      },
    });
  }

  saveAccountClick = () => {
    this.setState({
      showSaveAccountModal: true
    })
  }

  closeSaveAccountModal = () => {
    this.setState({
      showSaveAccountModal: false
    })
  }

  shareAny=()=>{
    navigator.share({
      url: this.state.shareUrl
    }).then(() => {
      // alert("Sent");
    }).catch((e)=>{
      // Canceled or something else
      // alert("Error"+e);
    })
  }


  toggleLockDownConfirmModal=()=>{
    this.setState({ 
      showLockDownConfirm: !this.state.showLockDownConfirm
    });
  } 


  lockDown=()=>{

    this.setState({ showLockDownConfirm: false });

    apiFetch(
      "/rest/fizz/" + this.state.fizzId + "/lockdown",
      {
        method: "POST",
      },
      (json) => {
        console.log(json);

        this.props.history.push( getBaseURL( `/thank-you/${this.state.fizzId}` ) );
        return;


      },
      (error) => {
        console.log(error);
        this.setState({
          errorMessage: error.message,
        });
      }
    );
  };
  

  render() {

    return (
      <div className="screen-wrap">
        <NavBar />
        <MainContent className="container bg-margin">

          {this.renderContent()}

        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }

  renderContent = ()=> {

    let emailLink = getMailToLink({body:this.state.shareUrl});

    if( this.state.isLoading ) {
      return <Spinner float={true} />
    }


    return (
      <>
          <h2 className="headline d-flex flex-reverse-column flex-md-row align-items-center text-end my-4">{this.state.title || "Watch Your Video"}</h2>


          <div className="row" >
            <div className="col-12 col-lg-6 col-xl-7" >

              <h4 className="subheadline mb-3">
                {this.state.body || 'Your Video Greeting isn’t final yet, and you can keep editing. Once you finish it, you won’t be able to make any more\u00A0changes.'}
              </h4>

            </div>

            { this.state.isPhysical && (

              <div className="col-12 col-lg-6 col-xl-5 text-right d-none d-lg-block my-3" >
                {this.state.isDone && (
                  <>
                    <button
                      type="button"
                      className="btn btn-primary btn-block btn-lg-inline-block px-5 me-3"
                      onClick={this.toggleLockDownConfirmModal}
                      >
                      It's finished
                    </button>
                    </>
                )}

                <Link to={getBaseURL(`/edit/${this.state.fizzId}`)} className="btn btn-outline-secondary btn-block btn-lg-inline-block px-5">
                  Back To Edit
                </Link>
              </div>
            )}

          </div>

          {this.state.errorMessage && (
              <p className="alert alert-danger mt-2">
              {this.state.errorMessage}
              </p>
          )}

          <div className="row">
            <div className="col-lg-10 offset-lg-1 position-relative">
              { !this.state.isDone &&
                (
                  <div className="preview-processing-wrap" >
                    <div className="preview-processing">
                      <div className="preview-processing-text">Your video is being prepared and will display here shortly.</div>
                      <div className="text-center">
                        <Spinner/>
                      </div>
                    </div>
                  </div>
                )
              }
              { this.state.isDone && this.state.video ?
                
                <VideoPlayer 
                  options={this.state.videoOptions} 
                  src={this.state.video}
                />
                :
                <div className="box-480">
                  <img
                    className="box-480-item video-fit w-100"
                    src={this.state.img}
                    alt="Your Video Will Play Here"
                  ></img>
                </div>
              }
            </div>
          </div>

          {this.state.isDone && this.state.isPhysical && (
              <button
                type="button"
                className="btn btn-primary btn-block btn-lg-inline-block px-5 me-3 d-lg-none"
                onClick={this.toggleLockDownConfirmModal}
                >
                It's finished
              </button>
          )}

          { Branding.getPreviewContentUnderVideo({
              fizzId: this.state.fizzId,
              saveAccountClick: this.saveAccountClick
            })
          }


        <Modal
          show={this.state.showSaveAccountModal}
          onHide={this.closeSaveAccountModal.bind(this)}
          size='lg'
        >
          <Modal.Header className="account-save-header">
            <h3 className="ms-4">Create an Account to save a copy of this video</h3>
          </Modal.Header>
          <Modal.Body className="pt-3 text-center">
              <img src={pig} height="200" />
              <h1 className="account-save-text">ACCOUNT SIGN-UP</h1>
              { canShare() && isMobile() ? (
                  <div className="my-4">
                    <button className="btn btn-link fs-4" onClick={this.shareAny} >
                      Share
                    </button>
                  </div>
              ) : (
                  <div className="my-4">
                    <a className="btn btn-link fs-4" href={emailLink} >
                      Share
                    </a>
                  </div>
              )}
              <button
                className="btn btn-outline-primary btn-block my-4"
                onClick={this.closeSaveAccountModal.bind(this)}
                >
                Close
              </button>
          </Modal.Body>
        </Modal>


          <StandardModal
            show={this.state.showLockDownConfirm }
            onHide={this.toggleLockDownConfirmModal}
            header="Are you sure it's finished?"
            confirmBtnText={"Yes, it's done" }
            onConfirm={this.lockDown}
            closeBtnText="No, keep editing"  
          >
            <p className='m-2'>Once finished, no more photos or videos can be added.</p>
          </StandardModal>

        </>
      );
  }
}

export default PreviewFizz;
