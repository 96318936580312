import React, { Component } from "react";
import { apiFetch } from "../helpers/APIHelper";
import SpecialFieldEditor, {getSpecialFieldsToSend, getPreviewImageUrls, validateFieldsToSend} from "../components/SpecialFieldEditor";
import Spinner from "../components/Spinner";
import { getBaseURL, getFizzURLKey, getQRKey } from "../helpers/URLHelper";
import MainContent from "../components/MainContent";
import NavBar from "../components/NavBar";
import FooterBar from "../components/FooterBar";
import MusicListField from "../components/MusicListField";
import { getSelectedMusic } from "../components/MusicPicker";
import { MusicPickerModal } from "../components/MusicPickerModal";

export default class CreateFizz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      specialFields: [],
      previewImages: [],
      displayErr: null,
      showMusicPicker: false,
      music: null,
      taId : this.props.match.params.themeAnimationId,
    };
  }

  componentWillMount() {
    this._getData();
  }

  _getData = () => {
    this.setState({
      displayErr: null,
      isLoading: true,
    });
    apiFetch(
      this.state.taId ? `/rest/theme-animation/${this.state.taId}` : "/rest/fizz/"+getFizzURLKey()+"/edit",      
      {
        method: "GET",
      },
      (json) => {

        let jsonItem = json.item || json;
        let taId = this.state.taId || jsonItem.taId || jsonItem.themeAnimationId;
        let specialFields = jsonItem.special_field || jsonItem.specialFields || [];
        let fieldsToSend = getSpecialFieldsToSend( specialFields );
        this.setState({
            isLoading: false,
            taId:taId,
            previewImageCount: jsonItem.previewImageCount,
            fizzId: jsonItem.fizzId || null, 
            specialFields: specialFields,
            specialFieldsToSend: fieldsToSend,
            previewImages: getPreviewImageUrls(taId, jsonItem.previewImageCount, fieldsToSend),
            showQRStyles: jsonItem.showQRStyles || null,
            music: jsonItem.music || null,
            selectedMusic: getSelectedMusic(jsonItem.music),
        });

      },
      (error) => {
        this.setState({
          isLoading:false,
          displayErr:error.message
        });
        console.log("error message" + error.message);
      }
    );
  };

  createFizz = () => {
    var areAllValid = validateFieldsToSend(this.state.specialFieldsToSend);

    if( !areAllValid ) {
      this.setState({
        createError: "Please complete all required fields.",
      });
      return;
    }

    this.setState({
      createLoading: true,
      createError: null,
    });

    apiFetch(
      this.state.fizzId ? ("/rest/fizz/"+this.state.fizzId) : "/rest/fizz",
      {
        method: this.state.fizzId ?  "PUT" : "POST",
        data: {
          specialFields: this.state.specialFieldsToSend,
          music: this.state.selectedMusic ? this.state.selectedMusic.id : null,
          themeAnimation: this.state.taId,
          fizzURLKey: getFizzURLKey(),
          qrKey: getQRKey(),
        },
      },
      (json) => {
        this.setState({
          createLoading: false,
          createError: null,
        });

        console.log( `fizzKey ====   ${json.fizzKey}` );

        if( json.fizzKey ){
          this.props.history.push( `/p/${json.fizzKey}/edit/${json.id}` );
          return;
        }

        this.props.history.push( getBaseURL( `/edit/${json.id}` ) );
      },
      (error) => {
        this.setState({
          createLoading: false,
          createError: error + "",
        });
        console.log(error.message);
      }
    );
  };

  onSpecialFieldsChange = (groups) => {
    this.setState({
      specialFieldsToSend: getSpecialFieldsToSend(groups),
      createError: "", // Clear the error as they type
    });

    // Delay then update images
    clearTimeout(this.textTimeout);
    this.textTimeout = setTimeout(() => {
      this.setState({
        previewImages: getPreviewImageUrls(this.state.taId, this.state.previewImageCount, this.state.specialFieldsToSend),
      });
    }, 500);
  };


  onShowMusicModal = () => {

    this.setState({
      showMusicPicker: true,
    });
  };


  playAudio = (music) => { 
    // Stop the old
    if(this.state.pickedMusic != music && this.state.pickedMusic != null) {
        var y = document.getElementById('music-'+this.state.pickedMusic.id);
        if( y ) {
            y.pause();
        }
    }
    this.setState({
        pickedMusic: music
    });

    // Play new
    var x = document.getElementById('music-'+music.id);
    if( x ) {
        x.play();
        return x;
    }
  }

  onCloseMusic = () => {
    this.setState({
        showMusicPicker: false,
    });
  };

  onSelectMusic = (picked) => {
    this.setState({
        selectedMusic: picked// Only use this to set the default saved if it is not set
    });
  };

  onSaveMusic = (picked) => {
    if (picked) {
      this.state.music.defaultMusicId = picked.id;
    }
    this.setState({
      showMusicPicker: false,
      selectedMusic: picked,
    });
  };
  
  render = ()=>{
    return (
      <div className="screen-wrap d-flex flex-column">
        <NavBar/>
        <MainContent className="container bg-margin">
          {this.renderContent()}

          <MusicPickerModal 
            showMusicPicker={this.state.showMusicPicker}
            onHide={this.onCloseMusic}
            music={this.state.music}
            onSave={this.onSaveMusic}
          />


        </MainContent>
      </div>
    );
  }
  renderContent = ()=>{

    if( this.state.isLoading ){
      return( <Spinner float={true} /> )
    }

    if( this.state.displayErr ){
      return( 
        <p className="alert alert-danger mt-4">
          {this.state.displayErr}
        </p>
      )
    }

    var preivewMarkup = this.state.previewImages.map((url,i) => (
      <img
        key={i+"i-avoids-blank-images"}
        style={{ width: "100%", marginBottom: "10px" }}
        src={url}
      />
    ));

    return (      
      <>
        {this.state.showQRStyles ? (

          <h1 className="headline-simple text-center my-3">
            Make it <b>Unique</b>
          </h1>

        ):(

          <h1 className="headline d-flex flex-column-reverse flex-md-row align-items-center text-end mt-3 mb-3 me-2 me-md-4">
            Make it Unique
          </h1>

        )}

        <div className="row mt-5">
          <div className="col-md-6" >
            <div className="d-md-none"> 
                {preivewMarkup}
            </div>

            <SpecialFieldEditor
                specialFields={this.state.specialFields}
                onSpecialFieldsChange={this.onSpecialFieldsChange.bind(
                  this
                )}
              />

            <p className="subheadline mt-4"><i>*Required</i></p>

            {this.state.createLoading && <Spinner float={true} />}

            { this.state.showQRStyles &&
              <MusicListField music={this.state.music} showMusicList={this.onShowMusicModal.bind(this)} selectedMusic={this.state.selectedMusic} />
            }
          </div>

          <div className="col-md-6 d-none d-md-block" >
              {preivewMarkup}
          </div>
        </div>

        <FooterBar>
          <button
            tabIndex="0"
            onClick={this.createFizz}
            disabled={this.state.createLoading}
            className="btn btn-block btn-lg-inline-block btn-primary next-btn"
          >
            NEXT
          </button>

          {this.state.createError  && (
            <p className="alert alert-danger mt-2">
              {this.state.createError }
            </p>
          )}
        </FooterBar>
     
      </>
    );
  }
}
