import React from 'react';
import "../assets/css/upload-bar.css"


export default (props) => {
	return (
    <div className="loading-bar-wrap" >
     <div
        className="loading-bar"
        style={{
          width: `${props.percent * 100}%`,
        }}
      ></div>
    </div>
	);
}