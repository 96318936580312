export function getCookie(name) {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");

  var val = null;
  if (parts.length >= 2) {
    val = parts.pop().split(";").shift();
  }
  return val;
}



export function saveSession( json ) {

  // if( json.isAccount===true || json.isAccount===false ) {
  //   localStorage.setItem( 'isAccount', json.isAccount );
  // }

  var sessionId = json.webSessionId ? json.webSessionId : json.sessionId;
  if( sessionId ) {
    let days = 30;
    var date = new Date();
    date.setTime(date.getTime()+(days*24*60*60*1000));
    var expires = "; expires="+date.toUTCString();
    document.cookie = "sessionId=" + sessionId +expires+ "; path=/";
  }
}


export function logOut() {
  document.cookie = "sessionId=; Max-Age=0; path=/;";
}


export function translateUrls( str ) {

  // if( json.isAccount===true || json.isAccount===false ) {
  //   localStorage.setItem( 'isAccount', json.isAccount );
  // }
  if( typeof window !== 'undefined') {
    str = str.replace(/\/\/localhost:9001\//g, "//"+window.location.host+"/");
    str = str.replace(/\/\/air.videofizz.com\//g, "//"+window.location.host+"/");
    str = str.replace(/https:\/\/localhost/g, "http://localhost");
  }
  return str;
}



export const apiFetchAsync = async ( path, options ) => {

  if( !options ) {
    options = {};
  }
  var response = await fetch(path, {
    method: options.method || "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      sessionId: getCookie("sessionId") || "",
    },
    body: options.data? JSON.stringify(options.data) : undefined,
    cache: 'no-store', /* Make sure nextjs does not call this server side */
  });
  if( response.status==403 ) {
    document.cookie = "sessionId=; Max-Age=0; path=/;";
  }
  try{
    var json = await response.json();
    json.httpStatusCode = response.status
    if(json.status != "success") {
      throw new Error(json.message || "Error. Please try again. ");
    }
    saveSession(json);
    return json;
  } catch(e) {
    console.log(e);
    // JSON is Invalid. Likely a server error
    throw new Error(e.message);
  }
}


export function apiFetch(path, options, successFun, errorFun) {
  // console.log(`${path} Request::`, options);
  // console.log(JSON.stringify(options.data));

  // console.log(path, options);
  fetch(path, {
    method: options.method || "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      sessionId: getCookie("sessionId") || "",
    },
    body: JSON.stringify(options.data),
    // body: {
    //   version: "web",
    //   user: {
    //     deviceType: 3,
    //     email: "united@test.com",
    //     password: "test",
    //     deviceId: "web",
    //   },
    // },
  })
    .then((response) => {
      if( response.status==403 ) {
        document.cookie = "sessionId=; Max-Age=0; path=/;";
      }
      var json = response.json();
      json.httpStatusCode = response.status
      return json;
    })
    .then((responseJson) => {
      if (responseJson) {
        if (responseJson.status === "success") {
          // console.log(responseJson);
          if (successFun) {
            return successFun(responseJson);
          }
        } else {
          throw new Error(responseJson.message);
        }
      } else {
        throw new Error("Error communicating with the server.");
      }
    })
    .catch((error) => {
      console.log(`${path} Error::`, error);
      if (errorFun) {
        errorFun(error);
      }
    });
}
