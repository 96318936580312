"use client"

import VideoPlayerCustom from './VideoPlayerCustom'
import { apiFetch } from "../helpers/APIHelper";
import { shouldTrackView } from "../helpers/Tracking";

export default function ViewPageVideoPlayer(props){

    const onPlay = ()=>{
        if (shouldTrackView(props.fizzUUID)){ 

            apiFetch(`/rest/vid/${props.fizzUUID}/video-play`, {
                method: 'POST',
                data: { value: 1, },
            }, (json) => {
        
                //fire and forget

            }, (error) => {
            
                console.log(error);
            
            });
        }


    }


    return <VideoPlayerCustom {...props} onPlay={onPlay} />;
}