"use client"
import React, { Component } from "react";
import Recorder from "../components/Recorder";
import Notify from '../components/Notify';
import StandardModal from "../modal/StandardModal";
import LoadingBar from "../components/LoadingBar";


export default class RecorderWithActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadError: null,
      showFinalClip: false,
      recordingState: {},
    };


    this.webcamRef = React.createRef();
    this.snapCanvasRef = null;
    this.snapCameraDropdownRef = React.createRef();
    this.recordChunks = [];
    this.micLevels = [];
  }
  
  onUploadError = () => {
    this.setState({ uploadError: null });
    this.restartRecording();
  };

  showFinalClipModal = () => {
    this.setState({ showFinalClip: true });
  };

  restartRecording = ()=>{
    this.recorder.restart();
  }
  preview = ()=>{
    this.recorder.preview();
  }
  onRecordingChange = ( recordingState )=>{
    this.setState({
      recordingState:recordingState
    })
  }

  upload = ()=>{

    var blob = this.recorder.getBlob();
    var workingType = this.recorder.getWorkingType();

    var file = null;
    if( blob ) {
      file = new File([blob], "browser-recording."+workingType.uploadExt);
    }

    if( this.props.onUpload ) {
      this.props.onUpload( file );
    }
  }
  
  
  render() {

    return (          <>
      { this.state.uploadError && 
        <div className="pt-2 pb-2">
          <Notify error={true} message={this.state.uploadError} onHide={this.onUploadError.bind(this )} />
        </div>
      }


      <div className="row mb-2" >
          { this.state.recordingState.previewVideoUrl &&

            <div className="col" >
              <div className="text-center mt-4">
                  <button
                    className="btn mob_button_block btn-primary ms-2 mb-2"
                    onClick={this.upload}
                  >
                    Upload Video
                  </button>
                
                  <button
                    className="btn mob_button_block btn-outline-primary ms-2 mb-2"
                    onClick={this.restartRecording}
                  >
                    Record Again
                  </button>
              </div>
            </div>
          }
      </div>

      { !this.state.isUploading &&
        <Recorder ref={(recorder) => this.recorder = recorder}   
          lensSet={this.props.recordType}
          onRecordingChange={this.onRecordingChange} />
      }


      <StandardModal header="Ready to upload?" 
        closeBtnText="Record Again" 
        onHide={this.restartRecording}
        show={ !this.state.isUploading && this.state.recordingState.showFinalClip }
        >
        <button className="btn btn-primary btn-block mb-2" onClick={this.preview} >Preview</button>
        <button className="btn btn-primary btn-block" onClick={this.upload} >Upload</button>
      </StandardModal>
    </>);
  }
  
}
  