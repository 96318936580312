import React, {Component, useState} from 'react';
// import { Link } from "react-router-dom";
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';


import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import arrayMove from 'array-move';
import { getBaseURL, getFizzURLKey } from "../helpers/URLHelper";
import { apiFetch, apiFetchAsync } from "../helpers/APIHelper";
import IconMove from "../assets/images/vf-icons/icon-move.svg";
import IconTrash from "../assets/images/vf-icons/icon-trash.svg";
import IconAdd from "../assets/images/vf-icons/plus-white.svg";
import Mute from "../assets/images/vf-icons/sound-white-mute.svg";
import IconPlay from "../assets/images/vf-icons/play.svg";
import Volume from "../assets/images/vf-icons/sound-white-2.svg";
import LoadingBar from './LoadingBar';
import UploadManager from "../helpers/UploadManager";
import VideoPlayer from "../components/VideoPlayer";
import ThemedIcon from "../components/ThemedIcon";
import {toastManager} from "../components/Toaster";
import Spinner from "../components/Spinner";
import StandardModal from "../modal/StandardModal";
import Branding from "../brands/Branding";
import "../assets/css/upload-list.css";
import "../assets/css/uploader.css";
import "../assets/css/themed-icon.css";

function SortableItem(props) {
  var { activeId, clip } = props;
  var id = clip.id;
  let { setNodeRef, transform, transition, listeners } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if( props.disabled ){
    listeners = {};
  }

  let isVideo = !clip.isImage && clip.video;

  return (
    <div 
      ref={ !props.disabled ? setNodeRef : null }
      {...listeners}
      style={style}
      className={"fizz-clip "+((activeId === id) ? 'sortable-item dragging-dbd-kit' : 'sortable-item')} >

        <div className="clip-drag" >
          <ThemedIcon icon={IconMove} />
        </div>
        <div className="clip-actions">
          { !clip.isImage &&

            <ThemedIcon className="clip-sound" 
              icon={clip.audioOption === 1 ? Mute : Volume}  
              onClick={() => props.openEditVideoModal(clip, "music")}
              />
            
          }

          <ThemedIcon className="clip-delete" 
            icon={IconTrash}  
            onClick={() => props.openEditVideoModal(clip, "delete")}
            />

        </div>


        <div className={"clip-thumb "+(isVideo?'video-thumb':'')} 
            onClick={() => {props.openClipPopup(clip)}} >
          <img src={clip.image} />
        </div>
    </div>
  );
}

function UploadingItem({upload}) {

  return (
    <div 
      className="fizz-clip fizz-clip-uploading" key={`uploadItem ${Math.round(Math.random() * 100000000)}`} >
        <div className="clip-upload-bar">
          <span>Uploading</span>  
          <LoadingBar percent={upload.getPercent()} />
        </div>

        <div className="clip-thumb" >
          <img src={upload.img} />
        </div>

      </div>
    )
}

const SortableList = ({ items, uploads, onSortEnd, openEditVideoModal, openClipPopup, openDesktopAddModal, disabled, taAsFirstClip }) => {
  const [activeId, setActiveId] = useState(null);
  const getIndex = (item) => items.findIndex( a => a.id==item.id);
  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Require the mouse to move by 10 pixels before activating.
      // Slight distance prevents sortable logic messing with
      // interactive elements in the handler toolbar component.
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(TouchSensor, {
      // Press delay of 250ms, with tolerance of 5px of movement.
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    })
  );


  let itemCount = (taAsFirstClip?1:0) + items.length + uploads.length;
  var extraAddons = Array(1).fill(0);
  if( itemCount< 4*4 ) {
    extraAddons = Array(4*4-itemCount).fill(0);
  }
  if( disabled ) {
    extraAddons = [];
  }


  return (
    <DndContext
      sensors={sensors}
      autoScroll={false}
      onDragStart={({ active }) => {
        if (active) {
          setActiveId(active.id);
        }
      }}
      onDragEnd={({ active, over }) => {
        console.log("Drag Ends",active, over)
        if (over && active.id !== over.id) {
          onSortEnd({
            oldIndex: getIndex(active),
            newIndex: getIndex(over),
          });
        }
        setActiveId(null);
      }}
      onDragCancel={() => setActiveId(null)}
    >
      <section className="d-flex flex-row justify-content-start flex-wrap">

          { taAsFirstClip &&
            <div className='fizz-clip'>
              <div className="clip-actions">               
              </div>
              <div className="clip-thumb" >
                <img src={taAsFirstClip.img} />
              </div>
            </div>

          }

          <SortableContext items={items} >
              {items.map((clip, index) => (
                <SortableItem 
                  key={`item-${clip.id}`} 
                  activeId={activeId}  
                  clip={clip} 
                  openEditVideoModal={openEditVideoModal}
                  openClipPopup={openClipPopup} 
                  disabled={disabled} />
              ))}
          </SortableContext>

        {uploads.map((upload, index) => 
          UploadingItem({upload})
        )}

        {extraAddons.map((blank,idx)=>
          <div 
            key={idx}
            className="desktop-add-button"
            onClick={openDesktopAddModal}
            />
        )}

      </section>
    </DndContext>
  );
}


class SortableClipList extends Component {
  
  constructor(props) {
    super(props);

    this.closeEditVideo = this.closeEditVideo.bind(this);
    this.openEditVideoModal = this.openEditVideoModal.bind(this);
    this.deleteVideo = this.deleteVideo.bind(this);
    this.checkIcon = this.checkIcon.bind(this);

    this.state = {
      isLoading: true,
      clips: [],
      editVideo: null,
      editType: null,
      inputBox: "",
      clipPopup: null,
      deleteToUrl: null,
      processMessage: "",
      fizz: null, // NULL UNTILL LOADED,
      uploadingList: [],
      checkFlag: null,
      showMusicPicker: false,
      music: null,
      videoId: null,
      isUploading: false,
      currentStep: null, 
      showDesktopAddModal: false,
      uploads:[],
      taAsFirstClip: null
    };
    this.loadProps(props);
  }
  componentDidUpdate(nextProps) {
    if (this.loadProps(nextProps)) {
      this._getData();
    }
  }
  loadProps(props) {
    var old = this.state.fizzId;

    this.state.fizzId = props.fizzId;
    this.state.recordPath = props.recordPath

    return old != this.state.fizzId;
  }
  componentDidMount() {
    this._getData();
    window.addEventListener("beforeunload", this.windowBeforeUnload);
  }
  componentWillUnmount() {
    this.stopCheckingForUpdates();

    window.removeEventListener("beforeunload", this.windowBeforeUnload);
  }

  windowBeforeUnload = (e)=>{

    if( !this.state.isUploading ){
      return;
    }

    var confirmationMessage = ARE_YOU_SURE_UPLOADING;
    (e || window.event).returnValue = confirmationMessage; //Gecko + IE
    return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  }

  _getData = () => {

    // Only make one of these
    this.uploadManager = new UploadManager( this.state.fizzId, 'editFizz', {
      onProgress:this.uploadProgress, 
      onAllDone:this.allDone,
      onOneDone: this.oneDone
    })

    
    if( this.props.isInvite ){
      this.setState({
        isLoading: false,
      });
      return;
    }
    
    this.setState({
      error: null,
      isLoading: true,
    });
    apiFetch(
      "/rest/fizz/" +
        this.state.fizzId +
        "/clip-list?cache=" +
        Math.round(Math.random() * 100000000),
      {
        method: "GET",
      },
      (json) => {

        this.setState({
            isLoading: false,
            clips: json.clips,
            newestActorVideoId: json.newestActorVideoId,
            disableNewestActor: json.disableNewestActor,
            taAsFirstClip: json.taAsFirstClip
  
        },()=>{
          this.fireClipsChange();
          this.checkForUpdates();
        });

      },
      (error) => {
        console.log(error);
        this.setState({
          error: error.message,
          isLoading: false
        });

      }
    );
  };

  fireClipsChange = ()=>{
    if( this.props.onClipsChange ) {
      this.props.onClipsChange(this.state.clips)
    }
  }

  checkForUpdates() {

    if( this.stopChecking ){
      return;
    }
    if( this.state.disableNewestActor ){
      return;
    }

    if( !this.checkingSince ) {
      this.checkingSince = (new Date()).getTime();
    }

    var beenCheckingForSec = ( (new Date()).getTime() - this.checkingSince )/1000;

    var waitTimeInSec = 60;
    if( beenCheckingForSec < 60  ) {
      waitTimeInSec = 20;
    } else if( beenCheckingForSec < 5*60  ) {
      waitTimeInSec = 60;
    } else if( beenCheckingForSec < 30*60  ) {
      waitTimeInSec = 60*3;
    } else {
      // Been on the page for 30min. Leave
      // window.location = "/list";

      this.props.history.push(  getBaseURL( "/list" ) )
      return;
    }

    this.refreshTimeout = setTimeout(() => {

      apiFetch( "/rest/fizz/" + this.state.fizzId + "/newest-actor-video", {
        method: "POST",
      },
      (json) => {

        if ( json.videoId && json.videoId !== this.state.newestActorVideoId ) {
          // get data will update newestActorVideoId
          this.setState({
            newestActorVideoId:json.videoId // THIS will get set in getData... but just incase
          })

          toastManager.showToast({ 
            title:'Good News!',
            message: 'Someone added a video or photo.' 
          });

          this.checkingSince = (new Date()).getTime();
          this._getData(true);


          
        } else {
          this.checkForUpdates();
        }
      },
      (error) => {
        // We wont display an error to the user for this. -EG
        console.log(error);
        this.checkForUpdates();
      });

    }, waitTimeInSec*1000);


  }
  stopCheckingForUpdates = () => {
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
    this.stopChecking = true;
  }

  
  checkIcon(option) {
    this.setState({ checkFlag: option });
  }

  closeEditVideo() {
    this.setState({
      editVideo: null,
      editType: null,
      processMessage: "",
    });
  }

  closeClipPopup = ()=>{
    this.setState({
      clipPopup: null,
    });
  }

  openEditVideoModal(clip, type) {
    this.setState(
      {
        editVideo: clip,
        editType: type,
        videoId: clip.id, 
        checkFlag: clip.audio_option,  
        inputBox: clip.caption ? clip.caption : "",
      }
    );
  }

  openClipPopup = (clip) => {
    if (!clip.video) {
      clip.video = clip.introVid;
    }
    this.setState({
      clipPopup: clip,
    });
  }

  onHideUpload( uploader ) {
    this.uploadManager.remove(uploader);
  }

  openDesktopAddModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showDesktopAddModal: true
    }));
  }

  closeDesktopAddModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showDesktopAddModal: false
    }));
  }

  deleteVideo(fizz) {
    var deleteUrl = "/rest/video/" + this.state.editVideo.id;
    this.setState({ editVideo: null, editType: null });

    apiFetch( deleteUrl,
      {
        method: "DELETE",
      },
      (json) => {
        this.setState({
          deleteToUrl: null,
        });
        var array = this.state.clips;
        var index = array.indexOf(fizz);

        if (index !== -1) {
          array.splice(index, 1);
          this.setState(
            { clips: array },()=>{
            this.fireClipsChange()
          });

        }
      },
      (error)=>{
        alert("Failed to delete item", error);
      }
    );
  }

  saveButton(clip, type) {
    this.setState({
      processMessage: "Saving...",
    });

    if (type == "music") {
      apiFetch(
        "/rest/video/audio-option",
        {
          
          method: "POST",
          data: {
            video: this.state.videoId,
            audioOption: this.state.checkFlag,
          },
        },
         (json) => {
          clip.audioOption = this.state.checkFlag;
          this.closeEditVideo()
          this.setState({
            clips: this.state.clips //update after manal set 
          },()=>{
            this.fireClipsChange()
          });
        },
        function (error) {
          console.log(error);
          this.setState({
            processMessage: error,
          });
        }
      );
    }
  }


  startUpload = (files) => {
    this.uploadManager.startUpload(files);

    this.setState({
      uploads:this.uploadManager.uploadingList,
      isUploading: true,
      currentUploadCount: 1,
      totalUploadCount: files.length
    })
    // this.uploadTimer = setTimeout( () => toastManager.showToast({
    //   jumboTitle: 'Your file is uploading.',
    //   message: 'Remain on this page until it is done. Upload times will vary depending on your internet connection.',
    //   keepOpen: true
    // }), 5000 );
  }

  uploadProgress=(clips)=>{
    console.log("upload progress in cliplist component: ", clips)
    this.setState({
      uploads: clips
    });
  }
  
  allDone = ( hasAnyErrors )=>{

    if( this.props.onItemAdded ){
      this.props.onItemAdded()
    }
    console.log("ALL DONE", hasAnyErrors)
    this.setState({
      isUploading: false
    });

    if( !hasAnyErrors ) {

      if( this.props.updateIsUploading ){
        this.props.updateIsUploading();
      }

    }
  }

  oneDone = (uploader)=>{
    if( uploader.hasError() ){
      console.log('showing toast because of error', uploader)
      console.log("Uploads in SCL component: ", this.state.uploads) 

      // var index = this.state.uploads.indexOf(uploader);
      // if (index !== -1) {
      //   this.state.uploads.splice(index, 1);
      // }

      toastManager.showToast({
        message: uploader.response.message,
        error: 'error'
      })
    }

    // This could be an else... We likely will not get clips if there was an error... but just in case
    if (uploader.response.clips && uploader.response.clips.length>0) {
      this.setState({
        clips: this.state.clips.concat(uploader.response.clips),
        currentUploadCount: this.state.currentUploadCount + 1
      },()=>{
        this.fireClipsChange()
      });

      // RETURN TRUE WILL TAKE IT OUT OF THE ARRAY
      return true;
    }
  }

  onFileUploadChange = (event) => {
    var acceptedFiles = Array.from(event.target.files);
    console.log("Select", acceptedFiles);
    this.startUpload(acceptedFiles);
    event.target.value = null;
    this.setState(prevState => ({...prevState, showDesktopAddModal: false}));
  };

  onDrop = (acceptedFiles) => {
    console.log("Drop", acceptedFiles);
    this.startUpload(acceptedFiles);
  };

  onSortEnd = ({oldIndex, newIndex}, e) => {
    console.log("onSortEnd",oldIndex, newIndex)
    this.setState(({clips}) => ({
        clips: arrayMove(clips, oldIndex, newIndex),
    }), () => {
        this.fireClipsChange()
        this.sendSortedList(this.state.clips)
    });

    console.log("onSortEnd");

  }

  sendSortedList = async () => {

      await apiFetchAsync( "/rest/video/rearrange",
        {
            method: "PUT",
            data: { videos: this.state.clips },
        });
    };



  render() {

    var isEmpty = this.state.clips.length<=0;

    if( this.state.isLoading ) {
      return (
        <div className="position-relative min-height-desktop">
          <Spinner centerFloat={true} />
        </div>
      );
    }

    if (this.state.error) {
      return (
        <p className="alert alert-danger">{this.state.error}</p>
      )
    }

    return (
      <div className="position-relative mb-5 min-height-desktop">

        { !this.props.isInvite && isEmpty && this.state.uploads.length<=0 && !this.props.hideEmpty &&
          <div className="text-center mb-3 align-self-center pt-lg-2">
            {Branding.getFizzEmptyMessage()}
          </div>
        }

        { !this.props.isInvite && !isEmpty &&
          <div className="ml-3 d-block hint">
            Drag and drop clips to reorder
          </div>
        }

        { this.state.isUploading &&
          <div className="upload-count-container">
            <div className='container'>
              <h3 className="upload-count">{"Uploading " + this.state.currentUploadCount + " of " + this.state.totalUploadCount + "..." }</h3>
            </div>
          </div>
        }

        <SortableList 
          uploads={this.state.uploads}
          items={this.state.clips} 
          onSortEnd={this.onSortEnd} 
          openEditVideoModal={this.openEditVideoModal}
          openClipPopup={this.openClipPopup}
          openDesktopAddModal={this.openDesktopAddModal} 
          disabled={this.props.disabled}
          taAsFirstClip={this.state.taAsFirstClip}
          />

        {/* Clip Detail Modal */}
        <StandardModal
            size="lg"
            show={this.state.clipPopup}
            onHide={this.closeClipPopup}
            closeBtnText="Close"
          >
            { this.state.clipPopup &&
              (this.state.clipPopup.isImage ? (
                <img src={this.state.clipPopup.image} width="100%"/>
                ):(
                <VideoPlayer 
                  src={this.state.clipPopup ? this.state.clipPopup.video : null}
                />
              )
            )}
          </StandardModal>


        {/* Delete Clip Modal */}
          <StandardModal
            show={this.state.editVideo && this.state.editType == "delete" }
            onHide={this.closeEditVideo}
            header="Are you sure you want to delete this item?"
            confirmBtnText="YES, DELETE"
            onConfirm={this.deleteVideo.bind(this, this.state.editVideo)} 
          >
          <p className='text-center'> Deleted items cannot be recovered.</p>
          </StandardModal>

        {/* Edit Clip Sound Modal */}
          <StandardModal
            show={this.state.editVideo && this.state.editType == "music" }
            onHide={this.closeEditVideo}
            header="Sound options"
            confirmBtnText="DONE"
            onConfirm={this.saveButton.bind(this, this.state.editVideo, this.state.editType )} 
          >
            <ul className="list-group list-group-flush sound-picker-list">
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 0)}>
                <li className={`list-group-item text-center ${this.state.checkFlag === 0 ? "active" : ""}`}>
                  Play background music + video&nbsp;sound
                </li>
              </a>
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 1)}>
                <li className={`list-group-item text-center ${this.state.checkFlag === 1 ? "active" : ""}`}>
                  Mute video sound
                </li>
              </a>
              <a tabIndex="0" onClick={this.checkIcon.bind(this, 2)}>
                <li className={`list-group-item text-center ${this.state.checkFlag === 2 ? "active" : ""}`}>
                  Mute background music
                </li>
              </a>
            </ul>
            <p>{this.state.processMessage}</p>
          </StandardModal>

          {/* Desktop Add photo/video modal */}
          <StandardModal
            show={this.state.showDesktopAddModal }
            onHide={this.closeDesktopAddModal}
            header="Add photos or videos"
            closeBtnText="CANCEL"
          >
          {/* upload or record */}
            <div id="desktop-upload-option">
              <label className="btn btn-primary btn-block btn-upload mb-2">
                Upload from this device
                <input
                  id="file"
                  type="file"
                  onChange={this.onFileUploadChange}
                  required
                  multiple
                  accept="image/*, video/*"
                  />
              </label>
            </div>
            <div id="desktop-record-option">
              <a className="btn btn-primary  btn-block" style={{ lineHeight: "22px" }} href={this.state.recordPath}>
                Record a new video
              </a>

              {/* <Link
                to={this.state.recordPath}
                className="btn btn-primary  btn-block "
                style={{ lineHeight: "22px" }}
              >
                Record a new video
              </Link> */}
            </div>
          </StandardModal>

      </div>
    );
  }
}

export default SortableClipList;