"use client"
export default class DeviceSelector {


  public static async getAvailableDevices() {
    const width = 854;
    const height = 480;

    try {
      // Will prompt for permission
      await navigator.mediaDevices.getUserMedia({
        video: { width, height },
        audio: true,
      });
      const devices = await navigator.mediaDevices.enumerateDevices();

      let videoDevices = devices.filter((d) => d.kind === 'videoinput');
      return videoDevices;
    }catch(e){
      return []; 
    }
  }

  public static getMediaStream(deviceId: string) {
    if( !deviceId ) {
      return null;
    }
    return navigator.mediaDevices.getUserMedia({
      video: {
        deviceId,
      },
    });
  }
}
