import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import LoadingBar from "../components/LoadingBar";
import { getBaseURL, getFizzURLKey } from "../helpers/URLHelper";
import StandardModal from "../modal/StandardModal";
import ProgressUploader from "../helpers/ProgressUploader";
import { toastManager } from "../components/Toaster";
import Notify from '../components/Notify';
import MainContent from "../components/MainContent";
import { getInviteFizzUUID } from "../helpers/URLHelper";
import RecorderWithActions from '../components/RecorderWithActions';
import Branding from "../brands/Branding";



export default class RecordFizz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // fizzId: set in loadProps
      // isInvite: set in loadProps
      // recordType: set in loadProps
      recordingState:{}
    };
    this.loadProps(props);
  }
  componentWillReceiveProps(nextProps) {
    if (this.loadProps(nextProps)) {
    }
  }
  loadProps(props) {
    const searchParams = new URLSearchParams(props.location.search);
    this.state.isInvite = props.isInvite;
    this.state.recordType = searchParams.get('recordType');
    this.state.fizzId = props.match.params.fizzId;
  }


  upload = ( file ) => {


    if( file ) {

      this.setState({
        isUploading: true
      });

      this.uploadTimer = setTimeout( () => toastManager.showToast({
        jumboTitle: 'Your file is uploading.',
        message: 'Remain on this page until it is done. Upload times will vary depending on your internet connection.' ,
        keepOpen: true
      }), 5000 );


      let linkAddOn = '';
      if ( this.state.isInvite ){
        linkAddOn= `&uuid=${getInviteFizzUUID()}`;
      }

      let apiUrl = `/rest/fizz/${this.state.fizzId}/add-clip?type=desktop-record${linkAddOn}&micVol=${this.state.maxVolumeLevel}`;

      let uploader = new ProgressUploader(file, (uploader) => {
        // Progress
        console.log(uploader.getPercent());
        // Call set state to refresh
        this.setState({
          // showFinalClip: false,
          uploader: uploader,
          isUploading: true
        });
      });
      uploader
        .start( "POST", apiUrl )
        .then((uploader) => {

          if ( this.uploadTimer ){
            clearTimeout(this.uploadTimer);
          }

          if ( uploader.hasError() ) {
            console.log("Error: ", uploader.response );
            // Error will show on page from uploader
            return;
          }

          
          toastManager.showToast({
            message: 'Upload successful!' 
          });

          // Leave the page
          this.state.isInvite ? 
            this.props.history.push( getBaseURL( `/in/${getInviteFizzUUID()}/thank-you` ) )
            : this.props.history.push( getBaseURL( `/edit/${this.state.fizzId}` ) );
          
        });
    }
  };

  restart() {
    this.setState({
      uploader:null,
      isUploading:false
    })
  }

  render() {

    return (
      <div className="screen-wrap">
        <NavBar />
        <MainContent className="container bg-margin">
          
          <div className="d-flex" >
            <div className="pe-4" style={{marginTop:'22px'}}>
              { ( this.state.isInvite ) ?
                  <Link
                    className="btn btn-outline-secondary"
                    to={ `in/${getInviteFizzUUID()}` }
                    disabled= {this.state.isUploading}
                  >Back</Link>
                :
                  <Link
                    className="btn btn-outline-secondary"
                    to={`/p/${getFizzURLKey()}/edit/`+this.state.fizzId }
                    disabled= {this.state.isUploading}
                  >Back to Edit</Link>
              }
            </div>
            <div className="flex-grow-1" >
              <h2 className="headline headline-no-bleed d-flex flex-reverse-column flex-md-row align-items-center text-end mt-4">Record</h2>
            </div>
          </div>


          { this.state.uploader ?
            ( 
              <div className="mb-2 mt-5">
                {this.state.uploader.status === "pending" &&
                  <>
                    <h3 className="">Uploading...</h3>
                    <LoadingBar percent={this.state.uploader.getPercent()} />
                  </>
                }
                {this.state.uploader.hasError() && 
                  <>
                    <Notify error={true} message={uploader.response.message || "Please try again or select a different file."} />
                    <button className="btn btn-primary" onClick={this.restart} >Record Again</button>
                  </>
                }
              </div>
            )
          :
          <RecorderWithActions onUpload={this.upload} recordType={Branding.getRecordType()} />
          }


        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}