"use client"
export default class VFSnapRecord {

  workingType = null;
  recordedChunks = [];
  isRecording = false;
  audioStream = null;
  mediaRecorder = null

  constructor(canvas, doneCallback ) {
    this.canvas = canvas;
    this.doneCallback = doneCallback;
  }

  init = async () => {
    if( this.mediaRecorder ) {
      this.mediaRecorder.stop();
    }
    
    return this.createMediaRecorder( this.canvas );
  }
  

  detectWorkingType = ()=>{
    var typePreference = [{
      mime:"video/webm",
      ext:"webm",
      uploadExt:'webm',
    },{ 
      mime:"video/mp4",
      ext:"mp4",
      uploadExt:'mp4',
    },{ 
      mime:"video/mpeg",
      ext:"mpeg",
      uploadExt:'mpeg',
    }];

    var workingType = null;
    for (var i in typePreference) {
      if( MediaRecorder.isTypeSupported(typePreference[i].mime) ) {
        workingType = typePreference[i];
        break;
      }
    }
    return workingType
  }

  createMediaRecorder = async ( canvas ) => {

    console.log("createMediaRecorder "+canvas)
    var stream = null;
    
    if( !this.audioStream ) {
      try{
        this.audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream = canvas.captureStream(29.97 /*fps*/);
        stream.addTrack(this.audioStream.getAudioTracks()[0]);
      } catch( e ) {
        console.log("Error in createMediaRecorder", e);
        return { erorr: "no audioStream" };
      }
    }
    
    if( !stream ) {
      return { erorr: "no audioStream" };
    }
      
    this.workingType = this.detectWorkingType();
    try{
      this.mediaRecorder = await new MediaRecorder(stream, {
        mimeType: this.workingType.mime,
      });
    } catch( e ) {
      console.log("Error in createMediaRecorder", e);
      return { erorr: "no mediaRecorder" };;
    }
      
    this.mediaRecorder.ondataavailable = (event) => {
        var data = event.data;
        if (data.size > 0) {
          console.log("Add Data", data);
          this.recordedChunks = this.recordedChunks.concat(data);
        }
    }
    this.mediaRecorder.onstop = (event) => {
      console.log("onstop")
      this.isRecording = false;

    }
    console.log("MEDIA RECORDER IS READY")
    return { sucess: true }

  };

  start = async ()=>{    
    this.recordedChunks = [];
    this.isRecording = true;
    if( !this.mediaRecorder ) {
      await this.init();
    }
    this.mediaRecorder.start();
  };

  stop = ()=>{
    if( this.mediaRecorder ) {
      this.mediaRecorder.stop();
    }
  };

  destroy = ()=>{
    if( this.mediaRecorder ) {
      this.mediaRecorder.stop();
    }
    if( this.audioStream ) {
        this.audioStream.getTracks().forEach((track) => {track.stop();});
    }
  }

  getWorkingType= ()=>{
    return this.workingType;
  }

  getBlob = () =>{
    return new Blob(this.recordedChunks, {type: this.workingType.mime });
  }
  createObjectURL = ()=>{
      return URL.createObjectURL( this.getBlob() );
  }
}
