"use client"

import React, { useState } from "react";
import { apiFetch } from "../helpers/APIHelper";
import { Modal } from "react-bootstrap";
import Spinner from '../components/Spinner'
import OrLine from "../components/OrLine"
import {validateFields } from "../helpers/Validation";
import StandardModal from "./StandardModal";
import {trackEvent} from "../helpers/Tracking";

import { ALLOW_PHONE_SIGNUP } from "../helpers/Config";
import { allowOptIn } from "../helpers/DomHelper";

export default function SaveProgressModal(props) {
    const fizzId = props.fizzId;
    console.log( "SaveProgressModal Props", props);

    const [errors,setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [marketingOptIn, setMarketingOptIn] = useState(true)

    const validateForm = (form)=>{
        var email = form.email.value.trim();
        var phone = form.phone?  form.phone.value.trim() : '';
        if( !email && !phone ) {
        return ["Provide an email or phone number to assign to your card"];
        }  
        return validateFields([
            {value:form.email.value, type:'email'},
            {value:phone, type:'phone'},
        ]);
    }

    const onInputChange = (e)=>{    
        setErrors([])
    }
    
    const onOptInChange = (e)=>{    
        setMarketingOptIn( !marketingOptIn );
        setErrors([])
    }

    const onHide = () => {
        if (props.onHide) {
            props.onHide()
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        let form = e.target;
        let errors = validateForm(form);
        setErrors( errors||[]);

        let from = props.from ? '?from='+props.from : '';

        trackEvent("email",{email:form.email.value})

		apiFetch('/rest/user/contact-info'+ from, {
			method: 'POST',
            data: { 
                email: form.email.value,
                phone:form.phone ? form.phone.value.trim() : '',
                optIn: marketingOptIn,
                fizzId: fizzId
            }
		  }, (json) => {

            // if( props.redirect ) {
            //     props.history.push( getBaseURL( props.redirect ) );
            // }

            setIsLoading(false);
            onHide();
            
            if( props.onSave){
                props.onSave();
            }

		  }, (error) => {
			console.log(error);
			setErrors(error)
            setIsLoading(false)
		  });

    }

    return (
        <StandardModal
            size='lg'
            show={props.show}
            onHide={()=>onHide()}
            confirmBtnText={false}
            closeBtnText={false} 
            header= {
                <span className='headline-simple text-center'>
                    <b>Save</b> Your Progress
                </span>
            }
        >

            <p>We'll send you a secure link to access your project at any time. You'll receive notifications when people submit their video.</p>
            
            <form onSubmit={onSubmit} >
                <div className="form-floating my-1">
                    <input
                        name="email"
                        type="email"
                        className="form-control"
                        placeholder={"user@mail.com"}
                        onChange={onInputChange}
                    />
                        <label>Email</label>
                </div>


                { ALLOW_PHONE_SIGNUP &&
                    <>
                        <OrLine/>
    
                        <div className="form-floating mt-0 mb-1">
                            <input
                            name="phone"
                            type="phone"
                            className="form-control"
                            placeholder={"123-456-7890"}
                            onChange={onInputChange}
                            />
                            <label>Phone Number</label>
                        </div>
                        <small className="opacity-75 ms-2 d-block"> I agree to recive SMS messages at this number.<br/>Message & data rates may apply. </small>
                    </>
                }

                { errors.map( (error)=>{
                    return (
                        <p className="text-center alert alert-danger" >{error}</p>
                    );
                })}

                { isLoading ?
                        <Spinner />
                :
                    <div className="text-center mt-4">
                        <input type="submit" disabled={isLoading} className="btn btn-primary btn-block mb-1" value="Continue" />
                        { allowOptIn() &&
                            <>
                                <div className="form-check d-inline-block">
                                    <label className="form-check-label" >
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            onChange={onOptInChange}
                                            checked={marketingOptIn}
                                            />
                                        Keep me in the loop!
                                    </label>
                                </div>

                            
                            { ALLOW_PHONE_SIGNUP &&
                                <p className={"mt-1 "+(marketingOptIn?"opacity-75":"opacity-0") } >
                                    <small>
                                        We will send you offers and product info.
                                        Unsubscribe at any time by replying STOP or use the unsubscribe link.
                                    </small>
                                </p>
                            }
                            </>
                        }
                    </div>
                }
            </form>
        </StandardModal>
    )
} 
