"use client"
import React, { useState, useEffect, useRef } from "react";
import Mute from "../assets/images/vf-icons/sound-white-mute.svg";


const AutoPlayVideo = ( props ) => {

  const videoRef = useRef();
  const [muted, setMuted ] = useState(true);

  console.log("AutoPlayVideo props",props);

  var isPlaying = true;

  const clickVideo = e => {


    if( !videoRef ) {
      return;
    }

    var video = videoRef.current

    if( muted ) {
      setMuted(false)
      video.muted = false;
    } else {
      if( isPlaying ) {
        video.pause();
      } else {
        video.play();
      }
      isPlaying = !isPlaying;
    }

  }



  return(
    <div style={{position:'relative'}} >
      {muted &&
        <img src={Mute} style={{position:'absolute',top:'5px',right:'5px',width:"9%",maxWidth:"60px"}} alt="Muted Symbol"/>
      }
      <video 
        poster={props.poster}
        ref={videoRef}
        className=" disable-gtm "
        style={{zIndex:100}}
        loop
        muted
        autoPlay
        playsInline
        width="100%"
        onClick={clickVideo}
      ><source src={props.video} type="video/mp4" /></video>
    </div>
  );
}

export default AutoPlayVideo;