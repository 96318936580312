import React, { Component } from 'react';
import DownIcon from "../assets/images/vf-icons/down-arrow.svg"

export default class MusicListField extends Component {

    constructor(props) {
      super(props);
    }

    render() {

      if( this.props.displayErr ){
        return(
          <p className="alert alert-danger mt-4">
            {this.props.displayErr}
          </p>
        )
      }

      if( this.props.music && this.props.music.options && this.props.music.options.length>1 ) {
  
        return (
          <div className="change_music my-3">
            <div className="form-group" >
              <span className="has-float-label">
                <label>{this.props.label ? this.props.label : "Choose Background Music:"}</label>
                <button
                  className="form-control text-start music-dropdown"
                  style={{color:'#48464f'}}
                  onClick={()=>{ this.props.showMusicList()}}
                >
                    {this.props.selectedMusic
                      ? this.props.selectedMusic.name
                      : "No Music"}
                    <img src={DownIcon} className="float-end icon-down"/>
                </button>
              </span>
            </div>
        </div>
        );
      }

      return null;
  
    }
  }