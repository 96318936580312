"use client"
import React, { Component } from 'react';
import MailIcon from "../assets/images/vf-icons/email.svg"; 
import ShareIcon from "../assets/images/vf-icons/share.svg";
import CopyIcon from "../assets/images/vf-icons/copy.svg";
import DownloadIcon from "../assets/images/vf-icons/download.svg";
import GroupShare from "../assets/images/vf-icons/group-share.svg";
import TextIcon from "../assets/images/vf-icons/text.svg";
import { getMailToLink, getSMSLink, isMobile, canShare, share } from "../helpers/URLHelper";
import StandardModal from "./StandardModal"
import PulseButton from "../components/PulseButton"
import { Spinner } from 'react-bootstrap';
import { apiFetch } from '../helpers/APIHelper';
import { toastManager } from '../components/Toaster';
import ThemedIcon from '../components/ThemedIcon';
import '../assets/css/share-modal.css';

 export default class ShareModal extends Component {

	constructor(props) {
		super(props);
		this.state={
			show: props.show || true,
			showGroupConfirm: false,
			sendGiftCard: false,
			isConfirmLoading: false,
			confirmError: null,
			showDownload: props.showDownload || false,
			showGroupShare: props.showGroupShare || false,
			showOnlyConfirm: props.showOnlyConfirm || false,
		}
	}

	onHide = () => {
		if( this.props.onHide ) {
			this.props.onHide();
		} 

		// extra clean up
		this.setState({
			showGroupConfirm: !this.state.show,
			sendGiftCard: !this.state.show
		});
	}
  

	copyToClipboard=()=>{
		navigator.clipboard.writeText(this.props.shareUrl);    
		toastManager.showToast({
			message: 'Copied to Clipboard!'
   		})
	}

	shareAny=()=>{
		navigator.share({
		 	url: this.props.shareUrl
		}).then(() => {
		  // alert("Sent");
		}).catch((e)=>{
		  // Canceled or something else
		  // alert("Error"+e);
		})
	}

	toggleGroupShare = () =>{
		if( this.state.showOnlyConfirm ){
			this.props.onHide();
		}
		
		this.setState({
			showGroupConfirm: !this.state.showGroupConfirm
		})
	}

	toggleGiftCardSend = () =>{
		this.setState({
			sendGiftCard: !this.state.sendGiftCard
		})
	}

	confirmGroupShare = () =>{
		this.setState({
			isConfirmLoading: true
		})
		

		apiFetch('/rest/fizz/' + this.props.fizzId + '/share-all', {
			method: 'POST',
		  }, (json) => {

			this.setState({
				isConfirmLoading: false,
				showGroupConfirm: false
			});
	  
		  }, (error) => {
			console.log(error);
			this.setState({
				isConfirmLoading: false,
			  	confirmError: ((error&&error.message)||error)+""
			})
		  });

	}



	renderGroupConfirm = () => {

		if( this.state.isConfirmLoading ){
			return(
				<>
					<h2 className="color-primary size-16">Sending now....</h2>
					<Spinner/>
				</>
			);
		}

		if( this.state.confirmError ){
			return(
				<>
					<p className="text-center alert alert-danger">{this.state.confimError}</p>
				
					<button className="btn btn-outline-primary btn-block" onClick={this.toggleGroupShare} >Close</button>
				</>
			);
		}


		return(
			<>
				<div className="text-center m-auto mb-4">						
					<ThemedIcon 
						className=" mb-4"
						icon={GroupShare}
					/>
					<h2 className="size-16">We'll automatically send your completed video to everybody that submitted a video.</h2>
				</div>

				<button className="btn btn-primary btn-block mb-2 me-1" onClick={this.confirmGroupShare} >Share with Everyone!</button>

				<button className="btn btn-outline-secondary btn-block" onClick={this.toggleGroupShare} >Cancel</button>
			</>
		);
	}

	renderShareButtons = () => {
		
		var hasClipboard = navigator && navigator.clipboard && navigator.clipboard.writeText;

		var generalURL = this.props.shareUrl;

		var downloadUrl = generalURL+"/download";

		var shareUrl = generalURL;
		
	    let smsLink = getSMSLink({body:shareUrl});
	    let emailLink = getMailToLink({body:shareUrl});
		
		return(
			<div className='ps-md-4'>	
			
				{this.state.showGroupShare &&
					<div>
						<button className="btn btn-link btn-share btn-word-wrap-icon" onClick={this.toggleGroupShare}>
							<ThemedIcon 
								className="me-3"
								icon={GroupShare}
								style={ {backgroundColor: `var(--secondary)` } }
							/>
							Share finished video with Participants
							{/* Share<span className="d-none d-sm-inline"> finished video</span> with Participants */}
						</button>
					</div>
				} 
						
				{ canShare() ? (

					<button className="btn btn-link btn-share btn-word-wrap-icon" onClick={this.shareAny}>
						<ThemedIcon 
							className="me-3"
							icon={ShareIcon}
							style={ {backgroundColor: `var(--secondary)` } }
						/>
						Share this video
					</button>
				) : (
					<>


						<a href={emailLink} className="btn btn-link btn-share btn-word-wrap-icon"  target="_blank">
							<ThemedIcon
								className="me-3"
								icon={MailIcon}
								style={ {backgroundColor: `var(--secondary)` } }
							/>
							Email
						</a>

						{ smsLink && 

							<div>
								<a className="btn btn-link btn-share btn-word-wrap-icon" href={smsLink}>
									<ThemedIcon 	
										className="me-3"
										icon={TextIcon} 
										style={ {backgroundColor: `var(--secondary)` } }
									/>
									Text message
								</a>
							</div>
						}
					
						{ hasClipboard &&
							<div>
								<PulseButton className="btn btn-link btn-share btn-word-wrap-icon" onClick={this.copyToClipboard} >
									<ThemedIcon
										className="me-3"
										icon={CopyIcon} 
										style={ {backgroundColor: `var(--secondary)` } }
									/>
									Copy Link & Paste Anywhere
								</PulseButton>
							</div>
						}


					</>
				)}

					
					{ this.state.showDownload &&
						<div>
							<a href={downloadUrl} download="Video.mp4" className="btn btn-link btn-share tag-download-user btn-word-wrap-icon">
								<ThemedIcon
									className="me-3" 
									icon={DownloadIcon} 
									style={ {backgroundColor: `var(--secondary)` } }
								/>
								Download
							</a>
						</div> 
					}


					{/* <div className='gift-card-btn mt-2'>
						<button className="btn btn-link btn-share" onClick={this.toggleGiftCardSend} >
							<ThemedIcon
								className="me-3"
								icon={MailIcon}
							/>
							Send with Giftcard
						</button>
					</div> */}
					

			</div>
		);
	}

	render() {


		if( !this.props.show ||  !this.props.shareUrl) {
			return null;
		}

		let headline = [<b key="share-text">Share</b>," your video!"]

		if( this.state.showOnlyConfirm ){
			return (
				<StandardModal
					size="md"
					show={this.props.show}
					onHide={this.onHide}
					header={headline}
					closeBtnText = {false}
				  >
	
					<div>{ this.renderGroupConfirm() }</div>
	
				</StandardModal>
			);
		}

		return (
			<StandardModal
				size="md"
            	show={this.props.show}
            	onHide={this.onHide}
				header={headline}
				closeBtnText = {false}
          	>

				<div>{ !this.state.showGroupConfirm ? this.renderShareButtons() : this.renderGroupConfirm() }</div>

			</StandardModal>
		);
	}
 }