import React, { Component } from "react";
import { apiFetch, saveSession } from "../helpers/APIHelper";
import VideoPlayer from "../components/VideoPlayer";
import { Link } from "react-router-dom";
import NavLogo from "../components/NavLogo";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";
import MainContent from "../components/MainContent";
import { getFizzUUID } from "../helpers/URLHelper";
import Branding from "../brands/Branding";
import ViewPageVideoPlayer from "../components/ViewPageVideoPlayer";



export default class SharedFizz extends Component {
  constructor(props) {
    super(props);

    this.fizzUUID = getFizzUUID();

    this.state = {
      title: "",
      video: "",
      img: "",
      showRedeem: true,
      firstPlay: false,
      playlistVideos: [],
      activeVideo: {},
      videoOptions: {},
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {



    this.setState({
      isLoading: true
    });
    
    apiFetch(`/rest/vid/${this.fizzUUID}/fizz`, {
      method: 'GET',
    }, (json) => {
      console.log(json);

      this.setState({
        isLoading: false,
        title: json.title,
        img: json.img,
        video: json.video,
        shareUrl: json.shareUrl,
        fizzId: json.fizzId,
        showGroupShare: json.showGroupShare,
        videoOptions: {
          poster: json.img,
        }
        // siteLink: companyLink || "https://boozybites.com",
        // siteName: json.siteName || "Game Day"
      });

      if( json.sessionId ){
        saveSession(json);
      }
      
      
      const list = json.videos;
      this.setState(prevState => ({
        ...prevState,
        playlistVideos: list
      }));
    

    }, (error) => {
      this.setState({
        isLoading: false,
        error: error+""
      })
    });

  };


  handleChange = (event) => {

    var value = event.target.value;
    if( event.target.type=='checkbox' ){
      value = event.target.checked;
    }
    this.setState({
      [event.target.name]: event.target.value,
      errors:{}
    });

    console.log("Handle Change", event.target.name, value );
  };


  showPreview(ta) {
    this.setState({
      previewTA: ta,
    });

  }

  handlePlayerReady = (player) => {
    console.log("Player is ready!");
  };


  render() {

    if( this.state.isLoading ){
      return(
        <div className="screen-wrap">
          <NavLogo/>
          <MainContent className="container bg-margin">
            <Spinner float={true}/>
          </MainContent>
          <Footer hideMobile={true} />
        </div>
      )
    }

    return (
      <div className="shared-fizz-view-page screen-wrap">
        <NavLogo />
        <MainContent className="container bg-margin">

          { Branding.getSharedFizzContentTop({title:this.state.title}) }

          { Branding.getSharedFizzVideoWrap((
              <>
                <ViewPageVideoPlayer 
                  options={this.state.videoOptions} 
                  onReady={this.handlePlayerReady} 
                  src={this.state.video}
                  fizzUUID={this.fizzUUID}
                />
                { Branding.getFizzShareOptions({ shareUrl:this.state.shareUrl, fizzId: this.state.fizzId, showGroupShare: this.state.showGroupShare }) }
              </>
          ))}


          { Branding.getSharedFizzContentBottom() }


        </MainContent>
        <Footer hideMobile={true} />
      </div>
    );
  }
}
