"use client"
import {
  bootstrapCameraKit,
  CameraKitSource,
  Transform2D,
} from '@snap/camera-kit';


var globalReturn = null;

interface SetupOptions {
  apiToken: string;
  lensGroups: string[];
  canvasEl: HTMLCanvasElement;
}

export let createSource = (media: MediaStream): CameraKitSource => {
  return new CameraKitSource(
    { media },
    {
      onAttach: async (controller,a,b,c) => {
        // Currently it's only valid to call `setRenderSize` after `CameraKitSession.play` has been called. 
        console.log("CameraKitSource.onAttach",controller);
        await controller.setRenderSize(854, 480);
        // if( controller.deviceInfo.cameraType=='front' ) {
        //   console.log("CameraKitSource.onAttach FRONT");
        //   await controller.setTransform(Transform2D.MirrorX);
        // } else {
        //   console.log("CameraKitSource.onAttach BACK");
        //   await controller.setTransform(Transform2D.Identity);
        // }
      },
    }
  );
}

export default async function setup({
  apiToken,
  lensGroups,
  canvasEl,
}: SetupOptions) {

  if( globalReturn && globalReturn.session && globalReturn.session.setLiveRenderTarget ) {
    console.log(globalReturn.session)
    globalReturn.session.setLiveRenderTarget(canvasEl);
    return globalReturn;
  } else {
    const cameraKit = await bootstrapCameraKit({ apiToken });

    console.log("setup cameraKit",cameraKit)

    const width = 854;
    const height = 480;

    canvasEl.width = width;
    canvasEl.height = height;

    const session = await cameraKit.createSession({ liveRenderTarget: canvasEl });
    console.log("setup session",session)

    session.setFPSLimit(29.97);

    const mediaStream = await navigator.mediaDevices.getUserMedia({
      video: { width, height },
    }).catch(e => { 
      console.log("setup navigator.mediaDevices.getUserMedia",e);
      return null;
    });

    if(mediaStream ) {
      const source = createSource(mediaStream);
      
      await session.setSource(source);
      
      const { lenses } = await cameraKit.lenses.repository.loadLensGroups(
        lensGroups
        );
        
        await session.play();
        
        globalReturn = { cameraKit, session, lenses, mediaStream, source };
    } else {
      globalReturn = { cameraKit, session, lenses: null, mediaStream: null, source: null };
    }
  }
  return globalReturn;
}
