import React from 'react';
import CloseIcon from "../assets/images/vf-icons/close.svg";

export default (props) => {

	var onHide = ()=>{
		if( props.onHide ){
			props.onHide() 
		}
	}

  var convertNewLines = (string) => {
    let lines = string.split('\n');
    let elements = [];
    for (let i=0; i<lines.length; i++) {
        if (i>0) {
            elements.push(<br key={i}/>);
        }
        elements.push(lines[i]);
    }
    return elements;
  };

  return (
      <div className={ (props.float?'notify-float':'')} onClick={onHide}>
        <div className={ (props.float?' container':'')} >
          <div className={ (props.error ? "notify alert-danger" : "notify") } >
            { !!props.onHide &&
        			<i className="icon-mask notify-close" style={{ WebkitMaskImage:"url("+CloseIcon+")"}} alt="Close"></i>
            }
            { props.jumboTitle &&
              <h1>{props.jumboTitle}</h1>
            }
            { props.header &&
              <h3>{props.header}</h3>
            }
            <p>{( props.noNewLines ? props.message : convertNewLines( props.message ) )}</p>
          </div>
        </div>
      </div>
  );
}