"use client"

import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import ShareModal from '../modal/ShareModal'

import { GET_FEATURES } from "../helpers/Config";

export default function  ShareButton(props) {

	const [showModal, setShowModal] = useState(false);

	const toggleShareModal = ()=>{
		setShowModal(!showModal);
	}

	return (
		<>
      <Button
        className={ props.className || "btn btn-primary btn-block mt-2" }
        onClick={toggleShareModal} >
        {props.title || 'Share Video'}
      </Button>
      
      <ShareModal
        show={showModal}
        onHide={toggleShareModal}
        shareUrl={props.shareUrl}
        fizzId={props.fizzId}
        showDownload={ GET_FEATURES.allowDownload }
        showGroupShare={ props.showGroupShare }
      />
    </>
	);
 }
