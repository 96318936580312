import React from "react";
import "../assets/css/themed-icon.css";

const ThemedIcon = (props) => {

    let extraClass = props.variant ? (" themed-icon-"+props.variant+" "):"";
    let defaultStyles = {
      maskImage: `url(${props.icon})`,
      WebkitMaskImage: `url(${props.icon})`
    };
    return (
        <i
            onClick={props.onClick}
            className={"themed-icon-wrap "+extraClass+(props.className||"")}
            style={{
                ...defaultStyles,
                ...props.style
            }}
        >&nbsp;</i>
    )
}
export default ThemedIcon