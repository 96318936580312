/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import SpecialFieldEditor from '../components/SpecialFieldEditor';
import { apiFetch } from "../helpers/APIHelper";
import Spinner from "../components/Spinner";

import "../assets/css/fsf-editor.css";


export default class FizzSpecialFields extends Component {

	constructor(props) {
		super(props);


		var specialFieldsToSend = this.getSpecialFields( props.specialFields );
		this.state = {
			lastSave: JSON.parse( JSON.stringify(props.specialFields) ),
			specialFields: props.specialFields,
		  	specialFieldsToSend: this.getSpecialFields(specialFieldsToSend),
			imageString: this.buildSpecialFieldImageString(specialFieldsToSend),
		};
	}


	onSpecialFieldsChange = (groups) => {
		this.setState({
			isEditing: true,
			specialFields:groups,
			specialFieldsToSend: this.getSpecialFields(groups),
		});
		clearTimeout(this.textTimeout);
		this.textTimeout = setTimeout(() => {
		  this.setState({
		    imageString: this.buildSpecialFieldImageString( this.state.specialFieldsToSend ),
		  });
		}, 500);
	};

	buildSpecialFieldImageString = ( flatFields ) => {
		let imageString = "";
		if ( flatFields ) {
		  flatFields.map((item) => {
		    imageString += `${item.value}\n`;
		  });
		}
		return encodeURIComponent(imageString);
	};

	getSpecialFields = (groups) => {
		var fields = [];
		for (var i = 0; i < groups.length; i++) {
			var group = groups[i];
			fields = fields.concat(group.items);
		}
		return fields;
	}

	cancelEdit = () => {

		var oldFields = JSON.parse( JSON.stringify(this.state.lastSave) );
		var specialFieldsToSend = this.getSpecialFields( oldFields );
		this.setState({
			isEditing: false,
			specialFields: oldFields,
		  	specialFieldsToSend: specialFieldsToSend,
			imageString: this.buildSpecialFieldImageString( specialFieldsToSend )
		});
	}

	save = ()=>{
		this.setState({
			isLoading: true,
		});
	    apiFetch( "/rest/fizz/"+this.props.fizzId,
	      {
	        method: "PUT",
	        data: {
	          specialFields: this.state.specialFieldsToSend
	        },
	      },
	      (json) => {
			this.setState({
				isLoading: false,
				isEditing: false,
				lastSave: JSON.parse( JSON.stringify( this.state.specialFields) ),
			})
	      },
	      (error) => {
	        this.setState({
	          isLoading: false,
	          createError: error + "",
	        });
	      }
	    );

	}

	render() {

	    var previewImages = [];
	    for (var i = 0; i < this.props.previewImageCount; i++) {
	      previewImages.push(
	        "/rest/theme-animation/" +
	          this.props.taId +
	          "/image-preview/" +
	          i +
	          "/" +
	          this.state.imageString
	      );
	    }


		return (
			<div>
	          	<SpecialFieldEditor
					specialFields={this.state.specialFields}
					onSpecialFieldsChange={this.onSpecialFieldsChange}
	            />
	        	{/* mobile web view show image between edits and music dropdown */}
	        	{ this.state.isEditing &&
		        	<div className="fsf-editor"> 


  						{this.state.isLoading &&
							<Spinner float={true} />
 						}
		              
						{previewImages.map((url, index) => (
								<div className="box-480 mb-1" key={`images ${index}`} >
								<img className="box-480-item"
									key={url}
									src={url}
								/>
								</div>
						))}
		            	<div className="row" >
			              	<div className="col" >
				                <button
				                  className="btn btn-block btn-outline-primary"
				                  onClick={this.cancelEdit}
				                  >
				                  Cancel
				                </button>
			              	</div>
			              	<div className="col" >
				                <button
				                  className="btn btn-block btn-primary"
				                  onClick={this.save}
				                  >
				                  Save
				                </button>
			              	</div>
		        		</div>
		        	</div>
		     	}
			</div>
        );
	}

}
